import React, { useState, useEffect } from "react";
import "./Checkout.css"
import {
  FaChevronLeft,
} from "react-icons/fa";
import { BsTrash3 } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import { Select, Space, Skeleton, Spin, message, Modal } from "antd";
import {
  Url,
  addPromoCodeApi,
  addShippingPriceApi,
  addTotalAmountApi,
  allAddressesApi,
  allStoresApi,
  createOrderApi,
  executePaymentApi,
  getInitiatePaymentApi
} from "../../Apis";
import { appContext } from "../../Context/appContext";
import { useContext } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {useSelector } from "react-redux";
import { isEmpty} from "lodash";


export const NewCheckOut = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    padding: "1em",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    p: 4,
  };
  const [options, setOptions] = useState([]);
  const MySwal = withReactContent(Swal);
  const [branchId] = useState(localStorage.getItem("branchId"));
  const [type, setType] = useState(localStorage.getItem("type") ? localStorage.getItem("type") : "MADA");
  const [successPromocode, setSuccessPromoCode] = useState(false)
  const [salePrice, setSalePrice] = useState("")
  const [discount, setDiscount] = useState(0)
  const [chosenDate] = useState(
    new Date().toLocaleDateString("en-ca")
  );
  const [chosenTime] = useState(
    new Date().toTimeString().split(" ")[0]
  );
  const [notes] = useState("");
  const [shippingPrice, setShippingPrice] = useState("0");
  const [shippingPriceId, setShippingPriceId] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [payMethod, setPayMethod] = useState(localStorage.getItem("payMethod") ? localStorage.getItem("payMethod") : "CARD");
  const [addresses, setAddresses] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [billingAddress, setBillingAddress] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [store, setStore] = useState({});
  const [orderType] = useState(localStorage.getItem("orderType") ? localStorage.getItem("orderType") : "PICkUP");
  const [vat, setVat] = useState(0)
  const [salePriceAndVatload, setSalePriceAndVatload] = useState(false)
  const handleOpen = () => setOpen(true);
  const token = localStorage.getItem("token");
  const [width, setWidth] = useState(window.innerWidth);
  const {
    cartQty,
    cartItems,
    removeFromCart,
    increaseCartQuantity,
    decreaseCartQuantity,
    loading,
    isLoading,
    itemId,
    emptyCart,
    getCartData,
  } = useContext(appContext);
  const navigate = useNavigate();
  const { storeName } = useParams();
  const [loadingPromo, setLoadingPromo] = useState(false);
  const [placeLoading, setPlaceLoading] = useState(false);
  const [shipingLoading, setShipingLoading] = useState(false);
  const paymentToken = useSelector(state => state.paymentToken)
  const [promoId, setPromoId] = useState("")
  const { language } = useSelector(state => state)
  const [selectedPayMethod, setSelectedPayMethod] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState(localStorage.getItem("paymentMethodId") ? localStorage.getItem("paymentMethodId") : "")
  const [paymentMethods, setPaymentMethods] = useState([])
  const [filteredPayMethods , setFilteredPayMethods ] = useState([])
  const [isValidOrder , setValidOrder] = useState(true)



  let payMethodArr = [
    {
      img: "/logo3192_1_80x.png",
      title:  "Mada Pay",
      title_ar: "الدفع من خلال مدى",
      subTitle: "Mada Pay",
      desc: "Expires on 16/24",
      value: "CARD",
      type: "MADA",
      PaymentMethodId: 6
    },
    {
      img: "/image 62.png",
      title: "Visa Card",
      title_ar: "الدفع من خلال فيزا",
      subTitle: "Visa Card",
      desc: "Expires on 16/24",
      value: "CARD",
      type: "VISA",
      PaymentMethodId: 2
    },
    {
      img: "/Stc_pay.png",
      title: "STC Pay",
      title_ar: "الدفع من خلال stc",
      subTitle: "STC Pay",
      desc: "Expires on 16/24",
      value: "CARD",
      type: "STC",
      PaymentMethodId: 14
    },
    {
      img: "/image 63.png",
      title: "Apple Pay",
      title_ar:"الدفع  من خلال apple",
      subTitle: "Apple Pay",
      desc: "Lorem Ipsum Two",
      value: "CARD",
      type: "APPLE",
      PaymentMethodId: 11
    },
    {
      img: "/dollar coin.png",
      title: "Cash on delivery",
      title_ar : "الدفع كاش عند التوصيل",
      subTitle: "Cash on delivery",
      desc: "Lorem Ipsum Two",
      value: "CASH",
      type: "",
      PaymentMethodId: ""
    }
  ];


  // if (store[0]?._id !== '6461e5ae7ac57ede17a5efb4') {
  //   payMethodArr.push(
  //     {
  //       img: "/dollar coin.png",
  //       title: language == "en" ? "Cash on delivery" : "الدفع كاش عند التوصيل",
  //       subTitle: "Cash on delivery",
  //       desc: "Lorem Ipsum Two",
  //       value: "CASH",
  //       type: "",
  //       PaymentMethodId: ""
  //     }
  //   )
  // }

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 10,
      }}
      spin
    />
  );
  const antIconPlace = (
    <LoadingOutlined
      style={{
        fontSize: 22,
        color: "#fff",
      }}
      spin
    />
  );
  const antIconPromo = (
    <LoadingOutlined
      style={{
        fontSize: 18,
        color: "#fff",
      }}
      spin
    />
  );

  const orderDetails = cartItems && cartItems?.data?.products?.map((obj) => {
    return {
      product: obj?._id,
      quantity: obj?.quantity,
      price: obj?.price,
      note: obj?.note,
      addons: obj?.addons,
    };
  });


  const antIconShiping = (
    <LoadingOutlined
      style={{
        fontSize: 15,
        color: "rgba(72, 160, 219, 1)",
      }}
      spin
    />
  );
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleChangePromo = (e) => {
    setPromoCode(e.target.value)
  }

  const handleSelectPayMethod = (pay, value, type, paymentMethodId) => {
    localStorage.setItem("selectedPay", pay)
    localStorage.setItem("payMethod", value)
    localStorage.setItem("type", type)
    localStorage.setItem("paymentMethodId", paymentMethodId)
    setPayMethod(value);
    setType(type)
    setPaymentMethodId(paymentMethodId)
    setSelectedPayMethod(pay);
  };

  const getAddonsPrice = () => {
    let res = 0;
    for (let i = 0; i < cartItems?.data?.products?.length; i++) {
      if (cartItems?.data?.products[i]?.addonDetails) {
        for (
          let j = 0;
          j < cartItems?.data?.products[i]?.addonDetails?.length;
          j++
        ) {
          res +=
            parseFloat(cartItems?.data?.products[i]?.addonDetails[j].price) *
            parseFloat(cartItems?.data?.products[i]?.quantity);
        }
      }
    }
    return res;
  };
  const getSubTotal = () => {
    let res = 0;
    for (let i = 0; i < cartItems?.data?.products?.length; i++) {
      res +=
        parseFloat(cartItems?.data?.products[i]?.price) *
        parseFloat(cartItems?.data?.products[i]?.quantity);
    }
    return res;
  };

  useEffect(() => {
    if (orderType == "PICkUP") {
    }
  }, [orderType])
  function calculateAddonsTotal(products) {
    let total = 0;
    products?.forEach((product) => {
      product?.addons.forEach((addonId) => {
        const addon = product?.addonDetails?.find((a) => a._id === addonId);
        if (addon) {
          total += addon?.price * product.quantity;
        }
      });
    });
    return total;
  }

  const addonsTotal = calculateAddonsTotal(cartItems?.data?.products);
  const cartTotal = cartItems?.data?.products?.reduce((total, cartItem) => {
    return total + cartItem?.price * cartItem?.quantity;
  }, 0);

  const totalCart = cartTotal + addonsTotal;
  const getOneStore = async () => {
    allStoresApi().then((res) => {
      let data = res?.data?.data?.filter(
        (data) => data.name?.replace(/\s+/g, "-") == storeName
      );
      setStore(data);
      console.log("payment_methods" , data)
      if(data[0] && data[0]?.payment_methods){
        let payment_methods = data[0]?.payment_methods
        let filteredMethods = payMethodArr.filter(method => payment_methods.includes(method.title));
        setFilteredPayMethods(filteredMethods)
      }
    });
  };

  useEffect(() => {
    getOneStore();
  }, []);

  const getAllAddresses = () => {
    allAddressesApi()
      .then((res) => {
        let defaultAddress = res?.data.data.filter(address => address.isActive == true)[0]
        setShippingAddress(defaultAddress?._id)
        let allAddress = [];
        let allBillingAddress = [];
        res?.data?.data?.map((address) => {
          let oneAddress = {
            label: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span >
                  {address?.area +
                    " , " +
                    address?.street +
                    " , " +
                    address?.apartment +
                    " , " +
                    address?.buildingNo +
                    " , " +
                    address?.floor}
                </span>
                <span>
                  <CiEdit
                    style={{
                      color: "#48A0DB",
                      margin: " 0 8px",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/new-address/${address?._id}`)}
                  />
                </span>
              </div>
            ),
            value: address?._id,
          };
          let oneBillingAddress = {
            label:
              address?.area +
              " , " +
              address?.street +
              " , " +
              address?.apartment +
              " , " +
              address?.buildingNo +
              " , " +
              address?.floor,
            value: address?._id,
          };
          allBillingAddress.push(oneBillingAddress);
          allAddress.push(oneAddress);
        });
        setAddresses(res?.data?.data);
        setOptions(allAddress);
      })
      .catch((err) => console.log(err));
  };

  const getTotalSalePrice = () => {
    const orderTotal = {
      shippingPrice: orderType == "PICkUP" ? 0 : shippingPrice,
      orderDetails: cartItems && cartItems?.data?.products?.map((obj) => {
        return {
          product: obj?._id,
          quantity: obj?.quantity,
          price: obj?.price,
          note: obj?.note,
          addons: obj?.addons,
        };
      }),
      promo: promoCode
    };
    setSalePriceAndVatload(true)
    addTotalAmountApi(orderTotal).then(async (res) => {
      if (localStorage.getItem("promocodeDiscount")) {
        let saleDiscount = Math.abs(res.data.data.salePrice - parseFloat(localStorage.getItem("promocodeDiscount")))
        setSalePrice(saleDiscount)
      } else {
        setSalePrice(res.data.data.salePrice)
      }
      setVat(res.data.data.VAT)
      setSalePriceAndVatload(false)
    }).catch(err => {
      message.error(err.response.data.messageEn)
    })
  }

  useEffect(() => {
    if (!isEmpty(cartItems?.data) && cartItems?.data?.products) {
      getTotalSalePrice()
    }
  }, [cartItems]);


  useEffect(() => {
    if (token) {
      getAllAddresses();
    }
  }, []);


  const createOrder = async () => {
    let payload = {
      orderType,
      chosenDate,
      chosenTime,
      notes,
      paymentToken: paymentToken,
      store: store[0]?._id,
      branch: branchId,
      billingAddress: billingAddress,
      shippingAddress,
      orderMethod: "ONLINE",
      status: "PROCESSING",
      discount: discount,
      shippingPrice: orderType === "PICkUP" ? "0" : shippingPrice,
      shippingPriceId: orderType === "PICKUP" ? "" : shippingPriceId,
      paymentMethod: payMethod,
      currency: "SAR",
      orderDetails: [...cartItems?.data?.products],
    };
    if (successPromocode) {
      Object.assign(payload, { promo: promoId })
    }
    setPlaceLoading(true);
    try {
      createOrderApi(payload)
        .then((response) => {
          if (response) {
            handleOpen();
            emptyCart();
            setShippingPrice(0)
            localStorage.removeItem("selectedPay")
            localStorage.removeItem("payMethod")
            localStorage.removeItem("orderType")
            localStorage.removeItem("type")
            setDiscount(0)
            setSuccessPromoCode(false)
            getCartData(store[0]._id);
            setPlaceLoading(false);
            setSalePrice(0)
          }
        })
        .catch((error) => {
          message.error(error.response.data.messageEn);
          setPlaceLoading(false);
        });
    } catch (response) {
      message.error(response?.response?.data?.messageEn);
      setPlaceLoading(false);
    }
  };

  const orderPromoCode = {
    shippingPrice: shippingPrice,
    orderDetails: orderDetails,
    promo: promoCode,
    store : store[0]?._id
  };
  const handleSubmitPromo = () => {
    setLoadingPromo(true)
    addPromoCodeApi(orderPromoCode).then(res => {
      if (res.data.data) {
        setSuccessPromoCode(true)
        setDiscount(res.data.data.discount)
        setSalePrice(res.data.data.salePrice)
        setVat(res.data.data.VAT)
        setLoadingPromo(false)
        setPromoCode("")
        setPromoId(res.data.data.promo)
        message.success(res.data.messageEn)
      }
    }).catch(err => {
      message.error(err.response.data.messageEn)
      setLoadingPromo(false)
    })
  }

  const handlePay = async () => {
    let condition = false;
    if (payMethod == "CASH") {
      if (condition) {
        return;
      } else {
        if (branchId?.length == 0 && orderType == "PICkUP") {
          message.error("Please Choose Branch")
          return
        } else if (shippingAddress?.length == 0 && orderType == "DELIVERY") {
          message.error("Please Choose Delivery Address")
          return
        }
        createOrder();
      }
    } else {
      const payLoad = {
        InvoiceValue: salePrice,
        PaymentMethodId: paymentMethodId,
        DisplayCurrencyIso: "SAR",
        CallBackUrl: `${window.location.origin}/${storeName}/pay-success`,
        ErrorUrl: `${window.location.origin}/${storeName}/pay-fail`,
        store: store[0]?._id,
      }
      let orderobj = {
        orderType,
        chosenDate,
        chosenTime,
        notes,
        paymentToken: paymentToken,
        store: store[0]?._id,
        branch: branchId,
        billingAddress: billingAddress,
        shippingAddress,
        discount: discount,
        orderMethod: "ONLINE",
        status: "PROCESSING",
        shippingPrice: orderType === "PICkUP" ? "0" : shippingPrice,
        shippingPriceId: orderType === "PICKUP" ? "" : shippingPriceId,
        paymentMethod: payMethod,
        currency: "SAR",
        orderDetails: [...cartItems?.data?.products],
      };
      if (successPromocode) {
        Object.assign(orderobj, { promo: promoId })
      }
      if (shippingAddress?.length === 0 && orderType === "DELIVERY") {
        message.error("Please Choose Delivery Address")
        return
      }
      try {
        setPlaceLoading(true);
        executePaymentApi(payLoad).then((response) => {
          setPlaceLoading(false);
          const paymentURL = response?.data?.Data?.PaymentURL
          if (response.status === 1 && paymentURL) {
            localStorage.setItem("orderObj", JSON.stringify(orderobj));
            window.location.href = paymentURL;
          } else {
            message.error(response.data.message);
          }
        }).catch(error => {
          setPlaceLoading(false);
          message.error(error.response.data.data.message)
        })
      } catch (error) {
        setPlaceLoading(false);
        condition = true;
        message.error(error.response.data.messageEn);
        return;
      }
    }
  };

  const getShippingPrice = () => {
    let arr = [];
    arr = addresses?.filter((address) => address?._id == shippingAddress);
    const obj = {
      user: {
        lat: arr[0]?.areaCo?.lat ? arr[0]?.areaCo?.lat : "23",
        long: arr[0]?.areaCo?.long ? arr[0]?.areaCo?.long : "46",
      },
    };
    setShipingLoading(true)
    if (store[0]?._id) {
      addShippingPriceApi(obj, store[0]?._id).then(response => {
        setShipingLoading(false)
        if(response.data.status === 200){
          setValidOrder(true)
          let res = response.data?.data?.delivery_zone
          setShippingPrice(res?.delivery_fee?.toString() );
          setShippingPriceId(res._id);
        }
      }).catch(err => {
        message.error(err.response.data.messageEn)
        setValidOrder(false)
        setShipingLoading(false)
      })
    }
  };

  const handleChangeAddress = (e) => {
    if (e == undefined) {
      setShippingAddress("");
      setShippingPrice("0")
    } else {
      setShippingAddress(e);
    }
  };

  useEffect(() => {
    if (shippingAddress?.length !== 0 && orderType !== "PICkUP") {
      getShippingPrice();
    } else {
      setShippingPrice("0")
      getTotalSalePrice()
    }
  }, [shippingAddress, store[0], orderType]);

  useEffect(() => {
    if (shippingAddress?.length !== 0 && orderType !== "PICkUP") {
      getTotalSalePrice()
    }
  }, [shippingPrice])



  // useEffect(() => {
  //   getinitiatePayment();
  // }, [])

  // const getinitiatePayment = () => {
  //   const payLoad = {
  //     InvoiceAmount: salePrice,
  //     CurrencyIso: "SAR"
  //   }
  //   getInitiatePaymentApi(payLoad).then(res => {
  //     if (res.status === 1) {
  //       if (res.data && res.data.Data.PaymentMethods) {
  //         const PaymentMethods = res.data.Data.PaymentMethods
  //         setPaymentMethods(PaymentMethods)
  //       }
  //     }
  //   }).catch(err => {
  //     console.log(err);
  //   })
  // };

  const removeDiscount = () => {
    setSuccessPromoCode(false)
    setDiscount(0)
    setVat(0)
    setPromoId("")
  }

  const handleIncreaseCartQuantity = (product_id) => {
    removeDiscount();
    increaseCartQuantity(product_id);
  }

  const handleDecreaseCartQuantity = (product_id) => {
    removeDiscount();
    decreaseCartQuantity(product_id)
  }

  const handleRemoveItemFromCart = (product_id) => {
    removeDiscount();
    removeFromCart(product_id)
  }


  return (
    <>
      <Modal
        open={open}
        className="succesModal"
        wrapClassName="modal"
        style={style}
        closeIcon={" "}
        centered
        footer={
          <button
            style={{
              padding: "0.5em 1em",
              border: "none",
              borderRadius: "10px",
              boxShadow: "0 0 4px gray",
              margin: "2em 1em",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/order-history`)}
          >
            {language == "en" ? "Back To Orders" : "الرجوع للطلبات"}
          </button>
        }
      >
        <img
          src="/success.gif"
          style={{ width: "100px", height: "100px", margin: "auto" }}
        />
      </Modal>
      <div style={{ width: "100%", backgroundColor: "#F5F5F5", minHeight: "100vh" }}>
        <div
          style={{
            width: "90%",
            // height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "grid",
              textAlign: "center",
              position: "relative",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "45px",
                height: "45px",
                padding: "0.25em",
                border: "none",
                outline: "none",
                display: "flex",
                position: "absolute",
                backgroundColor: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                boxShadow: "0 0 4px lightgray",
                color: "rgba(72, 160, 219, 1)",
              }}
              onClick={() => navigate(`/${storeName}`)}
            >
              <FaChevronLeft />
            </button>
            <h2 style={{ fontWeight: 600 }}>{language == "en" ? "CHECKOUT" : "الدفع"}</h2>
          </div>
          <div style={{ marginTop: "40px" }} dir={language == "en" ? "ltr" : "rtl"}>
            {isLoading
              ? [1, 2, 3].map((i, index) => (
                <div
                  key={index}
                  style={{
                    borderRadius: "15px",
                    width: "95%",
                    justifyContent: "space-between",
                    margin: "20px auto",
                    background: "#fff",
                    padding: "10px",
                    display: "grid",
                    gridTemplateColumns: " 1fr 3fr",
                    alignItems: "center",
                  }}
                  dir={language == "en" ? "ltr" : "rtl"}
                >
                  <Space>
                    <Space>
                      <Skeleton.Image
                        active={true}
                        size={"large"}
                        shape={"round"}
                        block={false}
                      />
                    </Space>

                    <Space direction="vertical">
                      <Skeleton.Button
                        style={{ height: 10 }}
                        active={true}
                        size={"small"}
                        shape={"round"}
                        block={true}
                      />
                      <Skeleton.Avatar
                        style={{ height: 10 }}
                        active={true}
                        size={"small"}
                        shape={"round"}
                        block={false}
                      />
                      <Skeleton.Input
                        style={{ height: 10 }}
                        active={true}
                        size={"small"}
                        shape={"round"}
                        block={true}
                      />
                    </Space>
                  </Space>
                </div>
              ))
              : cartItems?.data?.products?.map((product, index) => (
                <div
                  key={product?._id}
                  style={{
                    borderRadius: "15px",
                    width: "95%",
                    justifyContent: "space-between",
                    margin: "20px auto",
                    background: "#fff",
                    padding: "10px",
                    display: "grid",
                    gridTemplateColumns: " 1fr 3fr",
                    alignItems: "center",
                    gap: 6,
                  }}
                >
                  <img
                    src={Url + product?.product?.productImage}
                    style={{ borderRadius: 10 }}
                    width={78}
                    height={76}
                    alt="image"
                  />
                  <div style={{ display: "grid" }}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4
                        style={{
                          width: "200px",
                          fontWeight: "700",
                          fontSize: "0.9em",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {language == "en" ? product?.product?.englishName : product?.product?.arabicName}
                      </h4>
                      <BsTrash3
                        style={{
                          color: "#48A0DB",
                          margin: "8px 0",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveItemFromCart(product?._id)}
                      />
                    </span>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <legend
                        style={{
                          fontSize: "12px",
                          position: "relative",
                          left: "0",
                          color: "#9f9f9f",
                        }}
                      >
                        <small style={{ color: "#000", fontSize: "12px" }}>
                          {language == "en" ? "addons :" : "الاضافات :"}
                        </small>
                        {product?.addonDetails?.map(
                          (addon, i) =>
                            language == "en" ? addon?.name : addon.nameArabic +
                              (i < product?.addonDetails?.length - 1
                                ? " , "
                                : "")
                        )}
                      </legend>
                    </div>
                    <legend
                      style={{
                        fontSize: "12px",
                        position: "relative",
                        left: "0",
                        color: "#9f9f9f",
                      }}
                    >
                      <small style={{ color: "#000", fontSize: "12px" }}>
                        {language == "en" ? "notes :" : "الملاحظات :"}
                      </small>
                      {product?.note}
                    </legend>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <span
                        style={{
                          color: "#48A0DB",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {language == "en" ? <>
                          <span>{language == "en" ? "SAR" : "س.ر"}</span>
                          <span style={{ margin: "auto 5px" }}>
                            {product?.product?.salePrice * product?.quantity +
                              product?.addons
                                ?.filter((add) =>
                                  product?.addonDetails?.some(
                                    (add2) => add2._id === add
                                  )
                                )
                                ?.map(
                                  (add) =>
                                    product?.addonDetails?.find(
                                      (add2) => add2._id === add
                                    )?.price
                                )
                                ?.reduce((acc, price) => acc + price, 0) *
                              product?.quantity}
                          </span>
                        </> :
                          <>
                            <span style={{ margin: "auto 5px" }}>
                              {product?.product?.salePrice * product?.quantity +
                                product?.addons
                                  ?.filter((add) =>
                                    product?.addonDetails?.some(
                                      (add2) => add2._id === add
                                    )
                                  )
                                  ?.map(
                                    (add) =>
                                      product?.addonDetails?.find(
                                        (add2) => add2._id === add
                                      )?.price
                                  )
                                  ?.reduce((acc, price) => acc + price, 0) *
                                product?.quantity}
                            </span>
                            <span>{language == "en" ? "SAR" : "س.ر"}</span>
                          </>
                        }
                      </span>
                      <div
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #48A0DB",
                          padding: " 3px 10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{ fontSize: "15px", cursor: "pointer" }}
                          onClick={() => handleDecreaseCartQuantity(product?._id)}
                        >
                          {" "}
                          -{" "}
                        </span>
                        <p style={{ margin: "auto 7px" }}>
                          {loading && itemId == product?._id ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            product?.quantity
                          )}
                        </p>
                        <span
                          style={{ fontSize: "15px", cursor: "pointer" }}
                          onClick={() => handleIncreaseCartQuantity(product?._id)}
                        >
                          {" "}
                          +{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {orderType == "DELIVERY" && <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "50px",
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            <h4 style={{ fontSize: 18 }}>
              {language == "en" ? "Delivery Address" : "عنوان التوصيل"}
            </h4>
            <span
              style={{
                borderBottom: "1px solid #48A0DB",
                color: "#48A0DB",
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/new-address")}
            >
              {language == "en" ? "Add New" : "اضافة جديد"}
            </span>
          </div>
          }
          {orderType == "DELIVERY" && (
            <div style={{ marginTop: "5px" }} dir={language == "en" ? "ltr" : "rtl"}>
              <Select
                value={shippingAddress}
                onClear={(e) => setShippingAddress(" ")}
                placeholder={language == "en" ? "Choose your delivery location" : "اختر موقع التوصيل الخاص بك"}
                className="select-address"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                direction={language == "en" ? "ltr" : "rtl"}
                allowClear
                showSearch
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: shippingAddress?.length !== 0 && "hidden"
                }}
                onChange={handleChangeAddress}
                options={options}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            <h4 style={{ fontSize: '18px' }}>{language == "en" ? "Payment Method" : "طرق الدفع"}</h4>
          </div>
          <div style={{ marginTop: "30px" }} dir={language == "en" ? "ltr" : "rtl"}>
            {filteredPayMethods && filteredPayMethods.length > 0 && filteredPayMethods.map((data , index) => (
              <div
                key={index+1}
                onClick={() => handleSelectPayMethod(data.title, data.value, data.type, data.PaymentMethodId)}
                style={{
                  borderRadius: "15px",
                  width: "95%",
                  height: "50px",
                  justifyContent: "space-between",
                  margin: "20px auto",
                  background: "#fff",
                  padding: "12px 10px",
                  alignItems: "center",
                  display: "grid",
                  cursor: "pointer",
                  transition: " transform ease 0.33s",
                  gridTemplateColumns: " 1fr 3fr",
                  gap: 8,
                  border: selectedPayMethod === data.title && "1px solid #48A0DB",
                }}
                dir={language == "en" ? "ltr" : "rtl"}
              >
                <img loading="lazy" src={data.img}  style={{ margin: "auto" }} alt="pay-method" />
                <span>
                  <h4 style={{ fontWeight: "500", fontSize: "14px" }}>
                    {language == "en" ? data.title : data.title_ar}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "12px",
                        marginTop: "8px",
                      }}
                    >
                    </span>
                  </div>
                </span>
              </div>
            ))}
          </div>

          <div
            style={{
              width: "100%",
              margin: "50px auto 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 20
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            <input
              value={promoCode}
              onChange={handleChangePromo}
              style={{
                borderRadius: "12px",
                width: "100%",
                padding: " 0.95em 15px",
                background: "white",
                border: "none",
              }}
              type="text"
              placeholder={language == "en" ? "Add Promo Code" : "اضف الكود"}
            />
            <button
              onClick={handleSubmitPromo}
              style={{
                width: "55%",
                padding: "0.93em 1em",
                border: "none",
                outline: "none",
                display: "flex",
                backgroundColor: "#F6A94A",
                color: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 0 4px lightgray",
                cursor: "pointer"
              }}
              disabled={loadingPromo || localStorage.getItem("promocodeDiscount") || promoCode?.length == 0}
            >
              {loadingPromo ? <Spin indicator={antIconPromo} /> : language == "en" ? "Apply" : "تأكيد"}
            </button>
          </div>
        </div>

        <div
          style={{
            background: "white",
            width: "auto",
            padding: "20px 20px 20px 20px",
          }}
          dir={language == "en" ? "ltr" : "rtl"}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "18px auto",
            }}
          >
            <span style={{ fontSize: "16px" }}>{language == "en" ? "Subtotal" : "الإجمالي الفرعي"}</span>
            <span style={{ fontWeight: "600", fontSize: "14px" }} dir="ltr">
              <span style={{ display: "inline-block", margin: "0 5px" }}> {language == "en" ? "SAR" : "س.ر"} {" "}</span>
              {isLoading ? <Spin indicator={antIconShiping} /> : getSubTotal() + getAddonsPrice()}
            </span>
          </span>
          {(successPromocode || localStorage.getItem("promocodeDiscount")) && <><hr style={{ border: "1px solid #F1F2F3", borderColor: "#F1F2F3" }} />
            <span style={{ display: "flex", justifyContent: "space-between", margin: "20px auto" }}>
              <span style={{ fontSize: "16px" }}>{language == "en" ? "Discount" : "الخصم"}</span>
              <span style={{ fontWeight: "600", fontSize: "14px" }} dir="ltr">
                <span style={{ display: "inline-block", margin: "0 5px" }}> {language == "en" ? "SAR" : "س.ر"} {" "}</span>
                {salePriceAndVatload ? <Spin indicator={antIconShiping} /> : discount}</span>
            </span> </>}
          <hr style={{ border: "1px solid #F1F2F3", borderColor: "#F1F2F3" }} />
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px auto",
            }}
          >
            <span style={{ fontSize: "16px" }}>{language == "en" ? "Delivery" : "التوصيل"}</span>
            <span style={{ fontWeight: "600", fontSize: "14px" }} dir="ltr">
              <span style={{ display: "inline-block", margin: "0 5px" }}> {language == "en" ? "SAR" : "س.ر"} {" "}</span>
              {shipingLoading ? (
                <Spin indicator={antIconShiping} />
              ) : (
                orderType == "PICkUP" ? 0 : shippingPrice
              )}
            </span>
          </span>
          <hr style={{ border: "1px solid #F1F2F3", borderColor: "#F1F2F3" }} />
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px auto",
            }}
          >
            <span style={{ fontSize: "16px" }}>{language == "en" ? "Tax and Fees (15%)" : "الضرائب والرسوم (15%)"}</span>
            <span style={{ fontWeight: "600", fontSize: "14px" }} dir="ltr">
              <span style={{ display: "inline-block", margin: "0 5px" }}> {language == "en" ? "SAR" : "س.ر"} {" "}</span>
              <span >{salePriceAndVatload ? <Spin indicator={antIconShiping} /> : vat}</span>
            </span>
          </span>
          <hr style={{ border: "1px solid #F1F2F3", borderColor: "#F1F2F3" }} />
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px auto",
            }}
          >
            <span style={{ color: "#C4C4C4" }}>{language == "en" ? `(${cartItems?.data?.products?.length
              ? cartItems?.data?.products?.length
              : 0
              } items)` : `(${cartItems?.data?.products?.length
                ? cartItems?.data?.products?.length
                : 0
              } عنصر)`}</span>
            <span style={{ fontSize: "21px", fontWeight: "bold" }} dir="ltr">
              <span style={{ display: "inline-block", margin: "0 5px" }}> {language == "en" ? "SAR" : "س.ر"} {" "}</span>
              {salePriceAndVatload || shipingLoading ? <Spin indicator={antIconShiping} /> : salePrice}
            </span>
          </span>
        </div>
        <br />
        <br />
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "2em 1em",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "100%",
                fontSize: "16px",
                fontWeight: "600",
                padding: "0.8em 1em",
                border: "none",
                outline: "none",
                display: "flex",
                backgroundColor: (placeLoading || !selectedPayMethod || !isValidOrder) ? "#ccc" : "#F6A94A",
                color: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                cursor : (placeLoading || !selectedPayMethod || !isValidOrder) ? "not-allowed" : "pointer",
                boxShadow: "0 0 4px lightgray",
              }}
              onClick={() => handlePay()}
              disabled={placeLoading || !selectedPayMethod || !isValidOrder}
            >
              {placeLoading ? <Spin indicator={antIconPlace} /> : language == "en" ? "Order Now" : "اطلب الآن"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};


