import React, { useContext, useEffect, useRef } from "react";
import { Select, Space, Skeleton, Modal, Carousel } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CiClock2 } from "react-icons/ci"
import { BsCashStack, BsInfoCircle } from "react-icons/bs";
import { RiWhatsappFill } from "react-icons/ri";
import { Url, allStoresApi, getStoreBranchesApi, getStoreSlidersApi } from "../../Apis";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronLeft } from "react-icons/fa";
import Header from "../../components/Header";
import GoogleMap from "./Map";
import { MdLocationOn } from "react-icons/md";
import { LuClock3 } from "react-icons/lu";
import { appContext } from "../../Context/appContext";
import Map from "../../components/Map";

export const StoreBranches = () => {
  const anchorRef = useRef(null)
  const navigate = useNavigate();
  const { storeName } = useParams()
  const [loading, setLoading] = useState(false)
  const [store, setStore] = useState({})
  const token = localStorage.getItem('token');
  const [active, setActive] = useState("")
  const [branches, setBranches] = useState([]);
  const [storeSliders, setStoreSliders] = useState([]);
  const { language } = useSelector(state => state)
  const [orderType, setOrderType] = useState(localStorage.getItem("orderType") ? localStorage.getItem("orderType") : "PICkUP");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setMapAddress, mapAddress, emptyCart } = useContext(appContext)


  const observer = new IntersectionObserver((entries) => {
    console.log(entries);
  })


  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      // console.log(autocomplete.getPlace());
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };


  const changeOrderTypeFromChild = (order_type) => {
    if (order_type) {
      setOrderType(order_type)
    }
  }


  useEffect(() => {
    return () => observer.disconnect();
  }, [])

  const getStoreBaranchesData = (id) => {
    setLoading(true)
    if (id) {
      getStoreBranchesApi(id).then(res => {
        setLoading(false)
        if (res?.data?.data) {
          setBranches(res.data.data)
        }
      }).catch(err => {
        console.log(err);
        setLoading(false)
      })
    }
  }

  const getStoreSliders = (id) => {
    setLoading(true)
    if (id) {
      getStoreSlidersApi({ store: id }).then(res => {
        setLoading(false)
        if (res?.data?.data) {
          setStoreSliders(res.data.data)
        }
      }).catch(err => {
        console.log(err);
        setLoading(false)
      })
    }
  }

  const handleItemClick = (itemId) => {
    const element = document.getElementById(itemId.toString());
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };


  useEffect(() => {
    const inputEle = document.querySelector(".active")
    var time;
    if (inputEle) {
      clearTimeout(time)
      time = setTimeout(() => {

        inputEle.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
      }, 700);
    }
  }, [active])


  useEffect(() => {
    getAllStore()
    return () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  }, [])


  const getAllStore = async () => {
    allStoresApi().then(res => {
      let data = res?.data?.data?.filter(data => data.name?.replace(/\s+/g, '-') == storeName)[0]
      getStoreBaranchesData(data?._id)
      getStoreSliders(data?._id)
      setStore(data)
    })
  }

  const handleClickBranch = (branch) => {
    const branch_id = localStorage.getItem('branchId')
    if (branch_id && branch._id !== branch_id) {
      emptyCart();
    }
    localStorage.setItem('branchId', branch._id)
    localStorage.setItem('branchName', branch?.name ? branch?.name : "")
    localStorage.setItem('branchNameArabic', branch?.nameArabic ? branch?.nameArabic : "")
    localStorage.setItem("branchImage", branch.image ? branch?.image : "")
    if (branch.addresses && branch.addresses[0]?.lat && branch.addresses[0]?.long) {
      localStorage.setItem('branch_Lat', branch.addresses[0]?.lat)
      localStorage.setItem('branch_Lng', branch.addresses[0]?.long)
    }
    navigate(`/${storeName}`)
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
    if (store) {
      setMapAddress({ lat: store?.addresses?.length > 0 ? store?.addresses[0].lat : 18.52043, lng: store?.addresses?.length > 0 ? store?.addresses[0].long : 73.856743 });
    }
  };

  return (
    <>
      <Modal open={isModalOpen} cancelText={language == "en" ? "cancel" : "الغاء"} okText={language == "en" ? "ok" : "نعم"} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ width: "100%", height: "300px", margin: "20px 0" }}>
          <Map store={store} />
        </div>
      </Modal>
      <div style={{ width: "100%", backgroundColor: "#F5F5F5", minHeight: "100vh" }}>
        <div
          style={{
            width: "90%",
            height: "100%",
            margin: "auto",
            padding: "1em 0em 2em",
            boxSizing: "border-box",
          }}
        >
          <button
            style={{
              width: "45px",
              height: "45px",
              padding: "0.25em",
              border: "none",
              outline: "none",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "0 0 4px lightgray",
              color: "rgba(72, 160, 219, 1)",
              marginBottom: "1em"
            }}
            onClick={() => navigate("/")}
          >
            <FaChevronLeft />
          </button>
          <Header callBackFunc={changeOrderTypeFromChild} toggleOrderType={true} />
          {storeSliders && storeSliders.length > 0 ?
            <div style={{ marginTop: "1.2em" }}>
              <Carousel autoplay dotPosition="bottom" style={{ borderRadius: "15px", marginTop: "1em" }}>
                {storeSliders.map((item, index) => (
                  <div key={index + 1} >
                    <div className="landing-bg-div" style={{ display: "flex", backgroundImage: `url(${Url + item?.images})`, backgroundRepeat: "no-repeat" }}>
                      <div className="title-div" style={{ position: "relative" }}>
                        <p className="landing-title">{item.header}</p>
                        <p className="landing-subtitle">{item.subHeader}</p>
                      </div>
                      <div className="bg-div" >
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
            :
            <div
              style={{
                width: "100%",
                height: "250px",
                margin: "1em auto 0em",
                backgroundColor: "white",
                backgroundImage: `url(${Url + store?.logo})`,
                backgroundSize: "contain",
                objectFit: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "15px",
                position: "relative",
              }}
            >
              <div className="overlay"></div>
              <div
                style={{
                  position: "absolute",
                  width: "90%",
                  left: "5%",
                  display: "flex",
                  bottom: "5%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p
                    style={{
                      color: "white",
                      fontSize: "24px",
                      fontWeight: "700",
                    }}
                  >
                    {/* {store?.name} */}
                  </p>
                  {/* <p style={{ color: "white", width: "auto" }}>{store?.categories?.englishName}</p> */}
                </div>

                <div
                  style={{ width: "25%", display: "flex", justifyContent: "end" }}
                >
                  {store?.whatsappNumber &&
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        position: "relative",
                      }}
                    >
                      <a
                        href={`https://wa.me/${store?.whatsappNumber}`}
                        target="_blank"  // Open link in a new tab/window
                        rel="noopener noreferrer" // Recommended for security reasons
                      >
                        <RiWhatsappFill
                          style={{
                            width: "70%",
                            height: "70%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            top: "50%",
                            bottom: "50%",
                            left: "50%",
                            color: "rgba(116, 201, 106, 1)"
                            // margin: "1em auto 0em",
                          }}
                        />
                      </a>
                    </div>
                  }
                </div>
              </div>
            </div>
          }



          {/* <div style={{ width: "90%", display: "flex", justifyContent: "space-between", margin: "1em auto" }}>
            {store?.deliveryTime ?
              <p style={{ display: "flex", alignItems: "center", fontSize: "14px" }}><CiClock2 style={{ marginRight: "0.25em" }} />{store?.deliveryTime}</p> : null}
            <BsInfoCircle style={{ fontSize: "16px", color: "black", cursor: "pointer" }} onClick={() => navigate(`/more-info/${storeName}`)} />
          </div> */}

          <div
            style={{
              width: "100%",
              marginTop: "1em",
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                // alignItems: "center",
                width: "100%", fontWeight: "700", fontSize: "0.9em", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
              }}
              dir={language == "en" ? "ltr" : "rtl"}
            >
              <React.Fragment>
                <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "24px", fontWeight: "700" }}>
                  {loading ? <Skeleton.Button active shape="round" style={{ height: 15 }} /> : language == "en" ? store?.name : store?.nameArabic}
                </p>
                <p style={{ fontWeight: "500" }}>{loading ? <Skeleton.Button active block size="large" shape="round" style={{ height: 15, width: 200 }} /> : language == "en" ? store?.categories?.englishName : store?.categories?.arabicName}</p>
              </React.Fragment>
            </div>
            <div style={{ width: "90%", backgroundColor: "white", borderRadius: "10px", padding: ".7em 1em", margin: "1em 0em", boxShadow: "0 0 4px lightgray", display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "30%", padding: "0.2em" }}>
                <p style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px", gap: 5 }}>
                  {loading ?
                    <Skeleton.Button active shape="round" style={{ height: 10, marginTop: 8 }} />
                    : <React.Fragment>
                      <LuClock3 style={{ marginRight: "0.25em", color: "rgba(72, 160, 219, 1)", fontSize: "16px" }} />
                      {store?.deliveryTime ? store?.deliveryTime : 45 + " " + "Mins"}
                    </React.Fragment>
                  }</p>
              </div>
              <div style={{ width: "30%", borderLeft: "1px solid", borderRight: "1px solid", padding: "0.2em" }}>
                <p style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px", gap: 5 }}><BsCashStack style={{ marginRight: "0.25em", color: "rgba(72, 160, 219, 1)", fontSize: "16px" }} />{language == "en" ? "Cash/Card" : "كاش/فيزا"}</p>
              </div>
              <div style={{ width: "30%", padding: "0.2em" }}>
                <p style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px", color: "rgba(72, 160, 219, 1)" }}><MdLocationOn style={{ marginRight: "0.25em", color: "rgba(72, 160, 219, 1)", fontSize: "16px" }} /> <button style={{ border: "none", borderBottom: "1px solid rgba(72, 160, 219, 1)", fontSize: "14px", cursor: "pointer", fontSize: "14px", color: "rgba(72, 160, 219, 1)", backgroundColor: "white" }} onClick={() => showModal()}>{language == "en" ? "Location" : "الموقع"}</button></p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "50px",
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            <h4 style={{ fontSize: 18 }}>
              {orderType == "PICkUP" ? language == "en" ? "Choose Branch " : "اختر الفرع" : language == "en" ? "Delivery Address" : "عنوان التوصيل"}
            </h4>
          </div>
          {orderType == "DELIVERY" && (
            <div style={{ marginTop: "5px" }}>
              <GoogleMap store={store} />
            </div>
          )}

          {orderType === 'PICkUP' &&
            <div style={{ marginTop: "20px" }} dir={language == "en" ? "ltr" : "rtl"} >
              {loading ?
                [1, 2, 3, 4, 5].map((i, index) => (
                  <div
                    key={index + 1}
                    style={{
                      borderRadius: "15px",
                      width: "95%",
                      justifyContent: "space-between",
                      margin: "20px auto",
                      background: "#fff",
                      padding: "10px",
                      display: "grid",
                      gridTemplateColumns: " 1fr 3fr",
                      alignItems: "center",
                    }}
                  >
                    <Space>
                      <Space>
                        <Skeleton.Image active={true} size={"large"} shape={"round"} block={false} />
                      </Space>
                      <Space direction="vertical">
                        <Skeleton.Button active={true} size={"small"} shape={"round"} block={true} />
                        <Skeleton.Avatar active={true} size={"small"} shape={"round"} block={false} />
                        <Skeleton.Input active={true} size={"small"} shape={"round"} block={true} />
                      </Space>
                    </Space>
                  </div>
                ))
                :
                branches && branches.length > 0 &&
                branches.map((branch, index) => (
                  <div
                    onClick={() => handleClickBranch(branch)}
                    key={index + 1}
                    style={{
                      borderRadius: "15px",
                      width: "95%",
                      justifyContent: "space-between",
                      margin: "20px auto",
                      background: "#fff",
                      padding: "10px",
                      display: "grid",
                      cursor: "pointer",
                      gridTemplateColumns: " 1fr 3fr",
                      alignItems: "center",
                      gap: "5px"
                    }}
                  >
                    <img src={Url + branch?.image} width={78} height={76} style={{ borderRadius: "12px", marginRight: 10 }} alt="image" />
                    <div style={{ display: "grid" }}>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center", fontWeight: "700", fontSize: "0.9em", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
                        }}
                      >
                        <h4 style={{ width: "100%", fontWeight: "700", fontSize: "0.9em", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", textTransform: "capitalize" }}>
                          {language == 'en' ? branch?.name : branch?.nameArabic}
                        </h4>
                        <div
                          style={{
                            color: '#0085d1',
                            margin: "8px",
                            backgroundColor: "white",
                            border: "none",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ display: "flex", alignItems: "center", fontSize: "14px" }}><CiClock2 style={{ marginRight: "0.25em" }} />{store?.deliveryTime}</p>
                        </div>
                      </span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <span
                          style={{
                            color: "#48A0DB",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          }
        </div>
      </div >
    </>
  );
};
