import React from 'react'
import { useSelector } from 'react-redux'

function EmptyOrder() {
    const {language}= useSelector(state => state)
    return (
        <>
         <div style={{margin:"250px auto",textAlignL:"center" ,display:"flex",flexDirection:"column",gap:10,justifyContent:"center",justifySelf:"center"}}>
            <img loading="lazy" src='/cart.badge.minus.png' style={{margin:"10px auto"}} width={150} height={150} alt='img'/> 
            <h4 style={{margin:"0px auto",fontSize:18 ,fontWeight:600}}>{language=="en"? "You haven’t placed any orders yet!":"لم تقم بتقديم أي طلبات حتى الآن!"}</h4>
             <h5 style={{margin:"0px auto",fontSize:16 ,fontWeight:400}}>{language=="en"? "Orders placed will be displayed here.":"سيتم عرض الطلبات التي تم تقديمها هنا."}</h5>
         </div>
        </>
    )
}

export default EmptyOrder
