import React, { useEffect, useContext } from "react";
import { Empty, Select, Skeleton, Space } from "antd";
import { useState } from "react";
import "./Landing.css";
import { Segmented } from "antd";
import { useNavigate } from "react-router-dom";
import { BiLogOut, BiSearchAlt2 } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Card, Badge } from "antd";
import Swal from "sweetalert2";
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import withReactContent from "sweetalert2-react-content";
import { Url, allAddressesApi, allStoresApi, getPicksOfWeekApi, getStoresCatApi, getSliderApi } from "../../Apis";
import { useDispatch, useSelector } from "react-redux";
import { setTheStore } from "../../store";
import { CiShoppingCart } from "react-icons/ci";
import { FaShoppingCart } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { HiMenuAlt2 } from "react-icons/hi";
import { appContext } from "../../Context/appContext";
import Header from "../../components/Header";
import { PicksOfTheWeek } from "../PicksOfTheWeek/picksOfTheWeek";
import { Carousel } from 'antd';
const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};
const { Meta } = Card;

export const Landing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [storeFilter, setStoreFilter] = useState([])
  const [loading, setLoading] = useState(false)
  const [stores, setStores] = useState([])
  const [storesCat, setStoresCat] = useState([])
  const [picksOfWeek, setPicksOfWeek] = useState([])
  const [picksOfWeekFilter, setPicksOfWeekFilter] = useState([])
  const [active, setActive] = useState("")
  const [activeCategory, setActiveCategory] = useState([])
  const [width, setWidth] = useState(window.innerWidth);
  const [storesFilter, setStoresFilter] = useState([])
  const [sliders, setSliders] = useState([]);
  const { language } = useSelector(state => state)
  // const [count , setCount] = useState(0)
  const { count, increment } = useContext(appContext)
  //select location
  const selectOptions = [{ label: "Set your delivery location", value: "Set your delivery location", disabled: true }, { label: "address1", value: "address1" }, { label: "address2", value: "address2" }];
  const cityData = {
    Zhejiang: ["Hangzhou", "Ningbo", "Wenzhou"],
    Jiangsu: ["Nanjing", "Suzhou", "Zhenjiang"],
  };
  // const [cities, setCities] = useState(cityData[provinceData[0]]);
  // const [secondCity, setSecondCity] = useState(cityData[provinceData[0]][0]);
  const token = localStorage.getItem('token');
  // const handleProvinceChange = (value) => {
  //   setCities(cityData[value]);
  //   setSecondCity(cityData[value][0]);
  // };
  // const onSecondCityChange = (value) => {
  //   setSecondCity(value);
  // };

  const getSliders = async () => {
    let response = await getSliderApi()
    let res = await response?.data?.data;
    setSliders([...res])
  }

  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  let Arr = [];
  const handleActiveCategory = (catId) => {
    // const element = document.getElementById(catId.toString());
    // console.log(element);
    // setActive(catId)
    // if(element) {
    //   element.scrollIntoView({behavior:"smooth",block:"nearest",inline:"start"});
    // }
    // console.log(catId);

    // Previous code commited by Tahseem as client 
    // if (!activeCategory.includes(catId) && !Arr.includes(catId)) {
    //   setLoading(true)
    //   Arr.push(catId)
    //   setActiveCategory(prev => [...prev, catId])
    //   setLoading(false)


    // } else {
    //   setLoading(true)
    //   setActiveCategory(prev => [...prev.filter(id => id !== catId)])
    //   setLoading(false)
    // }

    if (!activeCategory.includes(catId) && !Arr.includes(catId)) {
      setLoading(true)
      Arr = [catId]
      setActiveCategory([catId])
      setLoading(false)

    } else {
      setLoading(true)
      setActiveCategory([])
      Arr = []
      setLoading(false)
    }

  }




  useEffect(() => {
    if (activeCategory.length > 0) {
      setLoading(true)
      setStoresFilter(stores.filter(stores => activeCategory.includes(stores.categories?._id)))
      setLoading(false)
      setPicksOfWeekFilter(picksOfWeek.filter(pick => activeCategory.includes(pick.storeCategory)))

    } else {
      setStoresFilter(stores)
      setPicksOfWeekFilter(picksOfWeek)
    }
  }, [activeCategory])
  // useEffect(() => {
  //   console.log("active" , active);
  //   const inputEle= document.querySelector(".active")
  //   var time ;
  //   if (inputEle) {
  //     clearTimeout(time)
  //      time = setTimeout(() => {

  //        inputEle.scrollIntoView({behavior:"smooth",block:"nearest",inline:"start"})
  //     }, 700);
  //   }
  // }, [active])

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleDispatchStore = (store) => {
    dispatch(setTheStore(store))
    navigate(`${store?.name?.replace(/\s+/g, '-')}/branch`)
  }
  const getStoresCat = () => {
    getStoresCatApi().then(res => {
      setStoresCat(res.data.data)
    })
  }
  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  }
  useEffect(() => {
    if (searchValue?.length > 0) {
      setStoreFilter(stores.filter(store => language == "en" ? store.name.toLowerCase()?.includes(searchValue.toLowerCase()) : store.nameArabic.toLowerCase()?.includes(searchValue.toLowerCase())))

    } else {
      setStoreFilter(stores)
    }
  }, [searchValue])
  // const getAllAddresses = () =>{
  //   allAddressesApi().then((res) =>{
  //     console.log(res?.data?.data);
  //     let allAddress = []
  //     res?.data?.data?.map(address =>{
  //       let oneAddress = {label:address?.city + " , " + address?.area + " , " + address?.street + " , " + address?.apartment + " , " + address?.buildingNo  + " , " + address?.floor, value:address?._id}
  //       allAddress.push(oneAddress)
  //       // setOptions(prev => [...prev,oneAddress])
  //     })
  //     setOptions(allAddress)
  //   }).catch(err => console.log(err))
  // }
  const getAllStores = () => {
    setLoading(true)
    allStoresApi().then(res => {
      setStores(res?.data?.data?.filter(data => data.isDeleted == false))
      setStoreFilter(res?.data?.data?.filter(data => data.isDeleted == false))
      setStoresFilter(res?.data?.data?.filter(data => data.isDeleted == false))
      setLoading(false)

    })
  }
  const getPicksOfWeek = () => {
    // setLoading(true)
    getPicksOfWeekApi().then(response => {
      setPicksOfWeek(response.data.data)
      setPicksOfWeekFilter(response.data.data)
      // setLoading(false)
    })
  }
  useEffect(() => {
    //   if(token){
    //   increment()
    // }
    getStoresCat()
    getPicksOfWeek()
    getAllStores()
    getSliders()
    // getAllAddresses()
  }, [])
  const handlelogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#48A0DB',
      confirmButtonText: 'ok'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token")
        navigate("/login")
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    })
  }
  // const [cities, setCities] = useState(cityData[provinceData[0]]);
  // const [secondCity, setSecondCity] = useState(cityData[provinceData[0]][0]);
  // const handleProvinceChange = (value) => {
  //   setCities(cityData[value]);
  //   setSecondCity(cityData[value][0]);
  // };
  // const onSecondCityChange = (value) => {
  //   setSecondCity(value);
  // };
  ////
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F5F5F5", minHeight: "100vh" }}>
        <div
          style={{
            width: "90%",
            height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
          }}
        >
          <Header stores={stores} toggleOrderType={false} />
          {/* <Select
          //  defaultValue={selectOptions[0].value}
           placeholder="Set your delivery location"
            className="select-address"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            allowClear
            // onClear={}
            showSearch
            style={{
              width: "100%",
              //   boxSizing:"border-box",
              //   padding:"1.2em 1em",
              marginTop: "2em",
            }}
            //onChange={handleProvinceChange}
            // options={provinceData.map((province) => ({
            //   label: province,
            //   value: province,
            // }))}
            options={options}
          /> */}
          <div style={{ marginTop: "1.2em" }}>
            <Carousel afterChange={onChange} autoplay dotPosition="bottom" style={{ borderRadius: "15px", marginTop: "1em" }}>
              {sliders.map((item, index) => (
                <div key={index + 1} >
                  <div className="landing-bg-div" style={{ display: "flex", backgroundImage: `url(${Url + item?.images})`, backgroundRepeat: "no-repeat" }}>
                    <div className="title-div" style={{ position: "relative" }}>
                      <p className="landing-title">{item.header}</p>
                      <p className="landing-subtitle">{item.subHeader}</p>
                      {/* <div style={{padding:"10px 15px",position:"absolute"}}>
                    <ul style={{display:"flex" ,flexDirection:"row",justifyContent:"space-around",width:"30%"}}>
                      <li style={{color:"#fff",background:"#fff",margin:" 0 10px"}}></li>
                      <li style={{color:"#fff",background:"#fff", margin:" 0 10px"}}></li>
                      <li style={{color:"#fff",background:"#fff", margin:" 0 10px"}}></li>
                      <li style={{color:"#fff",background:"#fff", margin:" 0 10px 30px 0"}}></li>
                    </ul>
                  </div> */}
                    </div>
                    <div className="bg-div" >
                      {/* <img className="bg" src={Url+item?.images} /> */}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>

          </div>
          <div
            style={{ display: "flex", position: "relative", margin: "1.5em auto" }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            <BiSearchAlt2
              style={{
                position: "absolute",
                top: "25%",
                left: language == "en" ? "10px" : "auto",
                right: language == "en" ? "auto" : "10px",
                // fontSize: "20px",
                width: "20px",
                height: "50%"
              }}
            />

            <input
              type="text"
              value={searchValue}
              onChange={(e) => handleSearch(e)}
              style={{
                width: "100%",
                // margin: "2em auto",
                boxSizing: "border-box",
                padding: "1.2em 1em",
                textIndent: "25px",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0 0 4px lightgray",
              }}
              placeholder={language == "en" ? "Search" : "يبحث"}
            />
            {searchValue.length > 0 &&

              <div style={{ width: "100%", position: "absolute", background: "#eee", maxHeight: "450px", boxShadow: "0 0px 8px #00000075", overflow: "auto", borderRadius: "12px", marginTop: "3.9em", zIndex: "999", transition: "all ease 1s" }}>
                <ul style={{ listStyle: "none" }} dir={language == "en" ? "ltr" : "rtl"}>
                  {storeFilter.length == 0 ? <Empty style={{ margin: "80px 0" }} image={Empty.PRESENTED_IMAGE_SIMPLE} /> : storeFilter?.map((store ,i) => (
                    <React.Fragment key = {i+1}>
                      <li style={{ padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <img src={Url + store?.logo} width={width > 400 ? 70 : 50} height={width > 400 ? 70 : 50} style={{ borderRadius: "10px" }} alt="img" />
                        <h5 style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", margin: "auto", fontSize: "16px" }}>{language == "en" ? store?.name : store?.nameArabic}</h5>
                        <button style={{ color: "#fff", background: "#48A0DB", padding: "8px 10px", border: "none", borderRadius: "10px", cursor: "pointer" }} onClick={() => navigate(`/${store?.name?.replace(/\s+/g, '-')}/branch`)}>{language == "en" ? "Chose Store" : "اختر المتجر"}</button>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>

              </div>
            }

          </div>
          <div
            className="btns-div"
            style={{
              width: "100%",
              margin: "0em auto",
              boxSizing: "border-box",
              padding: "0em 1em",
              border: "none",
              outline: "none",
              borderRadius: "10px",
              overflow: "auto",
              display: "flex",
              whiteSpace: "nowrap",
              scrollbarWidth: "none",
              scrollbarColor: "transparent transparent",
              msOverflowStyle: "none",
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            {loading ? <>
              {[1, 2, 3, 4, 5].map((el, i) => (
              <React.Fragment key = {i+1}>
                <Space key={i*3} >
                  <Skeleton.Button style={{ margin: "1.5em 0.5em" }} active={true} size={"large"} shape={"round"} block={false} />
                </Space>
              </React.Fragment>
              ))}
            </> : storesCat.map((data, i) => (
              <button
                id={data?.store}
                key={i + 1}
                // className={active == data?._id?"active":""}
                onClick={() => handleActiveCategory(data?._id)}
                style={{
                  border: "1px solid #48A0DB",
                  borderRadius: "50px",
                  margin: "1em 0.5em",
                  padding: ".6em 2em",
                  display: "flex",
                  alignItems: "center",
                  zIndex: "99",
                  outline: "none",
                  cursor: "pointer",
                  position: "relative",
                  background: activeCategory.includes(data?._id) ? "#48A0DB" : "#f5f5f5",
                  color: activeCategory.includes(data?._id) ? "#fff" : "#000"
                }}
                dir={language == "en" ? "ltr" : "rtl"}
              >
                {/* <img width={20} height={20} style={{borderRadius:"15px",marginRight:"10px",marginLeft:"-10px",boxShadow:"0 0 4px"}} src={Url + data?.image} alt="img" /> */}
                {language == "en" ? data?.englishName : data?.arabicName}
                {activeCategory.includes(data?._id) &&
                  <CloseOutlined style={{ right: 7, color: "#fff", position: "absolute", lineHeight: "0" }} />
                }
              </button>
            ))}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }} dir={language == "en" ? "ltr" : "rtl"}>
            <p
              style={{ fontSize: "22px", fontWeight: "600", margin: "1em 0em" }}
            >
              {language == "en" ? "New On Utrac" : "الجديد في يوتراك"}
            </p>
            <p style={{ display: "flex", alignItems: "center", fontSize: "14px", cursor: "pointer" }} onClick={() => navigate("/restaurants")}>
              {language == "en" ? "View All" : "عرض الكل"}
              {language == "en" ? <FiChevronRight
                style={{ marginLeft: "5px", color: "rgba(72, 160, 219, 1)" }}
              /> : <FiChevronLeft
                style={{ marginLeft: "5px", color: "rgba(72, 160, 219, 1)" }}
              />}
            </p>
          </div>
          <div
            className="stores-div"
            style={{
              overflow: "auto",
              whiteSpace: "nowrap",
              // scrollbarWidth: "none",
              // scrollbarColor: "transparent transparent",
              // msOverflowStyle: "none",

            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            {loading ?
              //  <Spin style={{margin:"60px auto",fontSize:"30px"}} indicator={antIcon} /> 
              [1, 2, 3].map((j, i) => (
                <div
                  key={i + 1}
                  className="store"
                >
                  <Space
                    direction="vertical"
                    size="small"
                    style={{
                    }}>
                    <Space>
                      <Skeleton.Image style={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px", width: "220px", height: "120px" }} active={true} size={"large"} shape={"round"} />
                    </Space>
                    {/* <br /> */}
                    <Space direction="vertical" style={{
                      width: "40%",
                      padding: "0.4em 0.5em"
                    }}>
                      <Skeleton.Button style={{ height: 15 }} active={true} size={"small"} shape={"round"} block={true} />
                      <Space size={"large"} direction="horizontal" >
                        {/* <Skeleton.Input style={{height:15}} active={true} size={"small"} shape={"round"} block={true} /> */}
                        <Skeleton.Input style={{ height: 15 }} active={true} size={"small"} shape={"round"} block={true} />
                      </Space>
                    </Space>
                  </Space>
                </div>))
              : storesFilter?.length == 0 ? <><Empty style={{ margin: "22px auto" }} image={Empty.PRESENTED_IMAGE_SIMPLE} /></> :
                storesFilter?.map((store) => (
                  <div
                    id={store?.categories?._id}
                    key={store?._id}
                    className="store"
                    onClick={() => handleDispatchStore(store)}
                    style={{ boxShadow: active == store?.categories?._id ? "0 0 10px" : "inherit" , background: "#fff" }}
                  >
                    <div
                      style={{
                        width: "220px",
                        height: "120px",
                        backgroundImage: `url(${Url + store?.logo})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                        position: "relative",
                      }}
                    >
                      {store?.deliveryTime &&
                        <div
                          style={{
                            padding: "0.15em 1em",
                            backgroundColor: "#F6A94A",
                            borderRadius: "5px",
                            position: "absolute",
                            bottom: "-42px",
                            right: language == "en" ? "10px" : "auto",
                            left: language == "en" ? "auto" : "10px",
                            color: "white",
                            fontSize: "12px"
                          }}
                        >
                          <p>{store?.deliveryTime?.split(' ')[0]}</p>
                          <p>{store?.deliveryTime?.split(' ')[1]}</p>
                        </div>
                      }
                    </div>
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "0.15em 1em",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                      }}
                    >
                      <div
                        style={{
                          padding: "0.15em",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        {language == "en" ? store?.name : store?.nameArabic}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          padding: "0.15em",
                        }}
                      >
                        <p style={{ color: "gray", fontSize: "14px" }}>{language == "en" ? store?.categories?.englishName : store?.categories?.arabicName}</p>
                        {/* <p style={{ color: "#F6A94A" }}>
                      <span
                        style={{
                          marginRight: "10px",
                          padding: "0.25em",
                          backgroundColor: "lightgray",
                          display: "flex",
                          display: "inline-block",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <AiFillStar style={{ color: "#F6A94A" }} />
                        4.5
                      </span>
                      20% off
                    </p> */}
                      </div>
                    </div>
                  </div>
                ))}
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }} dir={language == "en" ? "ltr" : "rtl"}>
            <p
              style={{ fontSize: "22px", fontWeight: "600", margin: "1em 0em" }}
            >
              {language == "en" ? "Picks Of The Week" : "اختيارات الأسبوع"}
            </p>
            <p style={{ display: "flex", alignItems: "center", fontSize: "14px", cursor: "pointer" }} onClick={() => navigate("/picks-of-the-weeks")}>
              {language == "en" ? "View All" : "عرض الكل"}
              {language == "en" ? <FiChevronRight
                style={{ marginLeft: "5px", color: "rgba(72, 160, 219, 1)" }}
              /> : <FiChevronLeft
                style={{ marginLeft: "5px", color: "rgba(72, 160, 219, 1)" }}
              />}
            </p>
          </div>

          <div
            className="stores-div"
            style={{
              overflow: "auto",
              whiteSpace: "nowrap",
              // scrollbarWidth: "none",
              // scrollbarColor: "transparent transparent",
              // msOverflowStyle: "none",

            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            {loading ?
              [1, 2, 3].map((j, i) => (
                <div
                  key={i + 1}
                  className="picks"
                  style={{ width: "160px" }}
                >
                  <Space
                    direction="vertical"
                    size="small"
                    style={{
                    }}>
                    <Space>
                      <Skeleton.Image style={{ width: "140px" }} active={true} size={"large"} shape={"round"} />
                    </Space>
                    <br />
                    <Space direction="vertical" style={{
                      width: "40%",
                      padding: "0 0.5em"
                    }}>
                      <Skeleton.Button style={{ height: 15 }} active={true} size={"small"} shape={"round"} block={true} />
                      <Space size={"large"} direction="horizontal" >
                        {/* <Skeleton.Input style={{height:15}} active={true} size={"small"} shape={"round"} block={true} /> */}
                        <Skeleton.Input style={{ height: 15 }} active={true} size={"small"} shape={"round"} block={true} />
                      </Space>
                    </Space>
                  </Space>
                </div>))

              : picksOfWeekFilter.length == 0 ? <><Empty style={{ margin: "22px auto" }} image={Empty.PRESENTED_IMAGE_SIMPLE} /></> : picksOfWeekFilter.map((data, i) => (
                <div
                  key={i + 1}
                  className="picks"
                  style={{ width: "160px", cursor: "pointer" }}
                  onClick={() => navigate(`/${stores && stores?.filter(stores => stores?._id == data?.store._id)[0]?.name?.replaceAll(/\s+/g, '-')}/${data?.englishName?.replaceAll(/\s+/g, '-')}`)}
                >
                  <div
                    style={{
                      width: "160px",
                      height: "120px",
                      backgroundImage: `url(${Url + data?.productImage})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      borderRadius: "15px",
                      // borderTopRightRadius: "15px",
                      position: "relative",
                    }}
                  >
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgb(245, 245, 245)",
                      padding: "0.18em 0.5em",
                      borderBottomLeftRadius: "15px",
                      borderBottomRightRadius: "15px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px"
                    }}
                  >
                    <p
                      style={{
                        padding: "0.16em 0",
                        fontWeight: "500",
                        fontSize: "16px",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                    >
                      {language == "en" ? data?.englishName : data?.arabicName}
                    </p>
                    {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0.15em",
                    }}
                  > */}
                    <p style={{ color: "gray", fontSize: "12px" }}>{stores && language == "en" ? stores?.filter(stores => stores?._id == data?.store._id)[0]?.name : stores?.filter(stores => stores?._id == data?.store._id)[0]?.nameArabic}</p>
                    <p style={{ color: "rgba(0, 163, 224, 1)" }}>{language === "en" ? 'SAR' + " " + data?.salePrice : data?.salePrice + " " + "س.ر"}</p>
                    {/* <p style={{ color: "#F6A94A" }}>
                      <span
                        style={{
                          marginRight: "10px",
                          padding: "0.25em",
                          backgroundColor: "lightgray",
                          display: "flex",
                          display: "inline-block",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <AiFillStar style={{ color: "#F6A94A" }} />
                        4.5
                      </span>
                      20% off
                    </p> */}
                    {/* </div> */}
                  </div>
                </div>
              ))}
          </div>
          {/* <div
            className="cards-div"
            style={{
              width: "100%",
              // height:"800px",
              //  overflowY:"scroll"

              marginTop: "2em",
            }}
          >
            {[1, 2, 3].map(() => (
              //     <Card
              //     hoverable
              //     style={{
              //       width: "100%",
              //       marginTop:"1em"
              //     }}
              //     cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
              //   >
              //     <Meta title="Europe Street beat" description="www.instagram.com" />
              //   </Card>
              <div
                style={{
                  width: "100%",
                  borderRadius: "15px",
                  margin: "1em 0em",
                  boxShadow: "0 0 8px lightgray",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "200px",
                    backgroundImage: "url(./burger.png)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      padding: "0.15em 1em",
                      backgroundColor: "#F6A94A",
                      borderRadius: "5px",
                      position: "absolute",
                      bottom: "10px",
                      right: "10px",
                      color: "white",
                    }}
                  >
                    10-15 <br />
                    mins
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "0.5em 1em",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      padding: "0.5em",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    crepaway
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0.5em",
                    }}
                  >
                    <p style={{ color: "lightgray" }}>fast food burger</p>
                    <p style={{ color: "#F6A94A" }}>
                      <span
                        style={{
                          marginRight: "10px",
                          padding: "0.25em",
                          backgroundColor: "lightgray",
                          display: "flex",
                          display: "inline-block",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <AiFillStar style={{ color: "#F6A94A" }} />
                        4.5
                      </span>
                      20% off
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
};
