import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaChevronLeft, FaCopy } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AiFillStar, AiOutlineClose } from "react-icons/ai";
import { addToCartApi, getOrderApi } from "../../Apis";
import {
  Drawer,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  message,
  notification,
} from "antd";
import { BsInfoCircleFill, BsTelephone } from "react-icons/bs";
import { useJwt } from "react-jwt";
import CopyToClipboard from "react-copy-to-clipboard";
import EmptyCart from "../../components/emptyCart";
import EmptyOrder from "../../components/emptyOrder";
import { useContext } from "react";
import { appContext } from "../../Context/appContext";
import { LoadingOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { useSelector } from "react-redux";
import moment, { lang } from "moment";
import "moment/locale/en-ca";
import "moment-timezone";
export const OrderHistory = () => {
  const {language}= useSelector(state => state)
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [oneOrder, setOneOrder] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const token = localStorage.getItem("token");
  const [orderId, setOrderId] = useState("");
  const { decodedToken, isExpired } = useJwt(token);
  const { addToCart, setStoreId, getCartData } = useContext(appContext);
  const [api, contextHolder] = notification.useNotification();
  const paymentToken = useSelector((state) => state.paymentToken);
  const ref = useRef(null);
  const antIconOrder = (
    <LoadingOutlined
      style={{
        fontSize: 18,
        color: "#fff",
      }}
      spin
    />
  );
  const showDrawer = (data) => {
    console.log(data);
    setOrderDetails(data?.orderDetails);
    setOneOrder(data);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const copyPhoneNumber = () => {
    message.success("phone number copied successfully");
  };
  const addOrderAgain = (data) => {
    console.log("data", data.orderDetails);
    setOrderId(data._id);
    console.log(data.store._id);
    const store = data.store;
    console.log(store);

    setStoreId(data.store._id);
    // if (cartItems?.data?.products?.length>0) {
    let currentTime = new Date().toLocaleString("en-US", {
      // timeZone: "Africa/Cairo",
      timeZone: "Asia/Riyadh",
    });
    const openNotification = (placement) => {
      api.info({
        // message: `Notification ${placement}`,
        description: (
          <span style={{ color: "white" }}>
            {store && language == "en"
      ? ` We're closed - our opening hours: ${store[0]?.workingHours[0].openingHours} -> ${store[0]?.workingHours[0].closeingHours}`
      : store &&
        `${store[0]?.workingHours[0].closeingHours} <- ${store[0]?.workingHours[0].openingHours} : لقد أغلقنا - ساعات عملنا`}{" "}
          </span>
        ),
        placement,
        closeIcon: <></>,
        className: "notification",
        icon: <BsInfoCircleFill style={{ color: "white" }} />,
        style: {
          background: "#48A0DB",
          color: "#fff",
        },
      });
    };
    // //console.log(moment(store[0].workingHours[0]?[0].closeingHours , "h A").format("h A"));
    const open = moment(
      store?.workingHours[0]?.openingHours,
      "hh:mm A"
    ).hours();
    const close = moment(
      store?.workingHours[0]?.closeingHours,
      "hh:mm A"
    ).hours();
    //console.log("open", open);
    //console.log("close", close);

    const currentHr = moment(currentTime.split(",")[1], "hh:mm A").subtract(
      1,
      "hour"
    );
    let openTime;
    let closeTime;
    if (
      (open >= close &&
        ((moment(store?.workingHours[0]?.closeingHours, "hh:mm A").format(
          "A"
        ) === "AM" &&
          moment(store?.workingHours[0]?.openingHours, "hh:mm A").format(
            "A"
          ) === "AM") ||
          (moment(store?.workingHours[0]?.closeingHours, "hh:mm A").format(
            "A"
          ) === "PM" &&
            moment(store?.workingHours[0]?.openingHours, "hh:mm A").format(
              "A"
            ) === "PM"))) ||
      (moment(store?.workingHours[0]?.openingHours, "hh:mm A").format("A") ===
        "PM" &&
        moment(store?.workingHours[0]?.closeingHours, "hh:mm A").format("A") ===
          "AM")
    ) {
      closeTime = moment(
        store?.workingHours[0].openingHours,
        "hh:mm A"
      ).second(0);

      openTime = moment(
        store?.workingHours[0].closeingHours,
        "hh:mm A"
      ).second(0);

      const ksaTime = moment().tz("Asia/Riyadh");

      if (ksaTime.isBetween(openTime, closeTime)) {
        openNotification(width > 500 ? "bottomLeft" : "bottom");
        return;
      }
    } else {
      openTime = moment(
        store?.workingHours[0].openingHours,
        "hh:mm A"
      ).second(0);
      closeTime = moment(
        store?.workingHours[0].closeingHours,
        "hh:mm A"
      ).second(0);
      const ksaTime = moment().tz("Asia/Riyadh");

      if (!ksaTime.isBetween(openTime, closeTime)) {
        openNotification(width > 500 ? "bottomLeft" : "bottom");
        return;
      }
    }

    const current = moment("11:00 PM", "hh:mm A");
    // const open = moment("10:00 AM","hh:mm A")
    // const close = moment("6:00 PM","hh:mm A")
    const now = moment();
    const start = moment().hour(6).minute(0).second(0);
    const end = moment().hour(22).minute(0).second(0);
    //console.log(moment().subtract(1, "hour").isBetween(openTime, closeTime));
    var startTime = store?.workingHours[0]?.openingHours;
    var endTime = store?.workingHours[0]?.closeingHours;

    let currentDate = new Date();

    // let startDate = new Date(currentDate.getTime());
    // startDate.setHours(startTime?.split(":"));
    // startDate.setMinutes(startTime?.split(":")[1]);
    // startDate.setSeconds(startTime.split(":")[2]);

    // let endDate = new Date(currentDate.getTime());
    // endDate.setHours(endTime?.split(":"));
    // endDate.setMinutes(endTime?.split(":")[1]);
    // endDate.setSeconds(endTime.split(":")[2]);

    console.log("currentHr", moment().subtract(1, "hour").format("hh:mm A"));
    console.log({ open: openTime, check: currentHr.isBefore(openTime) });
    //console.log( moment(store.workingHours[0]?.openingHours, "h A"));
    console.log({ close: closeTime, check: current.isAfter(closeTime) });
    console.log("format", moment().format("A"));

   // const ksaTime = moment().tz("Asia/Riyadh");

    // if (!ksaTime.isBetween(openTime, closeTime)) {
    //   openNotification(width > 500 ? "bottomLeft" : "bottom");
    //   return;
    // }
    setOrderLoading(true);
    let Arr = [];
    data?.orderDetails?.forEach(async (data) => {
      Arr.push({
        product: data?.product?._id,
        price: data?.product?.salePrice,
        note: data?.note,
        addons: data?.addons?.map((data) => data._id),
        quantity: data?.quantity,
      });
    });
    console.log(Arr);
    const storeId = data.store._id;
    let obj = {
      products: [...Arr],
      store: storeId,
    };
    addToCartApi(obj)
      .then((res) => {
        console.log(res.data.data);
        message.success(res.data.messageEn);
        setOrderLoading(false);
        navigate(`/${data?.store?.name?.replace(/\s+/g, "-")}/checkout`);
        // getCartData()
      })
      .catch((err) => {
        console.log(err.message);
        message.error(err.message);
      });

    //  }
  };

  const getOrders = () => {
    setLoading(true);
    getOrderApi().then((res) => {
      console.log("theOrders",res.data.data);
      setOrders(res.data.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    getOrders();
    console.log("paymentToken", paymentToken);
  }, []);
  return (
    <>
      {contextHolder}
      <div
        style={{
          width: "100%",
          backgroundColor: "#F5F5F5",
          minHeight: "100vh",
          paddingBottom: orders.length > 0 && "1em",
        }}
      >
        <div
          style={{
            width: "90%",
            height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            style={{
              width: "45px",
              height: "45px",
              padding: "0.25em",
              border: "none",
              outline: "none",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "0 0 4px lightgray",
              color: "rgba(72, 160, 219, 1)",
            }}
            onClick={() => navigate("/")}
          >
            <FaChevronLeft />
          </button>
          <h2
            style={{
              fontWeight: "600",
              margin: "0em auto",
              position: "absolute",
              // right: "70%",
              left: "25%",
              width: "50%",
              textAlign:"center"
            }}
          >
            {language=="en"?"Order History":"أرشيف الطلبات"}
          </h2>
        </div>
        {loading ? (
          [1, 2, 3, 4, 5].map(() => (
            <div
              style={{
                width: "90%",

                margin: "1em auto",
                padding: "1em 0.5em",
                boxSizing: "border-box",
                // display:"flex",
                // alignItems:"center"
                backgroundColor: "white",
                borderRadius: "15px",
              }}
              dir={language=="en" ? "ltr":"rtl"}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 0.5em",
                  gap: 15,
                }}
                dir={language=="en" ? "ltr":"rtl"}
              >
                <Space size={"middle"}>
                  <Skeleton.Button
                    style={{ height: 10 }}
                    active={true}
                    size={"large"}
                    shape={"round"}
                    block={false}
                  />
                  <Skeleton.Button
                    style={{ height: 10 }}
                    active={true}
                    size={"large"}
                    shape={"round"}
                    block={true}
                  />
                </Space>

                <Space size={"middle"} direction="horizontal">
                  <Skeleton.Button
                    style={{ height: 10 }}
                    active={true}
                    size={"small"}
                    shape={"round"}
                    block={false}
                  />
                  <Skeleton.Button
                    style={{ height: 10 }}
                    active={true}
                    size={"small"}
                    shape={"round"}
                    block={false}
                  />
                </Space>

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Space direction="vertical">
                    <Skeleton.Button
                      style={{ height: 30, width: 150 }}
                      active={true}
                      size={"large"}
                      shape={"round"}
                      block={false}
                    />
                  </Space>
                  <Space direction="vertical">
                    <Skeleton.Button
                      style={{ height: 30, width: 140, marginLeft: 8 }}
                      active={true}
                      size={"large"}
                      shape={"round"}
                      block={false}
                    />
                  </Space>
                </div>
              </div>
            </div>
          ))
        ) : orders.length > 0 ? (
          orders.map((order) => (
            <div
              style={{
                width: "90%",
                height: "100%",

                margin: "1em auto",
                padding: "1em 0.5em",
                boxSizing: "border-box",
                // display:"flex",
                // alignItems:"center"
                backgroundColor: "white",
                borderRadius: "15px",
                cursor: "pointer",
              }}
              dir={language=="en" ? "ltr":"rtl"}
            >
              <div
                style={{ display: "flex", width: "95%", margin: "auto" }}
                onClick={() => showDrawer(order)}
              >
                <div style={{ width: "60%" }}>
                  <p style={{ fontWeight: "500", fontSize: "18px" }}> {language=="en"?"Order":"طلب"}{`#${order?.orderNumber?order?.orderNumber:" "}`}</p>
                  <p style={{ fontSize: "14px", marginTop: "0.5em" }}>
                    {order?.orderType=="PICkUP"?language=="ar"?"من المتجر":"PICkUP":order?.orderType!=="PICkUP"?language=="ar"?"توصيل":"Delivery":""}
                  </p>
                  <p
                    style={{
                      color: "rgba(72, 160, 219, 1)",
                      fontSize: "16px",
                      marginTop: "0.5em",
                    }}
                  >
                    {language=="en"?
                    `SAR ${order?.salePrice}`: <>

                  <p
                  style={{
                    color: "rgba(72, 160, 219, 1)",
                    fontSize: "16px",
                    marginTop: "0.5em",
                    display: "inline-block",
                   
                  }}
                >{order?.salePrice}</p>
                    <p
                    style={{
                      color: "rgba(72, 160, 219, 1)",
                      fontSize: "16px",
                      marginTop: "0.5em",
                      marginRight:"0.25em",
                      display: "inline-block",
                    }}
                  > س.ر</p> 
                </>}
                  </p>

                </div>
                <div style={{ width: "40%" }}>
                  <p
                    style={{
                      textAlign: "right",
                      color: "gray",
                      fontSize: "14px",
                    }}
                  >
                    {new Date(order?.createdAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                  <p
                    style={{
                      textAlign: "right",
                      color:
                        order?.status == "PROCESSING"
                          ? "#f6a94a"
                          : order?.status == "CANCELLED"
                          ? "#dc3545"
                          : "rgba(3, 204, 48, 1)",
                      fontSize: "12px",
                      marginTop: "0.8em",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        backgroundColor:
                          order?.status == "PROCESSING"
                            ? "#f6a94a"
                            : order?.status == "CANCELLED"
                            ? "#dc3545"
                            : "rgba(3, 204, 48, 1)",
                        borderRadius: "50%",
                        marginRight: "0.5em",
                      }}
                    ></span>{" "}
                    {order?.status}
                  </p>
                  <p
                    style={{ color: "gray", textAlign: "right", marginTop: 10 }}
                  >
                    {language=="en"? order?.store?.name:order?.store?.nameArabic}
                  </p>
                  {/* <p style={{ textAlign: "right", marginTop: "0.8em" }}>
                <AiFillStar style={{ color: "rgba(72, 160, 219, 1)" }} />
                <AiFillStar style={{ color: "rgba(72, 160, 219, 1)" }} />
                <AiFillStar style={{ color: "rgba(72, 160, 219, 1)" }} />
                <AiFillStar style={{ color: "rgba(72, 160, 219, 1)" }} />
                <AiFillStar style={{ color: "rgba(72, 160, 219, 1)" }} />
              </p> */}
                </div>
              </div>
              <div
                style={{
                  width: "95%",
                  margin: "1em auto 0.5em",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    width: "55%",
                    //   marginTop: "2em",
                    padding: "0.7em 1em",
                    border: "1px solid rgba(72, 160, 219, 1)",
                    color: "rgba(72, 160, 219, 1)",
                    display: "flex",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                    display: "flex",
                    fontSize: width > 420 ? "16px" : "14px",
                    cursor: "pointer",
                    fontWeight:  language=="en"?"500":"600"
                    // boxShadow: "0 0 4px lightgray",
                  }}
                >
                  <a
                    style={{
                      color: "rgba(72, 160, 219, 1)",
                      textDecoration: "none",
                    }}
                    href={`tel:${order?.store?.phoneNumber}`}
                  >
                     {language=="en"?"Call Support":"طلب الدعم"}
                  </a>
                </button>
                <button
                  style={{
                    width: "40%",
                    // marginTop: "2em",
                    padding: "0.8em 1em",
                    border: "none",
                    outline: "none",
                    display: "flex",
                    backgroundColor: "#F6A94A",
                    color: "white",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 0 4px lightgray",
                    fontSize: width > 420 ? "16px" : "14px",
                    cursor: "pointer",
                    fontWeight:  language=="en"?"500":"600"
                  }}
                  onClick={() => addOrderAgain(order)}
                  disabled={orderLoading}
                >
                  {orderLoading && orderId == order._id ? (
                    <Spin indicator={antIconOrder} />
                  ) : (
                    language=="en"?"Order Again":"أطلب مجددا"
                  )}
                </button>
              </div>
            </div>
          ))
        ) : (
          <>
            <EmptyOrder />

            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "2em 1em",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                    padding: "1em 1em",
                    border: "none",
                    outline: "none",
                    display: "flex",
                    backgroundColor: "#F6A94A",
                    color: "white",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    boxShadow: "0 0 4px lightgray",
                  }}
                  onClick={() => navigate(`/`)}
                >
                  {language=="en"? "Order Now":"اطلب الآن"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <Drawer
        title={language =="en"? "Order Details":"تفاصيل الطلب"}
        placement={"bottom"}
        closable={false}
        onClose={onClose}
        open={open}
        extra={
          <AiOutlineClose
            style={{ fontSize: 25, cursor: "pointer" }}
            onClick={onClose}
          />
        }
        height={"95%"}
        maskStyle={{
          maxWidth: "500px",
        }}
        contentWrapperStyle={{
          maxWidth: "500px",
          direction:language=="en" ? "ltr":"rtl"
        }}
        style={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          // borderBottomLeftRadius:15,
          // borderBottomRightRadius:15,
          backgroundColor: "white",
          boxShadow: "0 0 4px lightgray",
          maxWidth: "500px",
        }}
        key={"bottom"}
      >
        <div
          style={{
            background: "white",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "auto",
            height: "100%",
            borderRadius: "20px 20px 0 0 ",
            padding: "20px 20px 20px 20px",
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "500", marginTop: "20px" }}
          >
            {language=="en"? "Estimated Delivery Time":"الوقت المقدر للتسليم"}
          </span>
          <span style={{ fontWeight: "700", color: "#48A0DB" }}>
            {/* 12:30 - 12:45 */}
            {oneOrder?.estimatedTime}
          </span>
          <span
            style={{
              position: "relative",
              background: "#C4C4C4",
              borderRadius: "10px",
              width: "100%",
              height: "5px",
            }}
          >
            <span
              style={{
                width: "30%",
                height: "5px",
                borderRadius: "10px",
                background: "#48A0DB",
                zIndex: "99",
                position: "absolute",
              }}
            ></span>
          </span>
          <span style={{ fontSize: "18px", fontWeight: "500" }}>
           {language=="en"? `Order ${oneOrder?.status}`:`الطلب ${oneOrder?.status}`}
          </span>
          {/* <p style={{ fontSize: "12px", color: "#686D76", fontWeight: "400" }}>
            Your order has been received
          </p> */}

          {/* <hr style={{border:"1px solid #F1F2F3",borderColor:"#F1F2F3",margin:"15px 0"}} />
         <span style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between",alignItems:"center"}}>
          <div style={{display:"flex",flexDirection:"column",gap:"15px" , justifyContent:"start"}}>
            <span style={{fontSize:14,fontWeight:400}}>Your driver is</span>
            <span style={{fontSize:16 , fontWeight:700}}>John Dow</span>
          </div>
          <BsTelephone style={{color:"#48A0DB" , fontSize:"20px",fontWeight:"500"}}/>
         </span> */}
          <hr
            style={{
              border: "1px solid #F1F2F3",
              borderColor: "#F1F2F3",
              margin: "15px 0",
            }}
          />
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                justifyContent: "start",
              }}
            >
              <span style={{ fontSize: 14, fontWeight: 400 }}>
                {language == "en"? "Provide instructions to":"تقديم التعليمات ل"}
              </span>
              <span style={{ fontSize: 16, fontWeight: 700 }}>
                {language=="en"? oneOrder?.store?.name:oneOrder?.store?.nameArabic}
              </span>
            </div>
            {/* <Tooltip placement="topLeft" title={<><span><CopyToClipboard text={oneOrder?.store?.phoneNumber} onCopy={() => copyPhoneNumber()}><FaCopy style={{
    color: "#fff",
    fontSize: "18px",
    border: "none",
    cursor: "pointer",
   margin:"0 10px"
  }}/></CopyToClipboard>{oneOrder?.store?.phoneNumber}</span></>} trigger="click" > */}
            <a
              style={{ color: "rgba(72, 160, 219, 1)", textDecoration: "none" }}
              href={`tel:${oneOrder?.store?.phoneNumber}`}
            >
              <BsTelephone
                style={{
                  color: "#48A0DB",
                  fontSize: "20px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              />
            </a>
            {/* </Tooltip> */}
          </span>
          <hr
            style={{
              border: "1px solid #F1F2F3",
              borderColor: "#F1F2F3",
              margin: "15px 0",
            }}
          />
          <span
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <span style={{ fontSize: 14, fontWeight: 400, color: "#48A0DB" }}>
              {language == "en"? "Your order from":"طلبك من"}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 18, fontWeight: 700, color: "#48A0DB" }}>
              {language=="en"? oneOrder?.store?.name:oneOrder?.store?.nameArabic}
              </span>
              <span style={{ fontSize: 18, fontWeight: 700, color: "#48A0DB" }}>
                # {oneOrder?.orderNumber}
              </span>
            </div>
            {orderDetails?.map((data) => (
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#48A0DB",
                  marginTop: "20px",
                }}
              >
                <Space size="middle">
                  <span>{data.quantity}</span>
                  <span style={{ fontSize: 18 }}>
                    {" "}
                    {language == "en"? data.product.englishName:data.product.arabicName}
                  </span>
                </Space>
                {/* <Space size="middle"> */}

                <legend
                  style={{
                    fontSize: "12px",
                    position: "relative",
                    left: "0",
                    color: "gray",
                    marginLeft: 25,
                    marginTop: "10px",
                  }}
                >
                  <small>{language=="en"? "addons :":"الاضافات :"}</small>
                  {data?.addons?.map(
                    (addon, i) =>
                     language=="en"?addon?.name:addon.nameArabic + (i < data?.addons?.length - 1 ? " , " : "")
                  )}
                </legend>
                <legend
                  style={{
                    fontSize: "12px",
                    position: "relative",
                    left: "0",
                    color: "gray",
                    marginLeft: 25,
                    marginTop: "10px",
                  }}
                >
                  <small>{language=="en"?"notes :":"الملاحظات :"}</small>
                  {data?.note}
                </legend>
                {/* </Space> */}
              </span>
            ))}
            {/* <span  style={{fontSize:14,fontWeight:400 ,color:"#48A0DB"}}>
            <Space size="middle">
              <span>1</span> 
              <span> Long Bun Beef Meal </span> 
          </Space>
          </span> */}
          </span>
          {/* <hr style={{border:"1px solid #F1F2F3",borderColor:"#F1F2F3"}} /> */}
          <br />
          <br />

          {/* <button
            style={{
              width:"100%",
              backgroundColor: "transparent",
              padding: "0.7em 1em",
              border: "1px solid #48A0DB",
              fontSize:"16px",
              cursor:"pointer",
              fontWeight:"bold",
              borderRadius: "10px",
            }}
            onClick={() => navigate("/order-history")}
          >
           View Order
          </button> */}
        </div>
      </Drawer>
    </>
  );
};
