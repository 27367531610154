import Axios from "axios"
 export const Url = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? 'http://localhost:8000' : 'https://api-new.utrac.store'
// export const Url = "https://api.utrac.store"
// export const Url = "https://api-new.utrac.store"

 const token = localStorage.getItem("token")
// axios.defaults.headers.common['Authorization'] = token;

export const loginApi = async (data) => {
    const result = await Axios.post(`/auth/login`, data)
    return result
}
export const registerApi = async (data) => {
    const result = await Axios.post(`/auth/register`, data)
    return result
}
export const sendOtpApi = async (data) => {
    const result = await Axios.post(`/auth/login/checkOtp`, data)
    return result
}
export const reSendOtpApi = async (data) => {
    const result = await Axios.post(`/auth/login`, data)
    return result
}
export const allAddressesApi = async () => {
    const result = await Axios.get(`/auth/address/`)
    // ,{

    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }

    // })
    return result
}

export const allBranchesApi = async () => {
    const result = await Axios.get(`/branch/`)
    // ,{

    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }

    // })
    return result
}
export const allStoresApi = async () => {
    const result = await Axios.get(`/store/`)
    // ,{

    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }

    // })
    return result
}
export const allProductsApi = async (id) => {
    const result = await Axios.get(`/product/${id}`)
    // ,{

    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }

    // })
    return result
}
export const oneProductApi = async (id) => {
    const result = await Axios.get(`/product/${id}`)
    // ,{

    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }

    // })
    return result
}
export const allCategoriesApi = async (storeId) => {
    const result = await Axios.get(`/category/${storeId}`)
    // ,{

    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }

    // })
    return result
}
export const allAddonsApi = async (storeId) => {
    const result = await Axios.get(`/store/addons/${storeId}`)
    // ,{

    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }

    // })
    return result
}
export const addToCartApi = async (data) => {
    const result = await Axios.post(`/cart/`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}
export const updateCartApi = async (cartId, data) => {
    const result = await Axios.patch(`/cart/${cartId}`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}
export const getCartDataApi = async (storeId) => {
    const result = await Axios.get(`/cart/store/${storeId}`)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}
export const getPicksOfWeekApi = async () => {
    const result = await Axios.get(`/product/trending/picksOfWeek?category=""`)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}
export const getStoresCatApi = async () => {
    const result = await Axios.get(`/category/store/list`)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}

export const addTotalAmountApi = async (data) => {
    const result = await Axios.post(`/order/total`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}
export const addPaymentTokenApi = async (storeId, data, type) => {
    const result = await Axios.post(`/order/payment/checkout/store/${storeId}?type=${type}`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}
export const addShippingPriceApi = async (data, storeId) => {
    const result = await Axios.post(`/order/shipping/price/store/${storeId}`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}
export const createOrderApi = async (data) => {
    const result = await Axios.post(`/order/`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}
export const getOrderApi = async () => {
    const result = await Axios.get(`/order/`)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${tokenVal}`
    //     }
    // })
    return result
}
export const updateAccountApi = async (data) => {
    const result = await Axios.patch(`/auth/profile`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${tokenVal}`
    //     }
    // })
    return result
}

export const addAddressApi = async (data) => {
    const result = await Axios.post(`/auth/address/geoLocation`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}

export const updateAddressApi = async (id, data) => {
    const result = await Axios.patch(`/auth/address/${id}`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}

export const setDefaultAddressApi = async (data) => {
    const result = await Axios.patch(`/auth/address/setDefault`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}
export const getOrderAreasApi = async () => {
    const result = await Axios.get(`/order/area/list`)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}

export const addPromoCodeApi = async (data) => {
    const result = await Axios.post(`/order/total`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // })
    return result
}


export const getUserInfoApi = async () => {
    try {
        const result = await Axios.get(`/auth/profile`);
        console.log("getUserInfoApi", result);
        return result;
    } catch (error) {
        console.error("An error occurred in getUserInfoApi:", error);
        throw error; // Re-throw the error to be caught by the calling code if needed.
    }
};

export const getSliderApi = async () => {
    const result = await Axios.get(`/auth/slider`)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${tokenVal}`
    //     }
    // })
    return result
}

export const getStoreBranchesApi = async (storeId) => {
    const result = await Axios.get(`/branch/${storeId}`)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${tokenVal}`
    //     }
    // })
    return result
}

export const getNearestBranchApi = async (data) => {
    const result = await Axios.post(`/branch/get_nearest_branch`, data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${tokenVal}`
    //     }
    // })
    return result
}

export const getbranchProductsApi = async (id) => {
    const result = await Axios.get(`/product/branch/${id}`)
    // ,{

    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }

    // })
    return result
}

export const findBranchApi = async (id) => {
    const result = await Axios.get(`/branch/${id}`)
    // ,{

    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }

    // })
    return result
}

export const getStoreSlidersApi = async (data) => {
    const result = await Axios.post('/auth/get_store_slider', data)
    // ,{
    //     headers: {
    //         Authorization: `Bearer ${tokenVal}`
    //     }
    // })
    return result
}


export const getInitiatePaymentApi = async (data) => {
    try {
        const response = await Axios.post(`/payment/InitiatePayment`, data);
        if(response && response?.data){
            return response?.data;
        }
    } catch (error) {
        console.error("An error occurred", error);
        throw error; // Re-throw the error to be caught by the calling code if needed.
    }
};



export const executePaymentApi = async (data) => {
    try {
        const response = await Axios.post(`/payment/ExecutePayment`, data);
        if(response && response?.data){
            return response?.data;
        }
    } catch (error) {
        console.error("An error occurred", error);
        throw error; // Re-throw the error to be caught by the calling code if needed.
    }
}


