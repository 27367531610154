import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import Axios from 'axios';


//const token = `Bearer ${localStorage.getItem("token")}`;
//Axios.defaults.baseURL = "https://api-new.utrac.store";
Axios.defaults.baseURL = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? 'http://localhost:8000' : 'https://api-new.utrac.store'
Axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
console.log("window.location.hostname" , window.location.hostname)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
