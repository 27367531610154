import React, { useContext, useEffect, useMemo, useState } from "react";
import './Header.css'
import { Badge, Button, Drawer, Segmented, Space, Dropdown } from "antd";
import { FiShoppingCart } from "react-icons/fi";
import { HiMenuAlt2, HiMenuAlt3 } from "react-icons/hi";
import { appContext } from "../Context/appContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { BiChevronLeft, BiChevronRight, BiRightArrow } from "react-icons/bi";
import Swal from "sweetalert2";
import { AiOutlineClose } from "react-icons/ai";
import { useJwt } from "react-jwt";
import { getUserInfoApi } from "../Apis";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../store";
import { HiMiniChevronDown } from "react-icons/hi2";



const Header = ({ callBackFunc, toggleOrderType }) => {
  // console.log(props.stores);
  const navigate = useNavigate();
  const { count, cartQty, quantityLoading , emptyCart } = useContext(appContext);
  const CartQuantity = useMemo(() => cartQty, [cartQty]);
  const [open, setOpen] = useState(false);
  const { storeName } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const token = localStorage.getItem("token") || '';
  const { decodedToken, isExpired } = useJwt(token);
  const location = useLocation()
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [orderType, setOrderType] = useState(
    localStorage.getItem("orderType")
      ? localStorage.getItem("orderType")
      : "DELIVERY"
  );
  const { language } = useSelector((state) => state);
  const [lastName, setLastName] = useState("");
  const items = [
    {
      label:
        <div style={{ display: "flex", alignItems: "center", gap: 10 }} onClick={() => changeLanguageEn()} dir={language == "en" ? "ltr" : "rtl"}>
          <img width={30} height={30} style={{ borderRadius: 20, cursor: "pointer" }} src={"/english-flag.png"} alt="" />
          {language == "en" ? "En" : "انجليزي"}
        </div>,
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label:
        <div style={{ display: "flex", alignItems: "center", gap: 10 }} onClick={() => changeLanguageAr()} dir={language == "en" ? "ltr" : "rtl"}>
          <img width={30} height={30} style={{ borderRadius: 20, cursor: "pointer" }} src={"/saudi-flag.png"} alt="" />
          {language == "en" ? "Ar" : "عربي"}
        </div>,
      key: '1',
    },
  ];
  // console.log(decodedToken , isExpired);

  const getUserInfo = async () => {
    try {
      let response = await getUserInfoApi();
      console.log("userInfo", response);

      let res = response?.data?.data;

      setFirstName(res?.firstName);
      setLastName(res?.lastName);
    } catch (error) {
      console.error("An error occurred:", error);
      // You can handle the error here, for example, showing a message to the user.
    }
  };

  const changeLanguageAr = () => {

    localStorage.setItem("language", "ar");
    dispatch(setLanguage("ar"));
    // console.log(location.pathname?.split("/")?.shift());
    // window.location.replace(`/${language}/${location.pathname}`);

  }
  const changeLanguageEn = () => {
    localStorage.setItem("language", "en");
    dispatch(setLanguage("en"));
    // window.location.reload();
  }


  useEffect(() => {
    if (token) {
      getUserInfo();
    }
    if (!localStorage.getItem("orderType")) {
      localStorage.setItem("orderType", 'DELIVERY');
    }
  }, []);

  const handleChangeOrderType = (e) => {
    setOrderType(e);
    localStorage.setItem("orderType", e);
    if (callBackFunc) {
      emptyCart();
      callBackFunc(e);
    }
  };

  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handlelogout = () => {
    setOpen(false);
    Swal.fire({
      title: language == "en" ? "Are you sure?" : "هل انت متأكد؟",
      // text: "You won't be able to revert this!",
      icon: "warning",
      width: 260,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#48A0DB",
      confirmButtonText: language == "en" ? "ok" : "تاكيد",
      cancelButtonText: language == "en" ? "cancel" : "الغاء",
      customClass: {
        container: "containerSwl",
        title: "titleSwl",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        window.location.reload();
        // navigate("/login");
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    });
  };
  return (
    <>
      <div
        // style={{
        //   display: "flex",
        //   justifyContent: "space-between",
        //   alignItems: "center",
        // }}
        className="headerBtns"
        dir={language == "en" ? "ltr" : "rtl"}
      >
        {/* <Segmented
          options={[
            {
              label: "Delivery",
              value: "delivery",
            },
            {
              label: "Pickup",
              value: "pickup",
            },
          ]}
        /> */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
          className="segmentedBtns"

        >
          {language == "en" ?
            <HiMenuAlt2
              // dir={language=="en" ? "ltr":"rtl"}
              onClick={() => showDrawer()}
              style={{ fontSize: 27, cursor: "pointer" }}
            />
            :
            <HiMenuAlt3
              // dir={language=="en" ? "ltr":"rtl"}
              onClick={() => showDrawer()}
              style={{ fontSize: 27, cursor: "pointer" }}
            />

          }
          {toggleOrderType && <Segmented
            options={[
              {
                label: language == "en" ? "Pickup" : "من المتجر",
                value: "PICkUP",
              },
              {
                label: language == "en" ? "Delivery" : "توصيل",
                value: "DELIVERY",
              },
            ]}
            value={orderType}
            onChange={(e) => handleChangeOrderType(e)}
            style={{ padding: "0px", overflow: "hidden" }}
            dir={language == "en" ? "ltr" : "rtl"}
          />
          }
        </div>
        <Dropdown
          menu={{
            items,
          }}
          trigger={['click']}
          placement={width > 410 ? "bottom" : ""}
        >
          <div style={{ display: "flex", alignItems: "center", gap: 2 }} dir={language == "ar" ? "ltr" : "ltr"} >
            <img width={30} height={30} style={{ borderRadius: 20, cursor: "pointer" }} src={language == "ar" ? "/saudi-flag.png" : "/english-flag.png"} alt="" />
            {/* { language.charAt(0).toUpperCase() + language.slice(1)} */}
            <HiMiniChevronDown />
          </div>
        </Dropdown>


        {token ? (
          <>
            {storeName !== undefined && (
              <Badge
                style={{ cursor: "pointer" }}
                count={storeName && !quantityLoading ? CartQuantity : 0}
                offset={[-2, 0]}
                onClick={() =>
                  storeName
                    ? navigate(`/${storeName}/my-cart`)
                    : navigate(`/my-cart`)
                }
              >
                <FiShoppingCart style={{ fontSize: 26, cursor: "pointer" }} />
              </Badge>
            )}
          </>
        ) : (
          // <button
          //   style={{
          //     border: "none",
          //     borderRadius: "50px",
          //     backgroundColor: "white",
          //     padding: "0.7em 0.5em",
          //     width: "30%",
          //     cursor: "pointer",
          //     boxShadow: "0 0 4px lightgray",
          //     display:"flex",
          //     alignItems:"center",
          //     textAlign:"center",
          //     justifyContent:"center",
          //   }}
          //   onClick={() => handlelogout()}
          // >
          //   <BiLogOut style={{fontSize:"16px",margin:"auto 8px"}}/>
          //   Logout
          // </button>
          <button
            style={{
              border: "none",
              borderRadius: "50px",
              backgroundColor: "white",
              padding: "0em 0.7em",
              // width: "50%",
              lineHeight: "34px",
              color: "#000",
              cursor: "pointer",
              boxShadow: "0 0 4px lightgray",
            }}
            className="loginSignup"
            onClick={() => navigate("/login")}
          >
            {language == "en" ? " Log In/Sign Up" : "تسجيل الدخول"}
          </button>
        )}
      </div>
      <Drawer
        title=" "
        width={width > 500 ? "285px" : "70%"}
        placement={language == "en" ? "left" : "right"}
        closable={false}
        onClose={onClose}
        open={open}
        // key={'left'}
        extra={
          <AiOutlineClose
            style={{ fontSize: 25, cursor: "pointer" }}
            onClick={onClose}
          />
        }
        className="drawer-container"
        contentWrapperStyle={{ direction: language == "en" ? "ltr" : "rtl", maxWidth: 500 }}
        rootStyle={{ maxWidth: 500, overflow: "hidden" }}
        bodyStyle={{ maxWidth: 500 }}
      >
        {token && (
          <h1
            style={{
              fontSize: width > 410 ? "1.5em" : "1.4em",
              margin: "0 auto 40px",
            }}
          >
            {language == "en" ? `Hey , ${firstName} ${lastName}!` : `مرحبا , ${firstName} ${lastName}!`}
          </h1>
        )}

        <ul style={{ listStyle: "none" }}>
          {token && (
            <>
              <li
                style={{
                  margin: "20px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/account-details")}
              >
                <h4 style={{ fontSize: "16px", fontWeight: "400" }}>
                  {language == "en" ? "Account Details" : "تفاصيل الحساب"}
                </h4>
                {language == "en" ? <BiChevronRight style={{ color: "#48A0DB", fontSize: 20 }} /> : <BiChevronLeft style={{ color: "#48A0DB", fontSize: 20 }} />}
              </li>
              <li
                style={{
                  margin: "20px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/order-history")}
              >
                <h4 style={{ fontSize: "16px", fontWeight: "400" }}>
                  {language == "en" ? "Order History" : "ارشيف الطلبات"}
                </h4>
                {language == "en" ? <BiChevronRight style={{ color: "#48A0DB", fontSize: 20 }} /> : <BiChevronLeft style={{ color: "#48A0DB", fontSize: 20 }} />}
              </li>
              <hr
                style={{
                  border: "1px solid #F1F2F3",
                  borderColor: "#F1F2F3",
                  marginTop: "20px",
                }}
              />
            </>
          )}
          {token ? (
            <li
              style={{
                margin: "30px 0px 20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handlelogout}
            >
              <h4 style={{ fontSize: "16px", fontWeight: "400" }}>{language == "en" ? "Log Out" : "تسجيل خروج"}</h4>
              {/* <BiChevronRight style={{color:"#48A0DB",fontSize:18}}/> */}
            </li>
          ) : (
            <li
              style={{
                margin: "30px 0px 20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/login")}
            >
              <h4 style={{ fontSize: "16px", fontWeight: "400" }}>{language == "en" ? "Log In" : "تسجيل الدخول"}</h4>
              {/* <BiChevronRight style={{color:"#48A0DB",fontSize:18}}/> */}
            </li>
          )}
        </ul>
      </Drawer>
    </>
  );
};

export default Header;
