import React, { useContext, useEffect, useRef } from "react";
import { Select, Space, Divider, Form, Radio, Skeleton, Switch, Modal } from "antd";
import { useState } from "react";
import "./Store.css";
import { Segmented, Badge, Anchor } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { BiSearchAlt2 } from "react-icons/bi";
import { CiClock2 } from "react-icons/ci"
import { BsCashStack, BsInfoCircle } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";
import { FiChevronRight } from "react-icons/fi";
import { RiWhatsappFill } from "react-icons/ri";
import { Card } from "antd";
import { Url, allAddressesApi, allCategoriesApi, allProductsApi, allStoresApi, findBranchApi, getbranchProductsApi } from "../../Apis";
import { BiLogOut } from "react-icons/bi";
import Swal from "sweetalert2";
import { CloseOutlined, DotChartOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { FiShoppingCart } from "react-icons/fi";
import { HiMenuAlt2 } from "react-icons/hi";
import { FaChevronLeft } from "react-icons/fa";
import Header from "../../components/Header";
import { MdLocationOn } from "react-icons/md";
import { LuClock3 } from "react-icons/lu";
import { appContext } from "../../Context/appContext";
import Map from "../../components/Map";
const { Meta } = Card;

export const Store = () => {
  const anchorRef = useRef(null)
  const navigate = useNavigate();
  const { storeName, branchName } = useParams()
  const [loading, setLoading] = useState(false)
  const [store, setStore] = useState({})
  const [products, setProducts] = useState([])
  const [productsFilter, setProductsFilter] = useState([])
  const [categories, setCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const token = localStorage.getItem('token');
  const [options, setOptions] = useState([])
  const [active, setActive] = useState("")
  const storeData = useSelector(state => state.theStore)
  const { language } = useSelector(state => state)


  const observer = new IntersectionObserver((entries) => {
    // console.log(entries);
  })

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setMapAddress, mapAddress } = useContext(appContext)
  const [englishBranchName, setEnglishBranchName] = useState(localStorage.getItem('branchName') ? localStorage.getItem('branchName') : '')
  const [arabicBranchName, setArabicBranchName] = useState(localStorage.getItem('branchNameArabic') ? localStorage.getItem('branchNameArabic') : '')
  const [branchImageURL, setBranchImageURL] = useState(localStorage.getItem("branchImage"))

  useEffect(() => {
    return () => observer.disconnect();
  }, [])

  const handleScroll = (e) => {
    // console.log(e);
  }

  const handleItemClick = (itemId) => {
    const element = document.getElementById(itemId.toString());
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleActiveCategory = (catId) => {
    const element = document.getElementById(catId.toString());
    if (element) {

      element.scrollIntoView({ behavior: "smooth", block: 'start' });
    }
    setActive(catId)
  }

  useEffect(() => {
    const inputEle = document.querySelector(".active")
    var time;
    if (inputEle) {
      clearTimeout(time)
      time = setTimeout(() => {

        inputEle.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
      }, 700);
    }
  }, [active])

  useEffect(() => {
    if (activeCategory.length > 0) {
      setLoading(true)
      setProductsFilter(products.filter(products => activeCategory.includes(products.category)))
      setLoading(false)

    } else {
      setProductsFilter(products)
    }
  }, [activeCategory])

  const getAllProducts = (id) => {
    setLoading(true)
    if (id) {
      allProductsApi(id).then(res => {
        setProducts(res?.data?.data?.filter(p => p.isDeleted === false))
        setProductsFilter(res?.data?.data?.filter(p => p.isDeleted === false))
        setLoading(false)
      }).catch(err => {
        console.log(err);
        setLoading(true)
      })
    }
  }
  //findBranchApi
  const getBranchProducts = (id) => {
    setLoading(true)
    if (id) {
      getbranchProductsApi(id).then(res => {
        setProducts(res?.data?.data?.filter(p => p.isDeleted === false))
        setProductsFilter(res?.data?.data?.filter(p => p.isDeleted === false))
        setLoading(false)
      }).catch(err => {
        console.log(err);
        setLoading(true)
      })
    }
  }

  const getAllCategories = (id) => {
    setLoading(true)
    if (id) {
      allCategoriesApi(id).then(res => {
        setCategories(res?.data?.data?.filter(data => data.isDeleted == false))
        setLoading(false)
      }).catch(err => {
        console.log(err);
        setLoading(true)
      })
    }
  }
  const getOneStore = async () => {
    allStoresApi().then(res => {
      let data = res?.data?.data?.filter(data => data.name?.replace(/\s+/g, '-') == storeName)[0]
      //  getAllProducts(data?._id)
      getAllCategories(data?._id)
      setStore(data)
      if (localStorage.getItem("branchImage")) {
        setBranchImageURL(localStorage.getItem("branchImage"))
      }
      else {
        setBranchImageURL(data?.logo)
      }
    })
  }
  const getAllAddresses = () => {
    allAddressesApi().then((res) => {
      let allAddress = []
      res?.data?.data?.map(address => {
        let oneAddress = { label: address?.city + " , " + address?.area + " , " + address?.street + " , " + address?.apartment + " , " + address?.buildingNo + " , " + address?.floor, value: address?._id }
        allAddress.push(oneAddress)
        // setOptions(prev => [...prev,oneAddress])
      })
      setOptions(allAddress)
    }).catch(err => console.log(err))
  }

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  }


  useEffect(() => {
    if (searchValue?.length > 0) {
      setProductsFilter(products?.filter(data => language == "en" ? data.englishName.toLowerCase()?.includes(searchValue.toLowerCase()) : data.arabicName.toLowerCase()?.includes(searchValue.toLowerCase())))
    } else {
      setProductsFilter(products)
    }
  }, [searchValue])


  useEffect(() => {
    setLoading(true)
    if (token) {
      getAllAddresses()
    }
    getOneStore()
    getBranchProducts(localStorage.getItem('branchId'))
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  }, [])


  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
    const latitude = localStorage.getItem('branch_Lat')
    const longitude = localStorage.getItem('branch_Lng')
    setMapAddress({ lat: latitude ? parseFloat(latitude) : 18.52043, lng: longitude ? parseFloat(longitude) : 73.856743 })
    // if (store) {
    //   setMapAddress({ lat: store?.addresses?.length > 0 ? store?.addresses[0].lat : 18.52043, lng: store?.addresses?.length > 0 ? store?.addresses[0].long : 73.856743 });
    // }
  };


  return (
    <>
      <Modal open={isModalOpen} cancelText={language == "en" ? "cancel" : "الغاء"} okText={language == "en" ? "ok" : "نعم"} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ width: "100%", height: "300px", margin: "20px 0" }}>
          <Map store={store} />
        </div>
      </Modal>
      <div style={{ width: "100%", backgroundColor: "#F5F5F5", minHeight: "100vh" }}>
        <div
          style={{
            width: "90%",
            height: "100%",
            margin: "auto",
            padding: "1em 0em 2em",
            boxSizing: "border-box",
          }}
        >
          <button
            style={{
              width: "45px",
              height: "45px",
              padding: "0.25em",
              border: "none",
              outline: "none",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "0 0 4px lightgray",
              color: "rgba(72, 160, 219, 1)",
              marginBottom: "1em"
            }}
            onClick={() => navigate(`/${storeName}/branch`)}
          >
            <FaChevronLeft />
          </button>
          <Header toggleOrderType={false} />
          <div
            style={{
              width: "100%",
              height: "250px",
              margin: "1em auto 0em",
              backgroundColor: "white",
              backgroundImage: `url(${Url + branchImageURL})`,
              backgroundSize: "cover",
              objectFit: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "15px",
              position: "relative",
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            <div className="overlay"></div>
            <div
              style={{
                position: "absolute",
                width: "90%",
                left: "5%",
                display: "flex",
                bottom: "5%",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p
                  style={{
                    color: "white",
                    fontSize: "24px",
                    fontWeight: "700",
                  }}
                >
                  {/* {language == "en" ? store?.name : store?.nameArabic} */}
                </p>
                {/* <p style={{ color: "white", width: "auto" }}>{language == "en" ? store?.categories?.englishName : store?.categories?.arabicName}</p> */}
              </div>
              <div
                style={{ width: "25%", display: "flex", justifyContent: "end" }}
              >
                {store?.whatsappNumber &&
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      position: "relative",
                    }}
                  >
                    <a
                      href={`https://wa.me/${store?.whatsappNumber}`}
                      target="_blank"  // Open link in a new tab/window
                      rel="noopener noreferrer" // Recommended for security reasons
                    >
                      <RiWhatsappFill
                        style={{
                          width: "70%",
                          height: "70%",
                          position: "absolute",
                          transform: "translate(-50%, -50%)",
                          top: "50%",
                          bottom: "50%",
                          left: "50%",
                          color: "rgba(116, 201, 106, 1)"
                          // margin: "1em auto 0em",
                        }}
                      />
                    </a>
                  </div>
                }
              </div>
            </div>
          </div>

          {/* Commited by Tahseem */}
          {/* <div style={{ width: "90%", display: "flex", justifyContent: "space-between", margin: "1em auto" }} dir={language == "en" ? "ltr" : "rtl"}>
            {store?.deliveryTime ?
              <p style={{ display: "flex", alignItems: "center", fontSize: "14px", gap: 3 }}><CiClock2 style={{ marginRight: "0.25em" }} />{store?.deliveryTime}</p> : null}
            <BsInfoCircle style={{ fontSize: "16px", color: "black", cursor: "pointer" }} onClick={() => navigate(`/more-info/${storeName}`)} />
          </div> */}

          <div
            style={{
              width: "100%",
              marginTop: "1em",
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                // alignItems: "center",
                width: "100%", fontWeight: "700", fontSize: "0.9em", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
              }}
              dir={language == "en" ? "ltr" : "rtl"}
            >
              <React.Fragment>
                <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "24px", fontWeight: "700" }}>
                  {loading ? <Skeleton.Button active shape="round" style={{ height: 15 }} /> : language == "en" ? store?.name : store?.nameArabic ? store?.nameArabic : store?.name}
                </p>
                <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "20px", color: "gray" }}>
                  {loading ? <Skeleton.Button active shape="round" style={{ height: 15 }} /> : language == "en" ? englishBranchName : arabicBranchName ? arabicBranchName : englishBranchName}
                </p>
                <p style={{ fontWeight: "500" }}>{loading ? <Skeleton.Button active block size="large" shape="round" style={{ height: 15, width: 200 }} /> : language == "en" ? store?.categories?.englishName : store?.categories?.arabicName}</p>
              </React.Fragment>

            </div>
            <div style={{ width: "90%", backgroundColor: "white", borderRadius: "10px", padding: ".7em 1em", margin: "1em 0em", boxShadow: "0 0 4px lightgray", display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "30%", padding: "0.2em" }}>
                <p style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px", gap: 5 }}>{loading ? <Skeleton.Button active shape="round" style={{ height: 10, marginTop: 8 }} /> : <><LuClock3 style={{ marginRight: "0.25em", color: "rgba(72, 160, 219, 1)", fontSize: "16px" }} />{store?.deliveryTime ? store?.deliveryTime : 45 + " " + "Mins"}</>}</p>
              </div>
              <div style={{ width: "30%", borderLeft: "1px solid", borderRight: "1px solid", padding: "0.2em" }}>
                <p style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px", gap: 5 }}><BsCashStack style={{ marginRight: "0.25em", color: "rgba(72, 160, 219, 1)", fontSize: "16px" }} />{language == "en" ? "Cash/Card" : "كاش/فيزا"}</p>
              </div>
              <div style={{ width: "30%", padding: "0.2em" }}>
                <p style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px", color: "rgba(72, 160, 219, 1)" }}><MdLocationOn style={{ marginRight: "0.25em", color: "rgba(72, 160, 219, 1)", fontSize: "16px" }} /> <button style={{ border: "none", borderBottom: "1px solid rgba(72, 160, 219, 1)", fontSize: "14px", cursor: "pointer", fontSize: "14px", color: "rgba(72, 160, 219, 1)", backgroundColor: "white" }} onClick={() => showModal()}>{language == "en" ? "Location" : "الموقع"}</button></p>
              </div>
            </div>
          </div>

          <div style={{ position: "sticky", top: "0", background: "#f5f5f5", width: "100%", paddingRight: "12px", paddingTop: "15px" }} >
            <div
              style={{ display: "flex", position: "relative", marginTop: "1.5em auto" }}
              dir={language == "en" ? "ltr" : "rtl"}
            >
              <BiSearchAlt2
                style={{
                  position: "absolute",
                  top: "25%",
                  left: language == "en" ? "10px" : "auto",
                  right: language == "en" ? "auto" : "10px",
                  // fontSize: "20px",
                  width: "20px",
                  height: "50%"
                }}
              />
              <input
                type="text"
                value={searchValue}
                onChange={(e) => handleSearch(e)}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  padding: "1.2em 1em",
                  textIndent: "25px",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0 0 4px lightgray",
                }}
                placeholder={language == "en" ? "Search For Products" : "قم بالبحث عن المنتجات"}
              />
            </div>
            <div
              className="btns-div"
              style={{
                width: "100%",
                margin: "0em auto",
                boxSizing: "border-box",
                padding: "0em 1em",
                border: "none",
                outline: "none",
                display: "flex",
                borderRadius: "10px",
                overflow: "auto",
                whiteSpace: "nowrap",
                scrollbarWidth: "none",
                scrollbarColor: "transparent transparent",
                msOverflowStyle: "none",
              }}
              dir={language == "en" ? "ltr" : "rtl"}
            >
              {loading ?
                [1, 2, 3, 4, 5].map((el, i) => (
                  <React.Fragment key={i + 1}>
                    <Space >
                      <Skeleton.Button style={{ margin: "1em 0.5em" }} active={true} size={"large"} shape={"round"} block={false} />
                    </Space>
                  </React.Fragment>
                ))
                :
                categories?.map((cat, i) => (
                  <React.Fragment key={i + 1}>
                    <button
                      onClick={() => handleActiveCategory(cat._id.toString())}
                      className={active == cat._id.toString() ? "active" : ""}
                      style={{
                        border: "1px solid #48A0DB",
                        borderRadius: "50px",
                        margin: "1em 0.5em",
                        padding: ".6em 2em",
                        display: "flex",
                        alignItems: "center",
                        zIndex: "99",
                        outline: "none",
                        cursor: "pointer",
                        position: "relative",
                        backgroundColor: active == cat._id.toString() ? " #48A0DB" : "#f5f5f5",
                        //  background:active==cat._id.toString()&&" #48A0DB",
                        color: active == cat._id.toString() ? " #fff" : "#000",
                      }}
                    >
                      {/* <img width={20} height={20} style={{borderRadius:"15px",marginRight:"10px",marginLeft:"-10px",boxShadow:"0 0 4px"}} src={Url + cat?.image} alt="img" /> */}
                      {language == "en" ? cat?.englishName : cat?.arabicName}
                      {activeCategory.includes(cat?._id) &&
                        <CloseOutlined style={{ right: 7, color: "#fff", position: "absolute", lineHeight: "0" }} />
                      }
                    </button>
                  </React.Fragment>
                ))
              }
            </div>
          </div>

          <div style={{ marginTop: "20px" }} dir={language == "en" ? "ltr" : "rtl"}>
            {loading ? <>
              {[1, 2, 3, 4, 5].map((i, index) => (
                <div
                  key={index + 1}
                  style={{
                    borderRadius: "15px",
                    width: "95%",
                    justifyContent: "space-between",
                    margin: "20px auto",
                    background: "#fff",
                    padding: "10px",
                    display: "grid",
                    gridTemplateColumns: " 1fr 3fr",
                    alignItems: "center",
                  }}
                >
                  <Space>
                    <Space>
                      <Skeleton.Image active={true} size={"large"} shape={"round"} block={false} />
                    </Space>
                    <Space direction="vertical">
                      <Skeleton.Button active={true} size={"small"} shape={"round"} block={true} />
                      <Skeleton.Avatar active={true} size={"small"} shape={"round"} block={false} />
                      <Skeleton.Input active={true} size={"small"} shape={"round"} block={true} />
                    </Space>
                  </Space>
                </div>
              ))}
            </> :
              productsFilter.length > 0 && !loading ?
                categories && categories.length > 0 && categories.map(category => (
                  <div key={category._id} id={category._id.toString()} ref={anchorRef} style={{ scrollMarginTop: 140, marginTop: 10 }} onScroll={handleScroll}>
                    {productsFilter.some(data => data.category === category._id) &&
                      <h2 style={{ fontWeight: "700", fontSize: "18px" }}>{language == "en" ? category.englishName?.charAt(0).toUpperCase() + category.englishName?.slice(1) : category?.arabicName}</h2>
                    }
                    {productsFilter?.filter(data => data.category == category._id).map((product, index) => (
                      <div
                        onClick={() => navigate(`/${storeName}/${product?.englishName?.replace(/\s+/g, '-')}`)}
                        key={index + 1}
                        style={{
                          borderRadius: "15px",
                          width: "95%",
                          justifyContent: "space-between",
                          margin: "20px auto",
                          background: "#fff",
                          padding: "10px",
                          display: "grid",
                          cursor: "pointer",
                          gridTemplateColumns: " 1fr 3fr",
                          alignItems: "center",
                          gap: "5px"
                        }}
                      >
                        <img src={Url + product?.productImage} width={78} height={76} style={{ borderRadius: "12px", marginRight: 10 }} alt="image" />
                        <div style={{ display: "grid" }}>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center", fontWeight: "700", fontSize: "0.9em", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
                            }}
                          >
                            <h4 style={{ width: "100%", fontWeight: "700", fontSize: "0.9em", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                              {language == "en" ? product?.englishName : product?.arabicName}
                            </h4>
                            <button
                              style={{
                                color: "#48A0DB",
                                margin: "8px",
                                padding: "0.25em 0.5em",
                                boxShadow: "0 0 4px rgb(233 227 227)",
                                backgroundColor: "white",
                                border: "none",
                                borderRadius: "5px",
                                fontSize: "14px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              +
                            </button>
                          </span>
                          <p style={{ fontSize: "10px", fontWeight: "300" }}>{language == "en" ? product?.englishDescription : product?.arabicDescription}</p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <span
                              style={{
                                color: "#48A0DB",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              {language == "en" ? <React.Fragment>
                                <span>{language == "en" ? "SAR" : "س.ر"}</span>
                                <span style={{ margin: "auto 5px" }}>{product?.salePrice}</span>
                              </React.Fragment> :
                                <React.Fragment>
                                  <span style={{ margin: "auto 5px" }}>{product?.salePrice}</span>
                                  <span>{language == "en" ? "SAR" : "س.ر"}</span>
                                </React.Fragment>
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
                : !loading && searchValue.length > 0 && <><Empty style={{ marginTop: 100 }} image={Empty.PRESENTED_IMAGE_SIMPLE} /></>}
          </div>
        </div>
      </div>
    </>
  );
};
