import logo from './logo.svg';
import { Route,Routes } from 'react-router-dom';

import './App.css';
import { Login } from './Pages/Login/Login';
import { Restaurants } from './Pages/Restaurants/Restaurants';
import { Register } from './Pages/Register/Register';
import { AccountDetails } from './Pages/AccountDetails/AccountDetails';
import { Item } from './Pages/Item/Item';
import { ItemCustom } from './Pages/ItemCustom/ItemCustom';
import { OrderHistory } from './Pages/OrderHistory/OrderHistory';
import { MyCart } from './Pages/MyCart/myCart';
import { Landing } from './Pages/Landing/Landing';
import { Store } from './Pages/Store/Store';
import { MoreInfo } from './Pages/MoreInfo/MoreInfo';
import { AppContextProvider } from './Context/appContext';
import { CheckOut } from './Pages/Checkout/Checkout';
import { MyOrder } from './Pages/MyOrder/myOrder';
import { NewAddress } from './Pages/NewAddress/NewAddress';
import { PicksOfTheWeek } from './Pages/PicksOfTheWeek/picksOfTheWeek';
import { useEffect, useState } from 'react';
import PayFail from './Pages/Form/payFail';
import PaySuccess from './Pages/Form/paySuccess';
import { useSelector } from 'react-redux';
import { StoreBranches } from './Pages/StoreBranches';
import { NewCheckOut } from './Pages/NewCheckout/NewCheckout';

function App() {
  const [showScroller,setShowScroller]=useState(false)
  const {language}=useSelector(state=>state)
  const root = document.documentElement;
  root.style.setProperty("--fontOfLanguage", language=="en" ? "'Inter', sans-serif" :"'Readex Pro', sans-serif")
  // useEffect(() => {
  //   window.addEventListener("scroll" , ()=>{
  //     if(window.scrollY > 100) {
  //         setShowScroller(true)
  //     }else {
  //         setShowScroller(false)
  //     }
  //   })
  //   return () => {
  //     window.scrollTo({ top: 0, behavior: 'smooth' });
  // };

  // }, [])

  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  }, [])
  return (
    <>
    <div
    className='mainDiv'
    // style={{
      // position: "absolute",
    // height: "auto",
    // minHeight: "100%",
    // width: "40%",
    
    // }}
    >
      <Routes>
        <Route path='/' element={<AppContextProvider><Landing /></AppContextProvider>}/>
        <Route path='/order-history' element={<AppContextProvider><OrderHistory /></AppContextProvider>}/>
        <Route path='/login' element={<Login />}/>
        <Route path='/register' element={<Register />}/>
        <Route path='/account-details' element={<AccountDetails />}/>
        <Route path='/:storeName' element={<AppContextProvider><Store /></AppContextProvider>}/>
        <Route path='/:storeName/branch' element={<AppContextProvider><StoreBranches /></AppContextProvider>}/>
        <Route path='/:storeName/:itemName' element={<AppContextProvider><Item /></AppContextProvider>}/>
        <Route path='/:storeName/my-cart' element={<AppContextProvider><MyCart /></AppContextProvider>}/>
        <Route path='/my-cart' element={<AppContextProvider><MyCart /></AppContextProvider>}/>
        <Route path='/:storeName/my-order' element={<AppContextProvider><MyOrder /></AppContextProvider>}/>
        <Route path='/item-custom' element={<ItemCustom />}/>
        <Route path='/restaurants' element={<AppContextProvider><Restaurants /></AppContextProvider>}/>
        <Route path='/picks-of-the-weeks' element={<AppContextProvider><PicksOfTheWeek/></AppContextProvider>}/>
        <Route path='/more-info/:storeName' element={<AppContextProvider><MoreInfo /></AppContextProvider>}/>

        <Route path='/:storeName/checkout' element={<AppContextProvider><NewCheckOut/></AppContextProvider>}/>
        {/* <Route path='/:storeName/new-checkout' element={<AppContextProvider><NewCheckOut/></AppContextProvider>}/> */}

        <Route path='/new-address' element={<AppContextProvider><NewAddress /></AppContextProvider>}/>
        <Route path='/:storeName/pay-fail' element={<AppContextProvider><PayFail /></AppContextProvider>}/>
        <Route path='/:storeName/pay-success' element={<AppContextProvider><PaySuccess /></AppContextProvider>}/>
        <Route path='/new-address/:addressId' element={<AppContextProvider><NewAddress /></AppContextProvider>}/>
      </Routes>

    </div>
    </>
  );
}

export default App;
