import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createOrderApi } from "../../Apis";
import { message } from "antd";
import { appContext } from "../../Context/appContext";
import './pay.css'
import { useSelector } from "react-redux";


function PaySuccess() {
  const { getCartData } = useContext(appContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [faile, setFaile] = useState(false);
  const [paymentId, setpaymentId] = useState("")
  const { language } = useSelector((state) => state);
  const orderObj = JSON.parse(localStorage.getItem("orderObj"));
  localStorage.removeItem("selectedPay");
  localStorage.removeItem("payMethod");
  localStorage.removeItem("orderType");
  localStorage.removeItem("type");


  const crateOrder = async () => {
    let payload = { ...orderObj }
    if (paymentId) {
      Object.assign(payload, { payment_id: paymentId })
    }
    setLoading(true);
    await createOrderApi(payload)
      .then(async (res) => {
        console.log(res);
        await getCartData(orderObj?.store);
        console.log("after get cart data");
        message.config({ maxCount: 1 });
        message.success(res.data.messageEn);
        setLoading(false);
        setSuccess(true);
        setFaile(false);
      })
      .catch((err) => {
        console.log(err.response.data.messageEn);
        message.error(err.response.data.messageEn);
        setLoading(false);
        setSuccess(false);
        setFaile(true);
      });
  };

  useEffect(() => {
    if (paymentId) {
      crateOrder();
    }
  }, [paymentId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paymentId = searchParams.get('paymentId');
    const id = searchParams.get('Id');
    setpaymentId(paymentId)
  }, [location.search]);

  return (
    <>
      <div style={{
        width: "100%", backgroundColor: "#fff",
        position: "absolute",
        minHeight: "100%",
      }}>
        <div
          style={{
            margin: "150px auto",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 10,
            justifyContent: "center",
            justifySelf: "center",
            borderRadius: "15px",
            boxShadow: "0 0 6px gray",
            padding: "1em",
            width: "90%",
            backgroundColor: "#F5F5F5",
          }}
        >
          {loading && (
            <>
              <img
                src="/loading.gif"
                style={{ width: "100px", height: "100px", margin: "auto" }}
              />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "gray",
                  marginTop: "2em",
                  margin: "1em  auto",
                }}
              >
                {language == "en" ? " Please Waite ..." : "برجاء الانتظار"}
              </p>
            </>
          )}
          {success && (
            <>
              <img
                src="/success.gif"
                style={{ width: "100px", height: "100px", margin: "auto" }}
              />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "black",
                  marginTop: "2em",
                  margin: "1em  auto",
                }}
              >
                {language == "en" ? " Thank you for your order!" : "شكراّّ لطلبك"}
              </p>
              <button
                style={{
                  padding: "0.7em",
                  marginTop: "2.5em",

                  borderRadius: "10px",
                  cursor: "pointer",
                  zIndex: "20000",
                  margin: "1em  auto",
                  width: "80%"
                }}
                className="goStoreBtn"
                onClick={() => navigate(`/order-history`)}
              >
                {language == "en" ? "Back To Orders" : "الرجوع للطلبات"}
              </button>
            </>
          )}

          {faile && (
            <>
              <img
                src="/error.gif"
                style={{
                  width: "130px",
                  height: "100px",
                  margin: "auto",
                  borderRadius: "20px",
                }}
              />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "black",
                  marginTop: "2em",
                  margin: "1em auto",
                }}
              >
                {language == "en" ? "Failed To create Order!" : "فشلت في عمل الطلب"}
              </p>

              <button
                style={{
                  padding: "0.7em",
                  marginTop: "2.5em",

                  borderRadius: "10px",
                  margin: "1em auto",
                  cursor: "pointer",
                  width: "80%"
                }}
                className="gobackBtn"
                onClick={() => window.history.back()}
              >
                {language == "en" ? "go back" : "الرجوع"}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PaySuccess;
