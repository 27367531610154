import React, { createContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addToCartApi, allStoresApi, getCartDataApi, updateCartApi } from '../Apis';
import { message } from 'antd';
import { useSelector } from 'react-redux';
const lodash = require("lodash");
const appContext = createContext()


const AppContextProvider=({ children }) => {
    const navigate = useNavigate()
    const [count, setCount] = useState(0);
    const [cartItems, setCartItems] = useState("");
    const [cartId, setCartId] = useState("");
    const [storeId, setStoreId] = useState("");
    const {storeName} = useParams()
    const token = localStorage.getItem('token');
    const [cartQty, setCartQty] = React.useState(0);
    const [isLoading, setIsloading] = useState(false);
    const [quantityLoading, setQuantityLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [itemId, setItemId] = useState("");
    const [open, setOpen] = useState(false);
    const {language}= useSelector(state => state)
    const [mapAddress, setMapAddress] = useState({lat:24.7136,lng:46.6753});
    const [messageApi, contextHolder] = message.useMessage();
      const getStore = () =>{
        
        if(storeName){
          setIsloading(true)
          setQuantityLoading(true)
          allStoresApi().then((response) =>{
           let storeId = response?.data.data?.filter(store => store.name?.replace(/\s+/g, '-') == storeName)[0]?._id
           setStoreId(storeId)
           if(token){

             getCartData(storeId)
           }
          }).catch(err=>console.log(err))

        }
      }
      useEffect(() => {
     
        return () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      }, [])
      useEffect(() => {
        localStorage.setItem('historyRouter',window.location.pathname)
      }, [window.location.pathname])

      const getCartData = async (id) => {
          //  setLoadingBtn(true)
        if (token) {
          getCartDataApi(id).then((response) => {
            if (response.data) {
                setCartItems(response.data);
                // setCartId(response?.data?._id)
             setIsloading(false)
            //  setLoading(false);
            setLoadingBtn(false)
            setQuantityLoading(false)
            }
          }).catch(error => console.log(error.message))
        }
      };
      useEffect(() => {
        // setLoadingBtn(true)
      }, [])
       useEffect(() => {
        // setLoadingBtn(true)
        setLoading(false)
       }, [cartItems])
        useEffect(() => {
          setQuantityLoading(true)
          getStore()
        // if(token){
            
        // }
        }, [storeName])
        useEffect(() => {
          //console.log(cartItems?.data);
          if ( token &&cartItems && cartItems?.data?.products?.length > 0) {
            
            let total = 0;
            for (let i = 0; i < cartItems?.data?.products.length; i++) {
              total += cartItems?.data?.products[i]?.quantity;
            }
            //console.log("total", total);
            setCartQty(total);
            setQuantityLoading(false)
          } else {
            setCartQty(0);
          }
        }, [cartItems]);

        const increaseCartQuantity = async (itemId) => {
          const existingItem = cartItems?.data?.products?.find(
            (item) => item?._id === itemId
          );
          if(itemId){ 
            
            setLoading(true)
             setItemId(itemId)
          } 
          // Item already exists in cart, increment quantity by 1
          const updatedItems = cartItems?.data?.products?.map((item) => {
            if (item?._id === itemId) {
              return {
                ...item,
                quantity: item.quantity + 1,
              };
            } else {
              return item;
            }
          });

          updateCartApi(cartItems?.data?._id,{ products: updatedItems }).then((res) => {
            getCartData(storeId).then(()=>{
                // setLoading(false);

            })
          }).catch((err) => {
            console.log(err);
          })
        };

        const showDrawer = () => {
          setOpen(true);
        };
        const onClose = () => {
          setOpen(false);
        };
      
        const decreaseCartQuantity = async (itemId) => {
          const existingItem = cartItems?.data?.products?.find(
            (item) => item?._id === itemId
          );
          if(itemId){ 
            
            setLoading(true)
             setItemId(itemId)
          } 
          if (existingItem) {
            // Item exists in cart, decrement quantity by 1
            let updatedItems = cartItems?.data?.products?.map((item) => {
              if (item?._id === itemId) {
                return {
                  ...item,
                  quantity: item.quantity - 1,
                };
              } else {
                return item;
              }
            });
      
            // Remove the item if the quantity becomes 0
            updatedItems = updatedItems.filter((item) => item.quantity > 0);
      
            updateCartApi(cartItems?.data?._id,{ products: updatedItems }).then((res) => {
              getCartData(storeId).then(()=>{
                // setLoading(false);
              })
            }).catch((err) => {
              console.log(err);
            })
          }
        };
      
        const removeFromCart = (itemId) => {
          const updatedData = {
            products: cartItems?.data?.products?.filter(
              (item) => item?._id !== itemId
            ),
          };
          updateCartApi(cartItems?.data?._id, {products: updatedData?.products}).then((res) => {
            getCartData(storeId).then(()=>{
            })
          }).catch((err) => {
            console.log(err);
          })
          // axios
          //   .patch(
          //     `https://api.utrac.store/cart/${cartId}`,
          //     {
          //       products: updatedData?.products,
          //     },
          //     {
          //       headers: {
          //         Authorization: `Bearer ${token || sessionToken}`,
          //       },
          //     }
          //   )
          //   .then((res) => {
          //     getCartData(storeId)
          //   })
          //   .catch((err) => {
          //     //console.log(err);
          //   });
        };
        const getItemQuantity = (id) => {
          const product =
            cartItems &&
            cartItems?.data?.products?.find((item) => item.id === id);
            return product ? product.quantity : 0;
          };
          
      const Arr = [];
      const addToCart = async (
        itemId,
        price,
        notes,
        addons,
        quantity
      ) => {
        setLoadingBtn(true)
        const checkAddons = () => {
          // let ischeck = false;
          let Arr= [];
          const arr = cartItems?.data?.products?.filter(
            (item) => item?.product?._id == itemId
          );
        
          for (let i = 0; i < arr.length; i++) {
            // ischeck=lodash.isEqual(arr[i].addons, addons)
            Arr.push(lodash.isEqual(arr[i].addons, addons));
          }
         
          if (Arr.includes(true)) {
            console.log("this addons is already exists");
            
            return true;
          } else return false;
          //  console.log("ischeck" ,ischeck);
        };
        
        if (
          !cartItems?.data?.products?.includes(
            cartItems?.data?.products?.filter(
              (item) => item?.product?._id == itemId
            )[0]
          )
    
          
        ) {
          const newItem = [
            {
              product: itemId,
              quantity,
              price: price,
              note: notes,
              addons: addons,
            },
          ];    
          const existingItems = cartItems?.data?.products || [];
          const updatedItems = [...existingItems, ...newItem];
          const store = storeId;
          if (token && storeId !== "") {
            addToCartApi({ products: updatedItems, store: store }).then((response) =>{
                getCartData(storeId);
                // setLoadingBtn(false)
                message.config({maxCount: 1})
                message.success(language == "en"?"Added To Cart successfully":"تم الاضافة الى السلة بنجاح")

                // messageApi.open({
                //   // key:"1",
                //   type: 'success',
                //   content: 'Added To Cart successfully',
                // });
            }).catch((error) =>{
                console.log(error);
                message.error(error.message)
             })
          }
    
          // refetch cart data after updating
        } else if (
          cartItems?.data?.products?.includes(
            cartItems?.data?.products?.filter(
              (item) => item?.product?._id == itemId
            )[0]
          ) &&
          !checkAddons()
        ) {
    
          const newItem = [
            {
              product: itemId,
              quantity,
              price: price,
              note: notes,
              addons: addons,
            },
          ];
          const existingItems = cartItems?.data?.products || [];
          const updatedItems = [...existingItems, ...newItem];
    
          const store = storeId;
          
          if ( token) {
            addToCartApi({ products: updatedItems, store: store }).then((response) =>{
                getCartData(storeId);
                // setLoadingBtn(false)
                message.config({maxCount: 1})
                message.success(language == "en"?"Added To Cart successfully":"تم الاضافة الى السلة بنجاح")
            }).catch((error) =>{
                console.log(error);
             })
          }
          // refetch cart data after updating
        }
        // cartItems?.data?.products?.filter(
        //   (item) => item?.product?._id == itemId
        // ).length!=0
        // &&
        //    (JSOify(addons)==JSOify(cartItems?.data?.products?.filter(
        //     (item) => item?.product?._id == itemId
        //   )[0].addons))
        // )
        else {    
          console.log(
            "copmpare",
            lodash.isEqual(
              cartItems?.data?.products?.filter(
                (item) => item?.product?._id == itemId
              )[0].addons,
              addons
            )
          );
          const store = storeId;
          const existingItems = cartItems?.data?.products || [];
          const index = existingItems?.findIndex(
            (item) =>
              item?.product?._id === itemId && lodash.isEqual(item.addons, addons)
          );
    
          if (quantity >= 1) {
            existingItems[index].quantity += quantity;
          }
          if (notes !== "") {
            existingItems[index].note = notes;
          }
          
          addons?.map((addon) => {
            if (
              addons.length !== 0 &&
              !existingItems[index].addons.includes(addon)
            ) {
              Arr.push(addon);
            }
            console.log(Arr);
          });
    
          // if (existingItems[index].product.type === "multi") {
          //   existingItems[index].addons = [ ...Arr];
          // } else if (
          //   existingItems[index].product.type === "single" &&
          //   addons.length !== 0
          // ) {
          //   existingItems[index].addons = [...existingItems[index].addons, ...Arr];
          // }
          // console.log("existingItems", existingItems[index]);
    
          const updatedItems = [...existingItems];
          if ( token) {
             updateCartApi(cartItems?.data?._id,{ products: updatedItems, store: store }).then((response) =>{
                getCartData(storeId);
                message.config({maxCount: 1})
                message.success(language == "en"?"Added To Cart successfully":"تم الاضافة الى السلة بنجاح")
             }).catch((error) =>{
                console.log(error);
             })
          }
        }
    
      };
      const emptyCart = () => {
        const store = storeId;
        updateCartApi(cartItems?.data?._id, {products: [],store:store}).then((res) => {
          getCartData(storeId).then(()=>{
          })
        }).catch((err) => {
          console.log(err);
        })
      };
      const contextValue = {
        count,
        addToCart,
        removeFromCart,
        decreaseCartQuantity,
        increaseCartQuantity,
        getItemQuantity,
        emptyCart,
        getCartData,
        cartItems,
        cartQty,
        loading,
        isLoading,
        itemId,
        quantityLoading,
        loadingBtn,
        setMapAddress,
        mapAddress,
        setStoreId,
        showDrawer,
        onClose,
        open
      };


    return (
        <appContext.Provider value={contextValue}>
        {children}
      </appContext.Provider>
    )
}

export { appContext , AppContextProvider}
