import React, { useLayoutEffect } from "react";
import { FaArrowLeft, FaTrash, FaTrashAlt } from "react-icons/fa";
import { BsInfoCircleFill, BsTrash3 } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { App, Skeleton, Space, Spin, notification } from "antd";
import { appContext } from "../../Context/appContext";
import { useEffect } from "react";
import { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Url, allStoresApi } from "../../Apis";
import { LoadingOutlined } from "@ant-design/icons";
import EmptyCart from "../../components/emptyCart";
import moment from "moment";
import "moment/locale/en-ca";
import "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
const lodash = require("lodash");



export const MyCart = () => {
  const { language } = useSelector((state) => state);
  const [loadingStore, setLoadingStore] = useState(false);
  const [store, setStore] = useState([]);
  const navigate = useNavigate();
  const { storeName } = useParams();
  const [total, setTotal] = useState(0);
  const [addonsName, setAddonsName] = useState([]);
  const {
    cartQty,
    cartItems,
    removeFromCart,
    increaseCartQuantity,
    decreaseCartQuantity,
    loading,
    isLoading,
    itemId,
    loadingBtn,
  } = useContext(appContext);
  const [cartProducts, setCartProducts] = useState([]);
  const [load, setLoad] = useState(false);
  const [cartId, setCartId] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 10,
      }}
      spin
    />
  );

  const getOneStore = async () => {
    allStoresApi().then((res) => {
      console.log(
        res?.data?.data?.filter(
          (data) => data.name?.replace(/\s+/g, "-") == storeName
        )
      );
      let data = res?.data?.data?.filter(
        (data) => data.name?.replace(/\s+/g, "-") == storeName
      );
      // getOneProduct(data[0]?._id)

      // getAllCategories(data?._id)
      setStore(data);
      setLoadingStore(false);
    });
  };

  useEffect(() => {
    setLoadingStore(true);
    getOneStore();
  }, []);


  const handleCheckOut = () => {
    if (cartItems?.data?.products?.length > 0) {
      let currentTime = new Date().toLocaleString("en-US", {
        // timeZone: "Africa/Cairo",
        timeZone: "Asia/Riyadh",
      });
      const open = moment(
        store[0]?.workingHours[0].openingHours,
        "hh:mm A"
      ).hours();
      const close = moment(
        store[0]?.workingHours[0].closeingHours,
        "hh:mm A"
      ).hours();
      const openNotification = (placement) => {
        api.info({
          // message: `Notification ${placement}`,
          description: (
            <span style={{ color: "white" }}>
              {store && language == "en"
              ? ` We're closed - our opening hours: ${store[0]?.workingHours[0].openingHours} -> ${store[0]?.workingHours[0].closeingHours}`
              : store &&
                `${store[0]?.workingHours[0].closeingHours} <- ${store[0]?.workingHours[0].openingHours} : لقد أغلقنا - ساعات عملنا`}{" "}
            </span>
          ),
          placement,
          closeIcon: <></>,
          className: "notification",
          icon: <BsInfoCircleFill style={{ color: "white" }} />,
          style: {
            background: "#48A0DB",
            color: "#fff",
          },
        });
      };
      const currentHr = moment(currentTime.split(",")[1], "hh:mm A").subtract(
        1,
        "hour"
      );
      let openTime;
      let closeTime;
      if (
        (open >= close &&
          ((moment(store[0]?.workingHours[0].closeingHours, "hh:mm A").format(
            "A"
          ) === "AM" &&
            moment(store[0]?.workingHours[0].openingHours, "hh:mm A").format(
              "A"
            ) === "AM") ||
            (moment(store[0]?.workingHours[0].closeingHours, "hh:mm A").format(
              "A"
            ) === "PM" &&
              moment(store[0]?.workingHours[0].openingHours, "hh:mm A").format(
                "A"
              ) === "PM"))) ||
        (moment(store[0]?.workingHours[0].openingHours, "hh:mm A").format(
          "A"
        ) === "PM" &&
          moment(store[0]?.workingHours[0].closeingHours, "hh:mm A").format(
            "A"
          ) === "AM")
      ) {
        closeTime = moment(
          store[0]?.workingHours[0].openingHours,
          "hh:mm A"
        ).second(0);

        openTime = moment(
          store[0]?.workingHours[0].closeingHours,
          "hh:mm A"
        ).second(0);

        const ksaTime = moment().tz("Asia/Riyadh");

        if (ksaTime.isBetween(openTime, closeTime)) {
          openNotification(width > 500 ? "bottomLeft" : "bottom");
          return;
        }
      } else {
        openTime = moment(
          store[0]?.workingHours[0].openingHours,
          "hh:mm A"
        ).second(0);
        closeTime = moment(
          store[0]?.workingHours[0].closeingHours,
          "hh:mm A"
        ).second(0);
        const ksaTime = moment().tz("Asia/Riyadh");

        if (!ksaTime.isBetween(openTime, closeTime)) {
          openNotification(width > 500 ? "bottomLeft" : "bottom");
          return;
        }
      }

      const current = moment("11:00 PM", "hh:mm A");
      // const open = moment("10:00 AM","hh:mm A")
      // const close = moment("6:00 PM","hh:mm A")
      const now = moment();
      const start = moment().hour(6).minute(0).second(0);
      const end = moment().hour(22).minute(0).second(0);
      var startTime = store[0]?.workingHours[0].openingHours;
      var endTime = store[0]?.workingHours[0].closeingHours;

      let currentDate = new Date();

      // let startDate = new Date(currentDate.getTime());
      // startDate.setHours(startTime?.split(":")[0]);
      // startDate.setMinutes(startTime?.split(":")[1]);
      // startDate.setSeconds(startTime.split(":")[2]);

      // let endDate = new Date(currentDate.getTime());
      // endDate.setHours(endTime?.split(":")[0]);
      // endDate.setMinutes(endTime?.split(":")[1]);
      // endDate.setSeconds(endTime.split(":")[2]);

      // const ksaTime = moment().tz("Asia/Riyadh");
      // if (!ksaTime.isBetween(openTime, closeTime)) {
      //   openNotification(width>500?"bottomLeft":"bottom")
      //   return;
      // }

      navigate(`/${storeName}/checkout`);
    }
  };

  const getAddonsPrice = () => {
    let res = 0;
    for (let i = 0; i < cartItems?.data?.products?.length; i++) {
      if (cartItems?.data?.products[i]?.addonDetails) {
        for (
          let j = 0;
          j < cartItems?.data?.products[i]?.addonDetails?.length;
          j++
        ) {
          res +=
            parseFloat(cartItems?.data?.products[i]?.addonDetails[j].price) *
            parseFloat(cartItems?.data?.products[i]?.quantity);
        }
      }
    }
    return res;
  };

  const getSubTotal = () => {
    let res = 0;
    for (let i = 0; i < cartItems?.data?.products?.length; i++) {
      res +=
        parseFloat(cartItems?.data?.products[i]?.price) *
        parseFloat(cartItems?.data?.products[i]?.quantity);
    }
    return res;
  };

  function calculateAddonsTotal(products) {
    let total = 0;
    products?.forEach((product) => {
      product?.addons.forEach((addonId) => {
        const addon = product?.addonDetails?.find((a) => a._id === addonId);
        if (addon) {
          total += addon?.price * product.quantity;
        }
      });
    });
    return total;
  }

  const addonsTotal = calculateAddonsTotal(cartItems?.data?.products);
  const cartTotal = cartItems?.data?.products?.reduce((total, cartItem) => {
    return total + cartItem?.price * cartItem?.quantity;
  }, 0);

  const totalCart = cartTotal + addonsTotal;

  useLayoutEffect(() => {
    setLoad(true);
    setCartProducts(cartItems?.data?.products);
    setCartId(cartItems?.data?._id);
    setLoad(false);
  }, []);

  useEffect(() => {
    //console.log("dssfdsfds");
    //console.log(addons);
    let Arr = [];
    cartItems?.data?.products?.forEach((data) => {
      Arr.push(data?.addonDetails);
    });
    Arr.forEach((data) => {
      setAddonsName((prev) => Array.from(new Set([...prev, data?.name])));
    });
    // const newAddons = cartItems?.data?.products?.addons?.filter((add) => cartItems?.data?.products?.addonDetails?.some((add2) => add2._id === add))
    //   .map((add) =>cartItems?.data?.products?.addonDetails?.find((add2) => add2._id === add)?.name);
    //   console.log("newAddons",newAddons);
    // setAddonsName((prev) =>newAddons && Array.from(new Set([...prev,...newAddons])));
    cartItems?.data?.products?.forEach((product) => {
      const addonPrices = product?.addons
        ?.filter((add) =>
          product?.addonDetails?.some((add2) => add2._id === add)
        )
        ?.map(
          (add) =>
            product?.addonDetails?.find((add2) => add2._id === add)?.price
        );
      const total = addonPrices?.reduce((acc, price) => acc + price, 0);
      setTotal(total);
    });
  }, []);

  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);


  return (
    <>
      {contextHolder}
      <div
        style={{
          width: "100%",
          backgroundColor: "#F5F5F5",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "90%",
            // height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "grid",
              textAlign: "center",
              position: "relative",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "45px",
                height: "45px",
                padding: "0.25em",
                border: "none",
                outline: "none",
                display: "flex",
                position: "absolute",
                backgroundColor: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                boxShadow: "0 0 4px lightgray",
                color: "rgba(72, 160, 219, 1)",
              }}
              onClick={() => navigate(`/${storeName}`)}
            >
              <FaChevronLeft />
            </button>
            <h2 style={{ fontWeight: 600 }}>
              {language == "en" ? "MY CART" : "سلة التسوق"}
            </h2>
          </div>

          <div style={{ marginTop: "40px" }}>
            {isLoading || load ? (
              [1, 2, 3].map((i, index) => (
                <div
                  key={index}
                  style={{
                    borderRadius: "15px",
                    width: "95%",
                    justifyContent: "space-between",
                    margin: "20px auto",
                    background: "#fff",
                    padding: "10px",
                    display: "grid",
                    gridTemplateColumns: " 1fr 3fr",
                    alignItems: "center",
                  }}
                >
                  <Space>
                    <Space>
                      <Skeleton.Image
                        active={true}
                        size={"large"}
                        shape={"round"}
                        block={false}
                      />
                      {/* <Skeleton.Node active={true} size={"large"} shape={"round"} block={false}>
                                        <DotChartOutlined
                                          style={{
                                            fontSize: 40,
                                            color: '#bfbfbf',
                                          }}
                                        />
                                      </Skeleton.Node> */}
                    </Space>
                    <Space direction="vertical">
                      <Skeleton.Button
                        style={{ height: 10 }}
                        active={true}
                        size={"small"}
                        shape={"round"}
                        block={true}
                      />
                      <Skeleton.Avatar
                        style={{ height: 10 }}
                        active={true}
                        size={"small"}
                        shape={"round"}
                        block={false}
                      />
                      <Skeleton.Input
                        style={{ height: 10 }}
                        active={true}
                        size={"small"}
                        shape={"round"}
                        block={true}
                      />
                    </Space>
                  </Space>
                </div>
              ))
            ) : cartItems?.data?.products?.length > 0 ? (
              cartItems?.data?.products?.map((product, index) => (
                <div
                  key={product?._id}
                  style={{
                    borderRadius: "15px",
                    width: "95%",
                    justifyContent: "space-between",
                    margin: "20px auto",
                    background: "#fff",
                    padding: "10px",
                    display: "grid",
                    gridTemplateColumns: " 1fr 3fr",
                    alignItems: "center",
                    gap: 6,
                    direction: language=="en"?"ltr":"rtl"
                  }}
                >
                  <img
                    src={Url + product?.product?.productImage}
                    style={{ borderRadius: 10 }}
                    width={78}
                    height={76}
                    alt="image"
                  />
                  <div style={{ display: "grid" }}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <h4
                        style={{
                          width: "200px",
                          fontWeight: "700",
                          fontSize: "0.9em",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {language=="en"? product?.product?.englishName:product?.product?.arabicName}
                      </h4>
                      <BsTrash3
                        style={{
                          color: "#48A0DB",
                          margin: "8px 0",
                          cursor: "pointer",
                        }}
                        onClick={() => removeFromCart(product?._id)}
                      />
                    </span>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* <span style={{color:"#A4A4A4"}}>ADD</span>     */}
                      <legend
                        style={{
                          fontSize: "12px",
                          position: "relative",
                          left: "0",
                          color: "#9f9f9f",
                        }}
                      >
                        <small style={{ color: "#000", fontSize: "12px" }}>
                          {language=="en"?"addons":"إضافات"} :
                        </small>
                        {product?.addonDetails?.map(
                          (addon, i) =>
                          language=="en"?addon?.name:addon.nameArabic +
                            (i < product?.addonDetails?.length - 1 ? " , " : "")
                        )}
                      </legend>
                    </div>
                    <legend
                      style={{
                        fontSize: "12px",
                        position: "relative",
                        left: "0",
                        color: "#9f9f9f",
                      }}
                    >
                      <small style={{ color: "#000", fontSize: "12px" }}>
                        {language=="en"?"notes":"ملاحظات"} :
                      </small>
                      {product?.note}
                    </legend>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <span
                        style={{
                          color: "#48A0DB",
                          fontSize: "18px",
                          fontWeight: "500",
                          direction: language=="en"?"rtl":"ltr"
                        }}
                      >
                        <p style={{display:"inline-block"}}>{language=="en"?"SAR":"س.ر"}</p>
                        <p style={{ margin: "auto 5px",display:"inline-block" }}>
                          {product?.product?.salePrice * product?.quantity +
                            product?.addons
                              ?.filter((add) =>
                                product?.addonDetails?.some(
                                  (add2) => add2._id === add
                                )
                              )
                              ?.map(
                                (add) =>
                                  product?.addonDetails?.find(
                                    (add2) => add2._id === add
                                  )?.price
                              )
                              ?.reduce((acc, price) => acc + price, 0) *
                              product?.quantity}
                        </p>
                      </span>
                      <div
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #48A0DB",
                          padding: " 3px 10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{ fontSize: "15px", cursor: "pointer" }}
                          onClick={() => decreaseCartQuantity(product?._id)}
                        >
                          {" "}
                          -{" "}
                        </span>
                        <p style={{ margin: "auto 7px" }}>
                          {loading && itemId == product?._id ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            product?.quantity
                          )}
                        </p>
                        <span
                          style={{ fontSize: "15px", cursor: "pointer" }}
                          onClick={() => increaseCartQuantity(product?._id)}
                        >
                          {" "}
                          +{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <span style={{ display:"flex" ,flexDirection:"column" , justifyContent:"space-between"}}>
                  </span> */}
                </div>
              ))
            ) : (
              <EmptyCart />
            )}
          </div>
          {/* {cartItems?.data?.products?.length>0&&<div style={{ width: "100%", margin: "50px auto 20px",display:"flex",justifyContent:"space-between",alignItems:"center" }}>
                  <input style={{borderRadius:"12px" , width:"100%" , padding:" 15px 15px" , background:"white",border:"none"}} type="text"  placeholder="Add Promo Code"/>
                  <button style={{
                      width: "55%",
                      // marginTop: "2em",
                      marginLeft:"20px",
                      padding: "1em 1em",
                      border: "none",
                      outline: "none",
                      display: "flex",
                      backgroundColor: "#F6A94A",
                      color: "white",
                      borderRadius: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0 0 4px lightgray",
                    }}>Apply</button>
                </div>} */}
        </div>

        {cartItems?.data?.products?.length > 0 && storeName && (
          <div
            style={{
              background: "white",
              width: "auto",
              padding: "10px 20px",
            }}
          >
            {/* <span style={{display:"flex", justifyContent:"space-between",margin:"20px auto"}}>
              <span style={{fontSize:"18px"}}>Subtotal</span>
              <span style={{fontWeight:"bold"}}>SAR {getSubTotal() + getAddonsPrice()} </span>
             </span> */}
            {/* <hr style={{border:"1px solid #F1F2F3",borderColor:"#F1F2F3"}} />
             <span style={{display:"flex", justifyContent:"space-between",margin:"20px auto"}}>
              <span style={{fontSize:"18px"}}>Tax and Fees</span>
              <span style={{fontWeight:"bold"}}>SAR 20.00</span>
             </span>
             <hr style={{border:"1px solid #F1F2F3",borderColor:"#F1F2F3"}} />
             <span style={{display:"flex", justifyContent:"space-between",margin:"20px auto"}}>
              <span style={{fontSize:"18px"}}>Delivery</span>
              <span style={{fontWeight:"bold"}}>SAR 25.00</span>
             </span> */}
            {/* <hr style={{border:"1px solid #F1F2F3",borderColor:"#F1F2F3"}} /> */}
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px auto",
              }}
              dir={language=="en"?"ltr":"rtl"}
            >
              <span
                style={{ color: "#C4C4C4" }}
              >{ language=="en"?`(${cartItems?.data?.products?.length} items)`:`(${cartItems?.data?.products?.length} عنصر)`}</span>
              <span style={{ fontSize: "20px", fontWeight: "bold",direction: language=="en"?"rtl":"ltr" }}>
                <span style={{display:"inline-block"}}>{language=="en"?"SAR":"س.ر"}</span> <span style={{display:"inline-block"}}>{totalCart}</span>
              </span>
            </span>
            {/* <hr style={{border:"1px solid #F1F2F3",borderColor:"#F1F2F3"}} /> */}
          </div>
        )}
        <br />
        <br />
        <br />
        <br />
        <br />
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "2em 1em",
            boxSizing: "border-box",
            bottom: 0,
            position: "absolute",
          }}
        >
          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {cartItems?.data?.products?.length > 0 && storeName ? (
              <>
                <button
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    padding: "10px",
                    border: "1px solid #48A0DB",
                    fontSize: "16px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    color: "black",
                  }}
                  onClick={() => storeName && navigate(`/${storeName}`)}
                >
                  {language=="en"?"Back To Menu":"الرجوع للقائمة السابقة"}
                </button>
                <button
                  style={{
                    width: "55%",
                    marginLeft: "10px",
                    padding: "11px",
                    border: "none",
                    outline: "none",
                    display: "flex",
                    backgroundColor: "#F6A94A",
                    color: "white",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    boxShadow: "0 0 4px lightgray",
                    fontSize: "16px",
                  }}
                  onClick={() => handleCheckOut()}
                >
                 {language=="en"?"Checkout":"الدفع"}
                </button>
              </>
            ) : (
              <>
                <button
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                    padding: "1em 1em",
                    border: "none",
                    outline: "none",
                    display: "flex",
                    backgroundColor: "#F6A94A",
                    color: "white",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    boxShadow: "0 0 4px lightgray",
                  }}
                  onClick={() => navigate(`/`)}
                >
                 {language=="en"?"Order Now":"اطلب الآن"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
