import React, { useEffect, useState, useMemo, useContext } from "react";
import "./NewAddress.css";
import { FaChevronLeft } from "react-icons/fa";
import { LuClock3 } from "react-icons/lu";
import { MdLocationOn } from "react-icons/md";
import { BsCashStack } from "react-icons/bs";
import Map from "../../components/Map";
import { useNavigate, useParams } from "react-router-dom";

import {
  Url,
  allAddressesApi,
  allCategoriesApi,
  allProductsApi,
  allStoresApi,
  addAddressApi,
  setDefaultAddressApi,
  getOrderAreasApi,
  updateAddressApi,
} from "../../Apis";
import { Skeleton, Space, Spin, message } from "antd";
import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { appContext } from "../../Context/appContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { LoadingOutlined } from "@ant-design/icons";
const lodash = require("lodash");
export const NewAddress = () => {
  const {language}= useSelector(state => state)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [store, setStore] = useState({});
  const [location, setLocation] = useState({});
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const token = localStorage.getItem("token");
  const { storeName, itemName, addressId } = useParams();
  const [options, setOptions] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState("");
  const [addressData, setAddresData] = useState({});
  const [error, setError] = useState(false);
  const [city, setCity] = useState("");
  const antIconPlace = (
    <LoadingOutlined
      style={{
        fontSize: 22,
        color: "#fff",
      }}
      spin
    />
  );

  const [Areas, setAreas] = useState([]);
  // const params = useParams();
  const getAllAddresses = async () => {
    let response = await allAddressesApi();
    let res = await response.data?.data;
    const address = res?.filter((add) => add._id == addressId)[0];
    setAddresData(address);
    console.log("adresses", res);
    console.log("adresse", address);
    setValue("nickName", address?.nickName);
    // setValue("city", address?.city);
    setCity(address?.city);
    setArea(address?.area);
    setValue("floor", address?.floor);
    setValue("apartment", address?.apartment);
    setValue("street", address?.street);
    setLat(address?.areaCo?.lat);
    setLong(address?.areaCo?.long);
    setValue("district", address?.district);
    setValue("buildingNo", address?.buildingNo);
    setValue("phoneNumber", "");
    setMapAddress({
      lat: parseFloat(address?.areaCo?.lat),
      lng: parseFloat(address?.areaCo?.long),
    });
  };
  useEffect(() => {
    console.log("addressId", addressId);
    if (addressId) {
      getAllAddresses();
    }
    else{
      getLocation()
    }
  }, []);
  //address obj values
  // const [nickName, setNickName] = useState("");

  // const [floor, setFloor] = useState("");
  // const [apartment, setApartment] = useState("");
  // const [country, setCountry] = useState("");
  // const [district, setDistrict] = useState("");
  const [area, setArea] = useState("");
  // const [street, setStreet] = useState("");
  // const [buildingNo, setBuildingNo] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const { setMapAddress, mapAddress } = useContext(appContext);
  const storeData = useSelector((state) => state.theStore);
  const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);
  const GoogleMap = require("react-google-maps").GoogleMap;
  const InfoWindow = require("react-google-maps").InfoWindow;
  const Marker = require("react-google-maps").Marker;
  const {
    InfoBox,
  } = require("react-google-maps/lib/components/addons/InfoBox");
  const schema = yup.object().shape({
    // area: yup.string().required("Area is required!"),
    // buildingNo: yup
    //   .number("buildingNo must be a `number`")
    //   .required("building No is required!"),
    // city: yup.string().required("Town/city is required!"),
    // country: yup.string().required("state/country is required!"),
    // floor: yup.number("floor must be a `number`").required("Floor is required"),
    // apartment: yup
    //   .number("apartment must be a `number`")
    //   .required("Apartment is required"),
    // district: yup.string().min(4).required("district is required!"),
    // street: yup.string(),
    // area:yup.string(),
    // phoneNumber: yup
    //   .number("phoneNumber must be a `number`")
    //   .required("mobile number is required!"),
    // nickName: yup.string(),
    // lat:yup.string().required("latitude is required!"),
    // long:yup.string().required("longitude is required!"),
  });

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit: handleSubmit3,
    control: control1,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // useEffect(() => {
  //   console.log("storeData", storeData);
  //   getOneStore();
  //   // if(token){

  //   // }
  //   setValue("townCity", "");
  //   setValue("floor", "");
  //   setValue("apartment", "");
  //   setValue("stateCountry", "");
  //   setValue("area", "");
  //   setValue("streetAddress", "");
  //   setValue("building", "");
  //   setValue("district", "");
  //   setValue("lat", "");
  //   setValue("long", "");
  // }, []);

  const getAreas = () => {
    setLoading(true);
    getOrderAreasApi().then((res) => {
      console.log(res.data.data);
      setAreas(res.data.data);
      setLoading(false);
    });
  };
  const handleChangeArea = (e) => {
    console.log(e.target.value);
    if (e.target.value == undefined) {
      setError(true);
    } else if (e.target.value) {
      setError(false);
      let location = Areas.filter((data) => data.name === e.target.value)[0]
        ?.location;
      console.log(location);
      setLat(location[0]);
      setLong(location[1]);
      setArea(e.target.value);
    }
  };
  useEffect(() => {
    console.log(mapAddress);
    setLat(mapAddress?.lat);
    setLong(mapAddress?.lng);
  }, [mapAddress]);

// useEffect(()=>{
//   if(!addressId){
// getLocation()
//   }
// },[addressId])

  useEffect(() => {
    // getAreas();
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, []);
  const getOneStore = async () => {
    allStoresApi().then((res) => {
      console.log(
        res?.data?.data?.filter(
          (data) => data.name?.replace(/\s+/g, "-") == storeName
        )
      );
      let data = res?.data?.data?.filter(
        (data) => data.name?.replace(/\s+/g, "-") == storeName
      )[0];
      console.log("storeData", data);
      //   getAllProducts(data?._id)
      //   getAllCategories(data?._id)
      setStore(data);
    });
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setDefaultAddress(e.target.checked);
  };
  function getLocation() {
    console.log("location");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    console.log("position",position);
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    console.log({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
    setLocation({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
    setMapAddress({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
    // document.getElementById("demo").innerHTML = "Latitude: " + latitude + "<br>Longitude: " + longitude;
  }

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
    }
  }

  console.log("errors", errors);
  //addadress Func
  useEffect(() => {
    console.log(lodash.isEmpty(errors));
    if (area == "" && !lodash.isEmpty(errors)) {
      console.log(area);

      setError(true);
      // message.error("Please select Your Town/city")
    }
  }, [errors]);
  const onSubmit = async (data) => {
    console.log(area);
    // if (area == undefined || area == "" || area.length == 0) {
    //   console.log(area);

    //   setError(true);
    //   message.error("Please select Your Town/city");
    // }
    console.log("submit", data);

    console.log({ areaCo: { lat, long } });
    const {
      nickName,
      buildingNo,
      apartment,
      floor,
    } = data;
    //  setValue("phoneNumber", `${phoneNumber}`);
    const obj = {
      nickName,
      buildingNo,
      apartment,
      floor,
      areaCo: { lat, long },
    };

    // if(city == undefined || city == "" || city.length == 0) {
    //  setError(true)
    //  message.error("Please select Your Town/city")

    // }

    console.log(obj);
    if (obj.areaCo.lat == undefined) {
      message.error("Please select Your Location");
      return;
    }
    setLoadingAddress(true);

    if (addressId) {
      let response = await updateAddressApi(addressId, obj);
      console.log(response.data.data);
      if (response.data.data) {
        message.success(response.data.messageEn);
        setLoadingAddress(false);
        if (defaultAddress) {
          let obj = { addressId: response.data.data?._id };
          setDefaultAddressApi(obj)
            .then((res) => {
              console.log(res.data.data);
              window.history.back();
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else {
          window.history.back();
        }
      }
    } else {
      let response = await addAddressApi(obj);
      console.log(response.data.data);
      if (response.data.data) {
        message.success("Address Added Successfully");
        setLoadingAddress(false);
        if (defaultAddress) {
          let obj = { addressId: response.data.data?._id };
          setDefaultAddressApi(obj)
            .then((res) => {
              console.log(res.data.data);
              window.history.back();
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else {
          window.history.back();
        }
      }
    }

    //  setValue("city", "");
    setValue("floor", "");
    setValue("apartment", "");
    // setValue("street", "");
    setValue("buildingNo", "");
    // setValue("district", "");
    // setValue("phoneNumber", "");
    setValue("nickName", "");
    setDefaultAddress(false);
    // setArea("");
    // setCity("")
  };
  // useEffect(() => {
  //   getLocation()
  // }, [])
  useEffect(() => {
    console.log(location);
  }, [location]);
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F5F5F5" }}>
        <div
          style={{
            width: "100%",
            // height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
            // position:"relative"
          }}
        >
          <div
            style={{
              width: "90%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              paddingBottom: "1em",
              boxSizing: "border-box",
            }}
          >
            <button
              style={{
                width: "45px",
                height: "45px",
                padding: "0.25em",
                position: "absolute",
                left: "0px",
                border: "none",
                outline: "none",
                display: "flex",
                backgroundColor: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                boxShadow: "0 0 4px lightgray",
                color: "rgba(72, 160, 219, 1)",
              }}
              onClick={() => window.history.back()}
            >
              <FaChevronLeft />
            </button>
            <p style={{ fontWeight: "600", fontSize: "18px" }}>
              {language=="en"? addressId ? "EDIT ADDRESS" : "NEW ADDRESS":addressId ? "تعديل العنوان" : "عنوان جديد"}
            </p>
          </div>
          {loading ? (
            <>
              <Space>
                <Skeleton.Image
                  active
                  style={{
                    maxWidth: "500px",
                    width: "90Vw",
                    height: "35vh",
                    marginTop: 20,
                    borderRadius: 12,
                  }}
                />
              </Space>
            </>
          ) : (
            <>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "2em auto 0em",
                  direction: language=="en"?"ltr":"rtl"
                }}
              >
                <p style={{ fontSize: "18px", fontWeight: "600" }}>
                 {language=="en"?"Location Pin":"رمز الموقع"}
                </p>
                <button
                  style={{
                    color: "rgba(72, 160, 219, 1)",
                    border: "none",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid rgba(72, 160, 219, 1)",
                    cursor: "pointer",
                  }}
                  onClick={() => getLocation()}
                >
                   {language=="en"?"Refine Map":"تحديد موقعك"}
                </button>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "300px",
                  marginTop: "1em",
                  // backgroundColor: "white",
                  // backgroundImage: `url(${Url + store?.logo})`,
                  // backgroundSize: "cover",
                  // backgroundRepeat: "no-repeat",
                  // backgroundPosition: "center",
                  borderRadius: "15px",
                }}
              >
                <Map location={location} />
              </div>
              {/* <h3 id="demo"></h3> */}
            </>
          )}

          <form onSubmit={handleSubmit3(onSubmit)}>
            <div
              style={{
                width: "90%",
                margin: "2em auto 0em",
              }}
            >
              <input
                type="text"
                style={{
                  width: "100%",
                  margin: "1em auto",
                  boxSizing: "border-box",
                  padding: "1.2em 1em",
                  textIndent: "1em",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0 0 4px lightgray",
                  direction: language=="en"?"ltr":"rtl"
                }}
                placeholder={language=="en"?"Nickname":"اللقب"}
                {...register("nickName")}
                // value={nickName}
                // onChange={(e) => setNickName(e.target.value)}
              />
              <p style={{ color: "red" }}>{errors.nickName?.message}</p>
              {/* <input
                type="text"
                style={{
                  width: "100%",
                  margin: "1em auto",
                  boxSizing: "border-box",
                  padding: "1.2em 1em",
                  textIndent: "1em",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0 0 4px lightgray",
                }}
                placeholder="street Address"
                {...register("street")}
                // value={street}
                // onChange={(e) => setStreet(e.target.value)}
              />
              <p style={{ color: "red" }}>{errors.street?.message}</p> */}
              <input
                type="number"
                // min={1}
                style={{
                  width: "100%",
                  margin: "1em auto",
                  boxSizing: "border-box",
                  padding: "1.2em 1em",
                  textIndent: "1em",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0 0 4px lightgray",
                  direction: language=="en"?"ltr":"rtl"
                }}
                placeholder={language=="en"?"Building Number":"رقم المبنى"}
                {...register("buildingNo")}
                // value={buildingNo}
                // onChange={(e) => setBuildingNo(e.target.value)}
              />
              <p style={{ color: "red" }}>
                {errors.buildingNo?.message &&
                  "enter valid building number value"}
              </p>
              <input
                type="number"
                // min={1}
                style={{
                  width: "100%",
                  margin: "1em auto",
                  boxSizing: "border-box",
                  padding: "1.2em 1em",
                  textIndent: "1em",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0 0 4px lightgray",
                  direction: language=="en"?"ltr":"rtl"
                }}
                placeholder={language=="en"?"Apartment":"الشقة"}
                {...register("apartment")}
                // value={apartment}
                // onChange={(e) => setApartment(e.target.value)}
              />
              <p style={{ color: "red" }}>
                {errors.apartment?.message && "enter valid apartment value"}
              </p>
              <input
                type="number"
                // min={1}
                style={{
                  width: "100%",
                  margin: "1em auto",
                  boxSizing: "border-box",
                  padding: "1.2em 1em",
                  textIndent: "1em",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0 0 4px lightgray",
                  direction: language=="en"?"ltr":"rtl"
                }}
                placeholder={language=="en"?"Floor":"الطابق"}
                {...register("floor")}
                // value={floor}
                // onChange={(e) => setFloor(e.target.value)}
              />
              <p style={{ color: "red" }}>
                {errors.floor?.message && "enter valid floor value"}
              </p>
              {/* <input
                type="text"
                style={{
                  width: "100%",
                  margin: "1em auto",
                  boxSizing: "border-box",
                  padding: "1.2em 1em",
                  textIndent: "1em",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0 0 4px lightgray",
                }}
                placeholder="District"
                {...register("district")}
                // value={district}
                // onChange={(e) => setDistrict(e.target.value)}
              />
              <p style={{ color: "red" }}>
                {errors.district?.message && "enter valid district value"}
              </p> */}
              {/* <input
                type="text"
                style={{
                  width: "100%",
                  margin: "1em auto",
                  boxSizing: "border-box",
                  padding: "1.2em 1em",
                  textIndent: "1em",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0 0 4px lightgray",
                }}
                placeholder="Town/City"
                {...register("city")}
                // value={city}
                // onChange={(e) => setCity(e.target.value)}
              />
              <p style={{ color: "red" }}>{errors.city?.message&&"enter valid city value"}</p> */}
              {/* <input
              type="text"
              style={{
                width: "100%",
                margin: "1em auto",
                boxSizing: "border-box",
                padding: "1.2em 1em",
                textIndent: "1em",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0 0 4px lightgray",
              }}
              placeholder="Phone Number"
              {...register("phoneNumber")}
              /> */}
              {/* <select
                className="select-area"
                value={area}
                placeholder="Select Area"
                // {...register("area")}
                onChange={handleChangeArea}
                style={{
                  width: "100%",
                  margin: "1em auto",
                  boxSizing: "border-box",
                  padding: "1.2em 1em",
                  textIndent: "1em",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0 0 4px lightgray",
                }}
              >
                <option disabled value={""} style={{ color: "gray" }}>
                  {" "}
                  Select Area{" "}
                </option>
                {Areas?.map((area) => (
                  <option
                    style={{ maxWidth: 500 }}
                    key={area._id}
                    id={area._id}
                    value={area.name}
                  >{`${area.name_combine} , ${area.direction}`}</option>
                ))}
              </select>
              {error && (
                <p className="error" style={{ color: "red" }}>
                  Area is required!
                </p>
              )} */}
              {/* <Controller
                name="phoneNumber"
                control={control1}
                rules={{
                  validate: (value) => isValidPhoneNumber(value),
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    onChange={onChange}
                    value={value}
                    id="phoneNumber"
                    containerStyle={{
                      margin: "1em auto",
                    }}
                    // {...field}

                    // inputProps={{
                    //     name: 'mobileNumber',

                    //    }}
                    autoFormat
                    enableSearch
                    enableClickOutside
                    enableTerritories
                    country={"sa"}
                    inputStyle={{
                      width: "100%",
                      margin: "3em auto",
                      boxSizing: "border-box",
                      paddingTop: "1.8em ",
                      paddingBottom: "1.8em ",
                      // paddingLeft:"2.5em",
                      textIndent: "1em",
                      border: "none",
                      outline: "none",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      boxShadow: "0 0 4px lightgray",
                    }}

                    // onChange={phone =>addPhoneNumber(phone)}
                    // {...register("mobileNumber")}
                  />
                )}
              />
              <p style={{ color: "red" }}>{errors.phoneNumber?.message}</p> */}
            </div>

            <div style={{ margin: "30px auto 150px", width: "90%" }}>
              <div style={{ display: "flex", justifyContent: "space-between",direction: language=="en"?"ltr":"rtl" }}>
                <label
                  style={{
                    color: "rgba(72, 160, 219, 1)",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                   {language=="en"?"Set address as default":"اجعل هذا العنوان افتراضى"}
                </label>
                <Checkbox className="addressCheckbox" onChange={onChange} />
              </div>
            </div>

            {/* <br />
          <br />
          <br />
          <br />
          <br /> */}

            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "2em 1em",
                boxSizing: "border-box",
                position: "absolute",
              }}
            >
              <div
                style={{
                  width: "90%",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    width: "100%",

                    // marginTop: "2em",
                    padding: "0.8em 1em",
                    border: "none",
                    outline: "none",
                    display: "flex",
                    backgroundColor: "#F6A94A",
                    color: "white",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 0 4px lightgray",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  disabled={loadingAddress}
                  type="submit"
                  // onClick={addAddress}
                >
                  {loadingAddress ? <Spin indicator={antIconPlace} /> :  language=="en"?"Save":"حفظ"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
