import React, { useEffect, useMemo, useState ,useLayoutEffect} from "react";
import { FaArrowLeft, FaTrash, FaTrashAlt } from "react-icons/fa";
import { BsTelephone, BsTrash3 } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { PhoneOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { allStoresApi } from "../../Apis";
import Map from "../../components/Map";




export const MyOrder = () => {
  const navigate = useNavigate();
  const {storeName} = useParams()
  const [store , setStore] = useState([])
  

  const getStore = () =>{
    allStoresApi().then(response =>{
      console.log(response.data.data);
      let store = response.data.data.filter(data => data?.name?.replaceAll(/\s+/g, '-') == storeName?.replaceAll(/\s+/g, '-'))
      console.log(store);
      setStore(store)
      
    })
  }
  useEffect(() => {
    getStore()
  }, [])

  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F5F5F5" }}>
        <div
          style={{
            width: "90%",
            // height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
          }}
        >
          <div style={{display:"grid",textAlign:"center",position:"relative",alignItems:"center"}}>
         <button
            style={{
              width: "45px",
              height: "45px",
              padding: "0.25em",
              border: "none",
              outline: "none",
              display: "flex",
              position:"absolute",
              backgroundColor: "white",
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "0 0 4px lightgray",
            }}
            onClick={() => window.history.back()}
          >
            <FaArrowLeft />
          </button>
          <h2 style={{fontWeight:600}}>Order Details</h2>
          </div>



        
   
        </div>

        <div style={{width:"100%" , height:"300px"}}>
       {store&&

            <Map store={store} />
       }
        
        </div>
        <div style={{background:"white",display:"flex",flexDirection:"column" ,gap:"20px", width:"auto" ,height:"100%" , borderRadius:"20px 20px 0 0 ", padding :"20px 20px 20px 20px"}}>
          
              <span style={{fontSize:"18px",fontWeight:"500",marginTop:"20px"}}>Estimated Delivery Time</span>
              <span style={{fontWeight:"700",color:"#48A0DB"}}>12:30 - 12:45</span>
               <span style={{position:"relative",background:"#C4C4C4",borderRadius:"10px",width:"100%",height:"5px"}}>
                <span style={{width:"30%",height:"5px",borderRadius:"10px",background:"#48A0DB",zIndex:"99", position:"absolute"}}></span>
               </span>
               <span style={{fontSize:"18px",fontWeight:"500"}}>Order Confirmed</span>
               <p style={{fontSize:"12px",color:"#686D76",fontWeight:"400"}}>Your order has been received</p>
     
             <hr style={{border:"1px solid #F1F2F3",borderColor:"#F1F2F3",margin:"15px 0"}} />
             <span style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between",alignItems:"center"}}>
              <div style={{display:"flex",flexDirection:"column",gap:"15px" , justifyContent:"start"}}>
                <span style={{fontSize:14,fontWeight:400}}>Your driver is</span>
                <span style={{fontSize:16 , fontWeight:700}}>John Dow</span>
              </div>
              <BsTelephone style={{color:"#48A0DB" , fontSize:"20px",fontWeight:"500"}}/>
             </span>
             <hr style={{border:"1px solid #F1F2F3",borderColor:"#F1F2F3",margin:"15px 0"}} />
             <span style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between",alignItems:"center"}}>
              <div style={{display:"flex",flexDirection:"column",gap:"15px" , justifyContent:"start"}}>
                <span style={{fontSize:14,fontWeight:400}}>Provide instructions to</span>
                <span style={{fontSize:16 , fontWeight:700}}>John Dow</span>
              </div>
              <BsTelephone style={{color:"#48A0DB" , fontSize:"20px",fontWeight:"500"}}/>
             </span>
             <hr style={{border:"1px solid #F1F2F3",borderColor:"#F1F2F3",margin:"15px 0"}} />
             <span style={{display:"flex" ,flexDirection:"column",gap:"16px"}}>
             
                <span style={{fontSize:14,fontWeight:400 ,color:"#48A0DB"}}>Your order from</span>
                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between" , alignItems:"center"}}>
                  <span style={{fontSize:18 , fontWeight:700,color:"#48A0DB"}}>Survival Cake</span>
                  <span style={{fontSize:18 , fontWeight:700,color:"#48A0DB"}}>#238590</span>
                </div>
              <span  style={{fontSize:14,fontWeight:400 ,color:"#48A0DB",marginTop:"20px"}}>
              <Space size="middle">
                  <span>1</span> 
                  <span> Smokey Beef Regular Meal </span> 
              </Space>
              </span>
              <span  style={{fontSize:14,fontWeight:400 ,color:"#48A0DB"}}>
                <Space size="middle">
                  <span>1</span> 
                  <span> Long Bun Beef Meal </span> 
              </Space>
              </span>
             </span>
             {/* <hr style={{border:"1px solid #F1F2F3",borderColor:"#F1F2F3"}} /> */}
             <br />
             <br />

             <button
                style={{
                  width:"100%",
                  backgroundColor: "transparent",
                  padding: "0.7em 1em",
                  border: "1px solid #48A0DB",
                  fontSize:"16px",
                  cursor:"pointer",
                  fontWeight:"bold",
                  borderRadius: "10px",
                }}
                onClick={() => navigate("/order-history")}
              >
               View Order
              </button>
          </div>
        
        {/* <div
          style={{
            width: "100%",
            backgroundColor: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "2em 1em",
            boxSizing: "border-box",
          }}
        >
          <div style={{ width: "100%", margin: "auto",display:"flex",justifyContent:"space-between",alignItems:"center" }}>

              <button
                style={{
                  width:"100%",
                  backgroundColor: "transparent",
                  padding: "0.7em 1em",
                  border: "1px solid #48A0DB",
                  fontSize:"16px",
                  cursor:"pointer",
                  fontWeight:"bold",
                  borderRadius: "10px",
                }}
              >
               Back To Menu
              </button>


            <button
              style={{
                width: "55%",
                marginLeft:"10px",
                padding: "1em 1em",
                border: "none",
                outline: "none",
                display: "flex",
                backgroundColor: "#F6A94A",
                color: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                cursor:"pointer",
                boxShadow: "0 0 4px lightgray",
              }}
              onClick={()=>navigate(`/${storeName}/checkout`)}
            >
              Checkout
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};