import React, { useState, useRef, useEffect } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  Autocomplete,
} from "@react-google-maps/api";
import { Url, getNearestBranchApi } from "../../Apis";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { message } from "antd";


const mapContainerStyle = { width: "100%", height: "500px" };
const libraries = ["places"]

const originPoint = { lat: 24.7290416, lng: 46.6587076 }

function Map(props) {
  const navigate = useNavigate()
  const { store } = props;
  const [loading, setLoading] = useState("")
  const [latLng, setLatLng] = useState(originPoint);
  const [map, setMap] = useState(null);
  const autocompleteRef = useRef(null);
  const [isMount, setMount] = useState(false)
  const { storeName } = useParams()
  const { language } = useSelector(state => state)

  

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: `AIzaSyAdBxVzMCeTOHZSeNMOrgQxhj92dklkrLo`,
    libraries: libraries,
  });

  var options = {
    enableHighAccuracy: true,
    timeout: 1000,
    maximumAge: 0,
  };

  const successCallback = function () {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const location = { lat, lng };
          setLatLng(location);
          if (isMount) {
            getAddressFromLatLng(lat, lng);
          }
          // setLoading(false);
        }
      );
    }
  };

  function errorCallback(error) {
    // setLoading(false);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        alert("Unknown error");
    }
  }

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      // setLoading(true);
      navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback,
        options
      );
    } else {
      alert("Geolocation is not supported by your browser");
    }
  };

  useEffect(() => {
    getCurrentLocation();
    setMount(!isMount)
  }, []);

  function handleLoad(maps) {
    setMap(maps);
  }

  async function onPlaceChanged() {
    if (autocompleteRef?.current?.value === "") {
      return;
    }

    // eslint-disable-next-line no-undef
    const geocoder = new google.maps.Geocoder();
    console.log("geocoder", geocoder);
    geocoder.geocode(
      { address: autocompleteRef?.current?.value },
      function (results, status) {
        // eslint-disable-next-line no-undef
        if (status === google.maps.GeocoderStatus.OK) {
          let latitude = results[0].geometry.location.lat();
          let longitude = results[0].geometry.location.lng();
          const location = { lat: latitude, lng: longitude };
          setLatLng(location);
          // dispatch(saveCurrentLatLng(location))
          // dispatch(saveAddress(autocompleteRef.current.value))
        } else {
          alert(
            "Geocode was not successful for the following reason: " + status
          );
        }
      }
    );
  }

  const getAddressFromLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { location: { lat, lng } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            if (autocompleteRef.current) {
              autocompleteRef.current.value = results[0].formatted_address;
              geocoder.geocode({ address: autocompleteRef?.current?.value });
              console.log(results[0].formatted_address);
            }
          } else {
            console.log("No results found");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      }
    );
  };

  function handleDragEnd(e) {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const location = { lat, lng };
    setLatLng(location);
    getAddressFromLatLng(lat, lng);
  }

  const confirmLocation = () => {
    let lat = latLng?.lat
    let lng = latLng?.lng
    const payload = {
      store: store?._id,
      location: [lat, lng]
    }
    setLoading(true)
    getNearestBranchApi(payload).then(res => {
      setLoading(false)
      if (res?.data && res?.data.status === 200) {
        const branch = res.data?.data?.branch
        localStorage.setItem('branchId', branch._id)
        localStorage.setItem('branchNameArabic', branch.nameArabic ? branch.nameArabic?.trim() : "")
        localStorage.setItem('branchName', branch.name ? branch?.name?.trim() : "")
        localStorage.setItem("branchImage", branch?.image ?  branch?.image : "")
        localStorage.setItem('branch_Lat', branch?.lat)
        localStorage.setItem('branch_Lng', branch?.lng)
        navigate(`/${storeName}`)
      }
    }).catch(err => {
      console.log("CatchErrore", err.response.data.messageEn);
      // alert(err.response?.data?.messageEn)
      message.error(err.response.data.messageEn)
      setLoading(false)
    })
  }

  return (
    <div>
      {isLoaded && (
        <GoogleMap
          center={latLng}
          zoom={13}
          mapContainerStyle={mapContainerStyle}
          onLoad={handleLoad}
        >
          <MarkerF
            draggable
            onDragEnd={handleDragEnd}
            position={latLng}
          />
          <Autocomplete onPlaceChanged={onPlaceChanged}>
            <div style={{ top: "10px", alignItems: "center", color: 'red', textAlign: 'center' }}>
              <input
                style={{ backgroundColor: "red" }}
                type="search"
                placeholder="Enter placement location"
                ref={autocompleteRef}
                options={{ types: ["geocode"] }}
              />
            </div>
          </Autocomplete>
        </GoogleMap>
      )}

      <div
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "15px"
        }}
      >
        <button
          style={{
            width: "100%",
            fontSize: "16px",
            fontWeight: "600",
            padding: "0.8em 1em",
            border: "none",
            outline: "none",
            display: "flex",
            backgroundColor: "#F6A94A",
            color: "white",
            borderRadius: "10px",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            boxShadow: "0 0 4px lightgray",
          }}
          onClick={confirmLocation}
        >
          {language == "en" ? 'Confirm location' : 'تأكيد الموقع'}
        </button>
      </div>

    </div>
  )
}

export default Map
