import React from "react";
import { Checkbox } from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const ItemCustom = () => {
  const options = [
    {
      label: "Hummus",
      value: "hummus",
    },
    {
      label: "Fries Box",
      value: "fries",
    },
    {
      label: "Garlic Box",
      value: "garlic",
    },
    
    {
      label: "Spicy Sauce Box",
      value: "spicy",
    },
    {
      label: "Coleslaw Box",
      value: "coleslaw",
    },
    {
      label: "Pickle Box",
      value: "pickle",
    },
  ];
  const options1 = [
    {
        label: "Cheetos",
        value: "cheeos",
      },
      {
        label: "Pickles",
        value: "pickles",
      },
      {
        label: "Cheese",
        value: "cheese",
      },
  ]

  const navigate = useNavigate();
  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F5F5F5" }}>
        <div
          style={{
            width: "100%",
            // height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
          }}
        >
          <button
            style={{
              width: "45px",
              height: "45px",
              padding: "0.25em",
              border: "none",
              marginLeft: "5%",
              outline: "none",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "0 0 4px lightgray",
            }}
            onClick={() => window.history.back()}
          >
            <FaArrowLeft />
          </button>

          <div
            style={{
              width: "90%",
              height: "250px",
              margin: "1em auto 0em",
              backgroundColor: "white",
              backgroundImage: "url(./item-custom.png)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "15px",
            }}
          ></div>

          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                margin: "1em auto 0em",
              }}
            >
              <p style={{ fontSize: "18px", fontWeight: "500" }}>
                Cheetos x Chicken Bureger
              </p>
              <p>550 Calories</p>
            </div>

            <div
              style={{ marginTop: "1em", width: "90%", margin: "1em auto 0em" }}
            >
              <p style={{ color: "gray", fontSize: "14px" }}>
                is placeholder text commonly used in the graphic, print, and
                publishing industries for previewing layouts and visual mockups.
              </p>
            </div>

            <div
              style={{
                width: "100%",
                marginTop: "2em",
                backgroundColor: "white",
                boxSizing: "border-box",
                padding: "24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={
                    {
                      //   display: "flex",
                    }
                  }
                >
                  <p
                    // className="updateUserPassLabel"
                    style={{ fontWeight: "500" }}
                  >
                    Add Sides
                  </p>

                  <Checkbox.Group
                    options={options}
                    // value={permissionArr}
                    //onChange={handlePermissionChange}
                    // className="vertical-checkbox-group"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "1em",
                    }}
                  />
                </div>
                <button
                  style={{
                    padding: ".25em 1em",
                    height: "24px",
                    width:"100px",
                    border: "none",
                    backgroundColor: "rgba(72, 160, 219, 0.2)",
                    borderRadius: "15px",
                  }}
                >
                  Required
                </button>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                marginTop: "2em",
                backgroundColor: "white",
                boxSizing: "border-box",
                padding: "24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={
                    {
                      //   display: "flex",
                    }
                  }
                >
                  <p
                    // className="updateUserPassLabel"
                    style={{ fontWeight: "500" }}
                  >
                    Remove
                  </p>

                  <Checkbox.Group
                    options={options1}
                    // value={permissionArr}
                    //onChange={handlePermissionChange}
                    // className="vertical-checkbox-group"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "1em",
                    }}
                  />
                </div>
                <button
                  style={{
                    padding: ".25em 1em",
                    height: "24px",
                    width:"100px",

                    border: "none",
                    backgroundColor: "rgba(72, 160, 219, 0.2)",
                    borderRadius: "15px",
                  }}
                >
                  Optional
                </button>
              </div>
            </div>


            <p
              style={{
                width: "90%",
                fontSize: "20px",
                fontWeight: "700",
                margin: "2em auto 0em",
              }}
            >
              Any Special Instructions
            </p>

            <input
              type="text"
              style={{
                width: "90%",
                marginTop: "2em",
                marginLeft: "5%",
                boxSizing: "border-box",
                padding: "1.2em 1em",
                textIndent: "1em",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0 0 4px lightgray",
              }}
              placeholder="Tell Us Here...."
            />
          </div>

          <br />
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "2em 1em",
            boxSizing: "border-box",
            margin: "auto",
          }}
        >
          <div
            style={{
              width: "96%",
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "35%",
                //   marginTop: "2em",
                padding: "0.55em",
                border: "1px solid blue",

                display: "flex",

                borderRadius: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: "0 0 4px lightgray",
              }}
            >
              <button
                style={{
                  backgroundColor: "transparent",
                  //   padding: "0.5em",
                  border: "none",
                  fontSize: "18px",
                }}
              >
                -
              </button>

              <span>02</span>
              <button
                style={{
                  backgroundColor: "transparent",
                  //   padding: "0.5em",
                  border: "none",
                  fontSize: "18px",
                }}
              >
                +
              </button>
            </div>
            <button
              style={{
                width: "55%",
                // marginTop: "2em",
                padding: "1em 1em",
                border: "none",
                outline: "none",
                display: "flex",
                backgroundColor: "#F6A94A",
                color: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 0 4px lightgray",
              }}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
