import React, { useEffect, useState } from "react";
import "./Restaurants.css";
import { Empty, Segmented, Skeleton, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { BiLogOut, BiSearchAlt2 } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai"
import { Card } from "antd";
import { Url, allStoresApi, getStoresCatApi } from "../../Apis";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Badge } from 'antd';
import { FiShoppingCart } from "react-icons/fi";
import { HiMenuAlt2 } from "react-icons/hi";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { FaChevronLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
const { Meta } = Card;
export const Restaurants = () => {
  const navigate = useNavigate()
  const [stores, setStores] = useState([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [storesCat, setStoresCat] = useState([])
  const [storeFilter, setStoreFilter] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [active, setActive] = useState("")
  const { language } = useSelector(state => state)
  const getAllStores = () => {
    setLoading(true)
    allStoresApi().then(res => {
      console.log(res?.data?.data?.filter(data => data.isDeleted == false));
      setStores(res?.data?.data?.filter(data => data.isDeleted == false));
      setStoreFilter(res?.data?.data?.filter(data => data.isDeleted == false))
      setLoading(false)

    })
  }

  const handleActiveCategory = (catId) => {
    const element = document.getElementById(catId.toString());
    console.log(element);
    if (element) {

      element.scrollIntoView({ behavior: "smooth", block: 'start' });
      // element.style.boxShadow ="0 0 10px"
      // element.style.transform ="translate ease 0.33s"
    }
    setActive(catId)
    console.log(catId);
    // if (!activeCategory.includes(catId) && !Arr.includes(catId)) {
    //   console.log("Arr",Arr);
    //   setLoading(true)
    //   Arr.push(catId)
    //   setActiveCategory(prev => [...prev, catId])
    //   setLoading(false)


    // }else {
    //   setLoading(true)
    //   setActiveCategory(prev =>[...prev.filter(id => id !== catId)] )
    //   setLoading(false)
    // }
  }
  useEffect(() => {
    const inputEle = document.querySelector(".active")
    var time;
    if (inputEle) {
      clearTimeout(time)
      time = setTimeout(() => {

        inputEle.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
      }, 700);
    }
  }, [active])
  const getStoresCat = () => {
    getStoresCatApi().then(res => {
      console.log(res.data.data);
      setStoresCat(res.data.data)
    })
  }



  const token = localStorage.getItem('token');
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const handlelogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#F6A94A',
      confirmButtonText: 'ok'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token")
        navigate("/login")
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    })
  }
  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  }
  useEffect(() => {
    if (searchValue?.length > 0) {
      console.log(stores.filter(store => store?.name?.includes(searchValue)));
      setStoreFilter(stores.filter(store => language == "en" ? store.name.toLowerCase()?.includes(searchValue.toLowerCase()) : store.nameArabic.toLowerCase()?.includes(searchValue.toLowerCase())))

    } else {
      setStoreFilter(stores)
    }
  }, [searchValue])

  useEffect(() => {
    getAllStores()
    getStoresCat()
    // if(token){

    // }
  }, [])
  return (
    <>
      <div
        style={{ width: "100%", backgroundColor: "#F5F5F5" }}
      >
        <div
          style={{
            width: "90%",
            height: "100%",
            margin: "auto",
            padding: "1em 0em 2em",
            boxSizing: "border-box",
          }}
        >
          <button
            style={{
              width: "45px",
              height: "45px",
              padding: "0.25em",
              border: "none",
              outline: "none",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "0 0 4px lightgray",
              color: "rgba(72, 160, 219, 1)",
              marginBottom: "1em"
            }}
            onClick={() => window.history.back()}
          >
            <FaChevronLeft />
          </button>
          <Header toggleOrderType={true} />
          <div style={{ position: "sticky", top: "0", background: "#f5f5f5", width: "100%", paddingRight: "12px", zIndex: "999" }} >
            <div
              style={{ display: "flex", position: "relative", marginTop: ".5em" }}
              dir={language == "en" ? "ltr" : "rtl"}
            >
              <BiSearchAlt2
                style={{
                  position: "absolute",
                  top: "42px",
                  left: language == "en" ? "10px" : "auto",
                  right: language == "en" ? "auto" : "10px",
                  fontSize: "20px",
                }}
              />
              <input
                type="text"
                value={searchValue}
                onChange={(e) => handleSearch(e)}
                style={{
                  width: "100%",
                  margin: "2em auto",
                  boxSizing: "border-box",
                  padding: "1.2em 1em",
                  textIndent: "25px",
                  border: "none",
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  boxShadow: "0 0 4px lightgray"
                }}
                placeholder={language == "en" ? "Search" : "يبحث"}
              />
              {searchValue.length > 0 &&

                <div style={{ width: "100%", position: "absolute", background: "#eee", maxHeight: "450px", boxShadow: "0 0px 8px #00000075", overflow: "auto", borderRadius: "12px", marginTop: "80px", zIndex: "999", transition: "all ease 1s" }}>
                  <ul style={{ listStyle: "none" }} dir={language == "en" ? "ltr" : "rtl"}>
                    {storeFilter.length == 0 ? <Empty style={{ margin: "80px 0" }} image={Empty.PRESENTED_IMAGE_SIMPLE} /> : storeFilter?.map(store => (
                      <>
                        <li style={{ padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10 }}>
                          <img src={Url + store?.logo} width={70} height={70} style={{ borderRadius: "10px" }} alt="img" />

                          <h4 style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "auto" }}>{language == "en" ? store.name : store.nameArabic}</h4>
                          <button style={{ color: "#fff", background: "#48A0DB", padding: "8px 10px", border: "none", borderRadius: "10px", cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => navigate(`/${store?.name?.replace(/\s+/g, '-')}/branch`)}>{language == "en" ? "Chose Store" : "اختر المتجر"}</button>
                        </li>

                      </>
                    ))}
                  </ul>

                </div>
              }
            </div>
            <div
              className="btns-div"
              style={{
                width: "100%",
                margin: "0em auto",
                boxSizing: "border-box",
                padding: "0em 1em",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                overflow: "auto",
                display: "flex",
                whiteSpace: "nowrap",
                scrollbarWidth: "none",
                scrollbarColor: "transparent transparent",
                msOverflowStyle: "none",

              }}
              dir={language == "en" ? "ltr" : "rtl"}
            >
              {loading ? <>
                {[1, 2, 3, 4, 5].map((el, i) => (<>
                  <Space >
                    <Skeleton.Button style={{ margin: "1.5em 0.5em" }} active={true} size={"large"} shape={"round"} block={false} />
                  </Space>

                </>))}

              </> : storesCat.map((data) => (
                <button
                  id={data?.store}
                  key={data?._id}
                  className={active == data?._id ? "active" : ""}
                  onClick={() => handleActiveCategory(data?._id)}
                  style={{
                    border: "1px solid #48A0DB",
                    borderRadius: "50px",
                    margin: "1em 0.5em",
                    padding: ".6em 2em",
                    display: "flex",
                    alignItems: "center",
                    zIndex: "99",
                    outline: "none",
                    cursor: "pointer",
                    position: "relative",
                    background: active == data?._id ? "#48A0DB" : "#f5f5f5",
                    color: active == data?._id ? "#fff" : "#000"
                  }}
                  dir={language == "en" ? "ltr" : "rtl"}
                >
                  {/* <img width={20} height={20} style={{borderRadius:"15px",marginRight:"10px",marginLeft:"-10px",boxShadow:"0 0 4px"}} src={Url + data?.image} alt="img" /> */}
                  {language == "en" ? data?.englishName : data?.arabicName}
                </button>
              ))}
            </div>
          </div>
          <div
            className="cards-div"
            style={{
              width: "100%",
              // height:"800px",
              //  overflowY:"scroll"

              marginTop: "2em"
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            {loading ? <>{[1, 2, 3, 4].map((d, index) => (
              <>
                <div
                  style={{ width: "100%", borderRadius: "15px", margin: "1em 0em", boxShadow: "0 0 8px lightgray" }}
                >
                  {/* <Space > */}

                  <Space
                    direction="vertical"
                    size="middle"
                    style={{
                      display: 'block',
                      width: "100%"
                    }}>
                    <Space>
                      <Skeleton.Image style={{ maxWidth: "460px", width: "90vw", height: "25vh" }} active={true} size={"large"} shape={"round"} />

                    </Space>
                    <br />
                    <Space direction="horizontal" style={{
                      width: "100%"
                    }}>
                      <Skeleton.Button active={true} size={"small"} shape={"round"} block={true} />
                      <Skeleton.Avatar active={true} size={"small"} shape={"round"} block={true} />
                      <Skeleton.Input active={true} size={"small"} shape={"round"} block={true} />
                    </Space>
                  </Space>
                  <br />
                  <br />



                  {/* </Space> */}

                </div>

              </>
            ))}

            </> :
              stores?.map((store) => (
                //     <Card
                //     hoverable
                //     style={{
                //       width: "100%",
                //       marginTop:"1em"
                //     }}
                //     cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
                //   >
                //     <Meta title="Europe Street beat" description="www.instagram.com" />
                //   </Card>
                <div
                  id={store?.categories?._id}
                  style={{ width: "100%", borderRadius: "15px", margin: "1em 0em", boxShadow: "0 0 8px lightgray", scrollMarginTop: 170, cursor: "pointer" , background: "#fff" }}

                  onClick={() => navigate(`/${store?.name?.replace(/\s+/g, '-')}/branch`)}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "200px",
                      backgroundImage: `url(${Url + store?.logo})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                      position: "relative"
                    }}
                  >

                    {/* <div
                      style={{
                        padding:"0.15em 1em",
                        backgroundColor:"#F6A94A",
                        borderRadius:"5px",
                        position:"absolute",
                        bottom:"10px",
                        right:"10px",
                        color:"white"
                      }}
                      >
                        10-15 <br/>
                        mins

                      </div> */}
                    {store?.deliveryTime &&

                      <div
                        style={{
                          padding: "0.15em 1em",
                          backgroundColor: "#F6A94A",
                          borderRadius: "5px",
                          position: "absolute",
                          bottom: "-65px",
                          right: language == "en" ? "10px" : "auto",
                          left: language == "en" ? "auto" : "10px",
                          color: "white",
                          // fontSize:"12px"
                        }}
                      >
                        {/* 10-15 <br />
                    mins */}
                        <p>{store?.deliveryTime?.split(' ')[0]}</p>
                        <p>{store?.deliveryTime?.split(' ')[1]}</p>
                      </div>
                    }

                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "0.5em 1em",
                      borderBottomLeftRadius: "15px",
                      borderBottomRightRadius: "15px"
                    }}
                  >
                    <div style={{ padding: "0.5em", fontWeight: "bold", fontSize: "18px" }}>
                      {language == "en" ? store?.name : store?.nameArabic}
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "0.5em" }}>
                      <p style={{ color: "lightgray" }}>{language == "en" ? store?.categories?.englishName : store?.categories?.arabicName}</p>
                      {/* <p style={{color:"#F6A94A"}}><span style={{marginRight:"10px",padding:"0.25em",backgroundColor:"lightgray",display:"flex",display:"inline-block",justifyContent:"center",alignItems:"center", borderRadius:"5px"}}><AiFillStar style={{color:"#F6A94A"}}/>4.5</span>20% off</p>    */}
                    </div>

                  </div>

                </div>
              ))
            }

          </div>
        </div>
      </div>
    </>
  );
};
