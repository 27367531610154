import React, { useContext, useEffect, useState } from 'react'
import { GoogleMap, MarkerF, useJsApiLoader  } from '@react-google-maps/api';
import { allStoresApi } from '../Apis';
import { useParams } from 'react-router-dom';
import { Skeleton, Space } from 'antd';
import { appContext } from '../Context/appContext';
const containerStyle = {
    width: '100%',
    height: '300px',
    position:"relative"
  };
  const libraries = ["places"]
  
  function Map(props) {
    const [store , setStore] = useState([])
    const {storeName} = useParams()
    const {setMapAddress,mapAddress} = useContext(appContext)
    const { isLoaded } = useJsApiLoader({
      // id: 'google-map-script',
      googleMapsApiKey: "AIzaSyAdBxVzMCeTOHZSeNMOrgQxhj92dklkrLo" ,
      libraries: libraries,
    })
    console.log(props.store);

    
    const [map, setMap] = React.useState(null)
    const [mapLatLng , setMapLatLng] = React.useState(mapAddress)
    const getStore = () =>{
      allStoresApi().then(response =>{
        console.log(response.data.data);
        let store = response.data.data.filter(data => data?.name?.replaceAll(/\s+/g, '-') == storeName?.replaceAll(/\s+/g, '-') && data?.isDeleted == false )
        console.log(store);
        setStore(store)
        // setMapLatLng({lat:store[0]?.addresses?.length>0? store[0]?.addresses[0].lat:18.52043, lng:store[0]?.addresses?.length>0? store[0]?.addresses[0].long:73.856743 })
        
      })
    }

    useEffect(() => {
      console.log(mapAddress);
      setMapLatLng(mapAddress)
    }, [mapAddress])
    useEffect(() => {
      getStore()
    }, [])
//  useEffect(() => {
//   if(props.location){

//     setMapLatLng(props.location)
//    setMapAddress(props.location)
//   }
//  }, [props.location])

 
 
  // const center = props.store? { lat:store[0]?.addresses?.length>0? store[0]?.addresses[0].lat:18.52043, lng:store[0]?.addresses?.length>0? store[0]?.addresses[0].long:73.856743 } :  props?.location?.lat ? props.location : {lat:18.52043,lng:73.856743}
 // console.log("center", center);
  // useEffect(() => {
  //  console.log(props.store);
  // //  setMapAddress(props.store)
  //  setMapLatLng(center )
  // }, [props.store])

    const handleGetMap = (mapProps) => {
    console.log(mapProps);
    console.log(mapProps.latLng.lat());
    console.log(mapProps.latLng.lng());
    setMapLatLng({lat:mapProps.latLng.lat() , lng:mapProps.latLng.lng()})
    setMapAddress({lat:mapProps.latLng.lat(), lng:mapProps.latLng.lng()})
    }
      const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(mapLatLng);
        map.fitBounds(bounds);
    console.log('maplat',map.center.lat())
    console.log('maplng',map.center.lng())
    setMapLatLng({lat:map.center.lat(),lng:map.center.lng()})
        setMap(map)
      }, [])
    
      const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
      }, [])



    return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapLatLng}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={handleGetMap}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <MarkerF position={mapLatLng} clickable  />
      </GoogleMap>
  ) : <>
    <Space>
     <Skeleton.Input active block  style={{width:"100vw" , maxWidth:"460px",height:"280px"}}/>
    </Space>
    </>
}

export default Map
