import React from "react";
import { FaArrowLeft, FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { useEffect } from "react";
import { updateAccountApi, getUserInfoApi } from "../../Apis";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";



export const AccountDetails = () => {
  const { language } = useSelector(state => state)
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate()
  const [width, setWidth] = useState(window.innerWidth);
  const token = localStorage.getItem("token");
  const { decodedToken, isExpired } = useJwt(token) || '';
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );
  const schema2 = yup.object().shape({
    firstName: yup.string().required("First name is required!").min(2),
    lastName: yup.string().required("Last name is required!").min(3),
    email: yup
      .string("Invalid Email!")
      .email("Invalid Email!")
      .required("Email is required!"),
    // mobileNumber: yup
    //   .string()
    //   .required("Phone number is required")
    //   .min(10, "phone number  should be at least 10 numbers!")
    //   .max(16, "phone number  should be at most 16 numbers!"),
  });
  const {
    register: register,
    handleSubmit: handleSubmit,
    setValue,
    formState: { errors: errors1 },
    control,
  } = useForm({
    resolver: yupResolver(schema2),
  });

  //  useEffect(() => {
  //  setValue("email",decodedToken?.email)
  //  setValue("firstName",decodedToken?.firstName)
  //  setValue("lastName",decodedToken?.lastName)
  // //  setValue("mobileNumber",decodedToken?.mobileNumber)
  // //  setMobile(decodedToken?.mobileNumber)
  //  }, [decodedToken])

  const getUserInfo = async () => {
    try {
      let response = await getUserInfoApi(token);
      let res = response?.data?.data;
      setValue("email", res?.email);
      setValue("firstName", res?.firstName);
      setValue("lastName", res?.lastName);
    } catch (error) {
      console.error("An error occurred:", error);
      // You can handle the error here, for example, showing a message to the user.
    }
  };

  useEffect(() => {
    getUserInfo()
  }, [])

  const onSubmit = (data) => {
    const { email, firstName, lastName, mobileNumber } = data;
    const obj = {
      email,
      firstName,
      lastName,
      // mobileNumber: "+" + mobileNumber,
    };
    // setMobile("+" + mobileNumber);
    try {
      setLoading(true)
      updateAccountApi(data)
        .then(async (data) => {
          // setShowOtp(true);
          MySwal.fire(
            {
              icon: 'success',
              title: language == "en" ? "Updated Successfully" : "تم التعديل بنجاح",
              showConfirmButton: false,
              timer: 1500,
              padding: "20px 0",
              width: 260,
              customClass: {
                container: 'containerSwl',
                title: 'titleSwl ',
              },
            }
          ).then(res => {
            setLoading(false)

            navigate("/")
          }).catch(err => MySwal.fire({
            icon: 'error',
            title: "Something went wrong",
            showConfirmButton: false,
            timer: 1500,
            width: 260,
            customClass: {
              container: 'containerSwl',
            },
          }))
        })
        .catch((error) => {
          setLoading(false)
          MySwal.fire({
            icon: "error",
            title: `${error.response.data.messageEn}`,
            showConfirmButton: false,
            timer: 1500,
            width: 260,
            customClass: {
              container: 'containerSwl',
            },
          });
        });
    } catch (err) {
      setLoading(false)
      MySwal.fire({
        icon: "error",
        title: `${err.response.data.messageEn}`,
        showConfirmButton: false,
        timer: 1500,
        width: 260,
        customClass: {
          container: 'containerSwl',
        },
      });
    }
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <>
      <div
        style={{ width: "100%", height: "100vh", backgroundColor: "#F5F5F5" }}
      >
        <div
          style={{
            width: "90%",
            height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box"
          }}
        >
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1em"
          }}>
            <button
              style={{
                width: "45px",
                height: "45px",
                padding: "0.25em",
                border: "none",
                outline: "none",
                display: "flex",
                backgroundColor: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                boxShadow: "0 0 4px lightgray",
                color: "rgba(72, 160, 219, 1)"
              }}
              onClick={() => window.history.back()}
            >
              <FaChevronLeft />
            </button>
            <p style={{ fontSize: 22, fontWeight: "600", margin: "0em auto", position: "absolute", left: "20%", width: "60%", textAlign: "center" }}>
              {language == "en" ? "ACCOUNT DETAILS" : "تفاصيل الحساب"}


            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 45 }}>
            <input
              type="text"
              {...register("firstName")}
              style={{
                width: "100%",
                margin: "1.5em auto",
                boxSizing: "border-box",
                padding: "1.2em 1em",
                textIndent: "1em",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0 0 4px lightgray",
                direction: language == "en" ? "ltr" : "rtl"
              }}
              placeholder={language == "en" ? "First Name" : "الاسم الأول"}
            />
            <p style={{ color: "red" }}>{errors1.firstName?.message}</p>
            <input
              type="text"
              {...register("lastName")}
              style={{
                width: "100%",
                margin: "1.5em auto",
                boxSizing: "border-box",
                padding: "1.2em 1em",
                textIndent: "1em",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0 0 4px lightgray",
                direction: language == "en" ? "ltr" : "rtl"
              }}
              placeholder={language == "en" ? "Last Name" : "الاسم الأخير"}
            />
            <p style={{ color: "red" }}>{errors1.lastName?.message}</p>
            <input
              type="email"
              {...register("email")}
              style={{
                width: "100%",
                margin: "1.5em auto",
                boxSizing: "border-box",
                padding: "1.2em 1em",
                textIndent: "1em",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0 0 4px lightgray",
                direction: language == "en" ? "ltr" : "rtl"
              }}
              placeholder={language == "en" ? "Email Address" : "البريد الالكترونى"}
            />
            <p style={{ color: "red" }}>{errors1.email?.message}</p>
            {/* <Controller
                  name="mobileNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      containerStyle={{
                        margin: "1em auto",
                      }}
                      {...field}
                      // inputProps={{
                      //     name: 'mobileNumber',

                      //    }}

                      enableSearch
                      enableClickOutside
                      enableTerritories
                      country={"sa"}
                      value={mobile}
                      inputStyle={{
                        width: "100%",
                        margin: "3em auto",
                        boxSizing: "border-box",
                        paddingTop: "1.8em ",
                        paddingBottom: "1.8em ",
                        // paddingLeft:"2.5em",
                        textIndent: "1em",
                        border: "none",
                        outline: "none",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        boxShadow: "0 0 4px lightgray",
                      }}

                      // onChange={phone =>addPhoneNumber(phone)}
                      // {...register("mobileNumber")}
                    />
                  )}
                />

          <p style={{ color: "red" }}>{errors1.mobileNumber?.message}</p> */}

            <button
              type="submit"
              style={{
                width: "100%",
                marginTop: "3.6em",
                padding: language == "en" ? ".8em 1em" : "0.6em 1em",
                border: "none",
                outline: "none",
                display: "flex",
                backgroundColor: "#F6A94A",
                color: "white",
                borderRadius: "6px",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 0 4px lightgray",
                fontSize: language == "en" ? "16px" : "18px",
                fontWeight: language == "en" ? "600" : "500",
                cursor: "pointer",

              }}
              disabled={loading}
            >
              {loading ? <Spin style={{ color: "white" }} indicator={antIcon} /> : language == "en" ? "Saves Changes" : "حفظ التغييرات"}
            </button>
          </form>
          {/* <p
            style={{
              width: "80%",
              textAlign: "center",
              margin: "2em auto",
              fontSize: "18px",
            }}
          >
           Already Have An Account?{" "}
            <button
              style={{
                border: "none",
                fontSize: "20px",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
             // onClick={()=>navigate('/login')}
            >
              Login
            </button>
          </p> */}
        </div>
      </div>
    </>
  );
};
