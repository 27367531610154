import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import {LuClock3} from "react-icons/lu";
import {MdLocationOn} from "react-icons/md";
import {BsCashStack} from "react-icons/bs"
import { useNavigate, useParams } from "react-router-dom";
import { Url, allAddressesApi, allCategoriesApi, allProductsApi, allStoresApi } from "../../Apis";
import { Modal, Skeleton, Space } from "antd";
import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Map from "../../components/Map"
import { useContext } from "react";
import { appContext } from "../../Context/appContext";
export const MoreInfo = () => {
  const navigate = useNavigate();
  const [loading , setLoading] = useState(false)
  const [loadingStore , setLoadingStore] = useState(false)
  const [store , setStore] = useState({})
  const [product , setProduct] = useState({})
  const [categories , setCategories] = useState([])
  const [quantity, setQuantity] = useState(1);
  const token = localStorage.getItem('token');
  const {storeName,itemName} = useParams()
  const [options , setOptions] = useState([])
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const storeData = useSelector(state => state.theStore)
   const [isModalOpen, setIsModalOpen] = useState(false);
   const {setMapAddress,mapAddress} = useContext(appContext)
   const {language}= useSelector(state => state)

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    console.log("storeData",storeData);
    getOneStore()
    // if(token){
     

    // }
   }, [])

   const showModal = () => {
    setIsModalOpen(true);
    if(store){
      setMapAddress({lat:store?.addresses?.length>0? store?.addresses[0].lat:18.52043, lng:store?.addresses?.length>0? store?.addresses[0].long:73.856743 });
    }
  };
  const getOneStore = async() =>{
    setLoadingStore(true)
    allStoresApi().then(res => {
      console.log(res?.data?.data?.filter(data => data.name?.replace(/\s+/g, '-')== storeName))
      let data =res?.data?.data?.filter(data => data.name?.replace(/\s+/g, '-')== storeName)[0]
console.log('storeData',data)
    //   getAllProducts(data?._id)
    //   getAllCategories(data?._id)
      setStore(data)
      setLoadingStore(false)
    })
   }
//   const getOneProduct = (id) =>{
//     let Addons = []
//     setLoading(true)
//     console.log(id);
//     console.log(store);
//     if(id){
//       allProductsApi(id).then(res => {
//        console.log(res?.data?.data);
//       let product = res.data?.data?.filter(prod => prod?.englishName?.replace(/\s+/g, '-') == itemName)[0]
//       console.log(product);
//        setProduct(product)
//        product.addons?.map(addon => {
//         console.log(addon);
//         let oneaddon = {label: addon.name , value: addon._id}
//         if(!Addons.includes(oneaddon)){
//           Addons.push(oneaddon)

//         }
//       })
//       setOptions([...Addons])
//        setLoading(false)
//       }).catch(err => {
//         console.log(err);
//         setLoading(true)
//       })

//     }
//   }


// const handleAddAddon = (e) =>{
//   console.log(e);


//   setSelectedAddOns([...e])
// }
// const handleIncrease = () => {setQuantity(prev => prev + 1)}
// const handleDecrease = () => {setQuantity(prev =>prev >1? prev-1:prev)}
//   const getOneStore = async() =>{
//     allStoresApi().then(res => {
//       console.log(res?.data?.data?.filter(data => data.name?.replace(/\s+/g, '-')== storeName))
//       let data =res?.data?.data?.filter(data => data.name?.replace(/\s+/g, '-')== storeName)[0]
//       getOneProduct(data?._id)

      
//       setStore(data)
//     })
//    }
  
//   useEffect(() => {
  
//     if(token){
     
//     getOneStore()

//     }else{
//       navigate("/login")
//     }
//    }, [])
  return (
    <>
    <Modal open={isModalOpen} cancelText={language=="en"?"cancel":"الغاء"} okText={language=="en"?"ok":"نعم"} onOk={handleOk} onCancel={handleCancel}>
      <div style={{width:"100%" , height:"300px",margin:"20px 0"}}>
            <Map store={store} />
        </div>
    </Modal>
      <div style={{ width: "100%", backgroundColor: "#F5F5F5" }}>
        <div
          style={{
            width: "90%",
            // height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
          }}
        >

         <button
            style={{
              width: "45px",
              height: "45px",
              padding: "0.25em",
              border: "none",
              outline: "none",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "0 0 4px lightgray",
              color:"rgba(72, 160, 219, 1)"
            }}
            onClick={() => window.history.back()}
          >
            <FaChevronLeft />
          </button>
         {loadingStore ? <>
         <Space >
          <Skeleton.Image active  style={{maxWidth:"460px",width:"90Vw",height:"35vh",marginTop:20,borderRadius:12}} />
         </Space>
         </>:
          <div
            style={{
              width: "100%",
              height: "250px",
              marginTop: "1em",
              backgroundColor: "white",
              backgroundImage: `url(${Url+store?.logo})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "15px",
            }}
          ></div>
         }

          <div
            style={{
              width: "100%",
              marginTop: "1em",
            }}
            dir={language=="en" ? "ltr":"rtl"}
          >
            <div
              style={{
                display: "flex",
                flexDirection:"column",
                // justifyContent: "space-between",
                // alignItems: "center",
                width:"100%" ,fontWeight: "700", fontSize: "0.9em" , overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap"
              }}
              dir={language=="en" ? "ltr":"rtl"}
            >
             <>
              <p style={{ overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap", fontSize: "24px", fontWeight: "700" }}>
                {loadingStore?<Skeleton.Button active shape="round" style={{height:15}}/>:language=="en"? store?.name:store?.nameArabic}
              </p>
              <p style={{fontWeight:"500"}}>{loadingStore?<Skeleton.Button active block size="large" shape="round" style={{height:15,width:200}}/>:language=="en"? store?.slogan:store?.sloganArabic}</p>
              
              </>
            </div>

            <div style={{width:"90%",backgroundColor:"white",borderRadius:"10px",padding:".7em 1em",margin:"1em 0em",boxShadow:"0 0 4px lightgray",display:"flex",justifyContent:"space-between"}}>
            <div style={{width:"30%",padding:"0.2em"}}>
            <p style={{display:"flex",alignItems:"center",justifyContent:"center", fontSize:"14px",gap:5}}>{loadingStore?<Skeleton.Button active shape="round" style={{height:10,marginTop:8}}/> :<><LuClock3 style={{marginRight:"0.25em",color:"rgba(72, 160, 219, 1)",fontSize:"16px"}}/>{store?.deliveryTime?store?.deliveryTime:45 + " " + "Mins"}</>}</p>
            </div>
            <div style={{width:"30%",borderLeft:"1px solid",borderRight:"1px solid",padding:"0.2em"}}>
            <p style={{display:"flex",alignItems:"center",justifyContent:"center", fontSize:"14px",gap:5}}><BsCashStack style={{marginRight:"0.25em",color:"rgba(72, 160, 219, 1)",fontSize:"16px"}}/>{language=="en"? "Cash/Card":"كاش/فيزا"}</p>
            </div>
            <div style={{width:"30%",padding:"0.2em"}}>
            <p style={{display:"flex",alignItems:"center",justifyContent:"center", fontSize:"14px",color:"rgba(72, 160, 219, 1)"}}><MdLocationOn style={{marginRight:"0.25em",color:"rgba(72, 160, 219, 1)",fontSize:"16px"}}/> <button style={{border:"none",borderBottom:"1px solid rgba(72, 160, 219, 1)",fontSize:"14px",cursor:"pointer",fontSize:"14px",color:"rgba(72, 160, 219, 1)",backgroundColor:"white"}} onClick={()=>showModal()}>{language=="en"? "Location":"الموقع"}</button></p>
            </div>
            </div>
            <div style={{ marginTop: "1em" }}>
              <p style={{ color: "gray", fontSize: "14px" }}>
                is placeholder text commonly used in the graphic, print, and
                publishing industries for previewing layouts and visual mockups.
              </p>
            </div>
          </div>



          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "2em 1em",
            boxSizing: "border-box",
          }}
        >
          <div style={{ width: "90%", margin: "auto",display:"flex",justifyContent:"space-between",alignItems:"center" }}>
          
            <button
              style={{
                width: "100%",
                // marginTop: "2em",
                padding: "0.8em 1em",
                border: "none",
                outline: "none",
                display: "flex",
                backgroundColor: "#F6A94A",
                color: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 0 4px lightgray",
                fontSize:"18px",
                cursor:"pointer"
              }}
              onClick={()=> navigate(`/${storeName}`)}
            >
              {language=="en"? "Order Now":"اطلب الان"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
