import { configureStore, createSlice } from '@reduxjs/toolkit';
// import React from 'react';


const initialState = {
  storeId:"",
  theStore:{},
  paymentToken:"",
  language:localStorage.getItem("language")?localStorage.getItem("language"):"en",
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setStoreId: (state, action) => {
      state.storeId = action.payload;
    },setTheStore: (state, action) => {
      state.theStore = action.payload;
    },setPaymentToken: (state, action) => {
      state.paymentToken = action.payload;
    }
    ,setLanguage: (state, action) => {
      state.language = action.payload;
    },

  },
});

export const {setStoreId,setTheStore,setPaymentToken ,setLanguage} = languageSlice.actions;

export const  store = configureStore({
  reducer: languageSlice.reducer,
});