import React, { useState, useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { registerApi } from "../../Apis";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { loginApi, reSendOtpApi, sendOtpApi } from "../../Apis";


export const Register = () => {
  const {language}= useSelector(state => state)
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [showOtp, setShowOtp] = useState();
  const [otp, setOtp] = useState("");
  const [resendOtp, setResendOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const schema2 = yup.object().shape({
    firstName: yup.string().required("First name is required!").min(2),
    lastName: yup.string().required("Last name is required!").min(3),
    email: yup
      .string("Invalid Email!")
      .email("Invalid Email!")
      .required("Email is required!"),
    mobileNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "phone number  should be at least 10 numbers!")
      .max(16, "phone number  should be at most 16 numbers!"),
  });

  const {
    register: register,
    handleSubmit: handleSubmit,
    formState: { errors: errors1 },
    control,
  } = useForm({
    resolver: yupResolver(schema2),
  });
  const schema = yup.object().shape({
    mobileNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "phone number  should be at least 10 numbers!")
      .max(16, "phone number  should be at most 16 numbers!"),
    // rememberMe: yup.bool(),
  });
  const {
    register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control1,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const { email, firstName, lastName, mobileNumber } = data;
    const obj = {
      email,
      firstName,
      lastName,
      mobileNumber: "+" + mobileNumber,
    };
    setMobile("+" + mobileNumber);
    try {
      registerApi(obj)
        .then((data) => {
          MySwal.fire({
            icon: "success",
            title:language=="en"? "Register Successfully":"تم التسجيل بنجاح",
            showConfirmButton: false,
            timer: 1500,
            width:260,
               customClass:{
                container: 'containerSwl',
                title:"titleSwl"
              },
          }).then((res) => {
            // navigate("/");
            navigate("/login")
          });
          // setShowOtp(true);
          // MySwal.fire(
          //   {

          //   icon: 'success',
          //   title: "Register Successfully",
          //   showConfirmButton: false,
          //   timer: 1500
          //  }
          // ).then(res=>{

          //   navigate("/")
          // }).catch(err=> MySwal.fire({
          //   icon: 'error',
          //   title: "Something went wrong",
          //   showConfirmButton: false,
          //   timer: 1500
          // }))
        })
        .catch((error) => {
          MySwal.fire({
            icon: "error",
            title: `${error.response.data.messageEn}`,
            showConfirmButton: false,
            timer: 1500,
            width:260,
               customClass:{
                container: 'containerSwl',
                title:"titleSwl"
              },
          });
        });
    } catch (err) {
      console.log(err);
      MySwal.fire({
        icon: "error",
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 1500,
        width:260,
               customClass:{
                container: 'containerSwl',
              },
      });
    }
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const resendOtpFunc = async () => {
    try {
      reSendOtpApi({ mobileNumber: mobile });
      setResendOtp(false);
      setShowOtp(true);
      setOtp("");
    } catch (error) {
      setResendOtp(true);
    } finally {
      setLoading(false);
    }
  };

  const sendCodeFunc = async () => {
    const obj = {
      mobileNumber: mobile,
      code: otp,
    };
    try {
      sendOtpApi(obj)
        .then((response) => {
          localStorage.setItem("token", response.data.data.token);
          MySwal.fire({
            icon: "success",
            title: "Register Successfully",
            showConfirmButton: false,
            timer: 1500,
            width:260,
               customClass:{
                container: 'containerSwl',
              },
          }).then((res) => {
            // navigate("/");
            window.history.back()
            setShowOtp(false);
          });
          // .catch((err) =>
          //   MySwal.fire({
          //     icon: "error",
          //     title: "Something went wrong",
          //     showConfirmButton: false,
          //     timer: 1500,
          //   })
          // );
        })
        .catch((error) => {
          console.log(error);
          MySwal.fire({
            icon: "error",
            title: `${error.response.data.messageEn}`,
            showConfirmButton: false,
            timer: 1500,
            width:260,
               customClass:{
                container: 'containerSwl',
              },
          });
        });
    } catch (err) {
      console.log(err);
      MySwal.fire({
        icon: "error",
        title: `${err.response.data.messageEn}`,
        showConfirmButton: false,
        timer: 1500,
        width:260,
               customClass:{
                container: 'containerSwl',
              },
      });
      setResendOtp(true);
    }
   
  };
  const otpChangeHandler = (e) => {
    console.log("otp", e);
    setOtp(e);
  };
  const addPhoneNumber = (phone) => {
    console.log(phone);
    setMobile(phone);
    console.log(register(phone));
  };

  return (
    <>
      <div
        style={{ width: "100%", height: "100vh", backgroundColor: "#F5F5F5" }}
      >
        <div
          style={{
            width: "90%",
            height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              textAlign: "center",
              position: "relative",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "45px",
                height: "45px",
                padding: "0.25em",
                border: "none",
                outline: "none",
                display: "flex",
                // position:"absolute",
                backgroundColor: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                boxShadow: "0 0 4px lightgray",
                color:"rgba(72, 160, 219, 1)"
              }}
              onClick={() => window.history.back()}
            >
              <FaChevronLeft />
            </button>
            {showOtp ? (
              <p
                style={{ fontSize: "26px", fontWeight: "700", margin: "auto" }}
              >
               {language=="en"?"VERIFY CODE":"تحقق من الرمز"}
              </p>
            ) : (
              <p
                style={{ fontSize:width>430? "26px" :"22px", fontWeight: "700", margin: "auto" }}
              >
                {language=="en"?"CREATE AN ACCOUNT":"إنشاء حساب"}
              </p>
            )}
          </div>
          {!showOtp ? (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="text"
                  {...register("firstName")}
                  style={{
                    width: "100%",
                    margin: "3em auto 1em",
                    boxSizing: "border-box",
                    padding: "1.2em 1em",
                    textIndent: "1em",
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    boxShadow: "0 0 4px lightgray",
                    direction: language=="en"?"ltr":"rtl"
                  }}
                  placeholder={language=="en"?"First Name":"الاسم الأول"}
                />
                <p style={{ color: "red" }}>{errors1.firstName?.message}</p>

                <input
                  type="text"
                  {...register("lastName")}
                  style={{
                    width: "100%",
                    margin: "1em auto",
                    boxSizing: "border-box",
                    padding: "1.2em 1em",
                    textIndent: "1em",
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    boxShadow: "0 0 4px lightgray",
                    direction: language=="en"?"ltr":"rtl"
                  }}
                  placeholder={language=="en"?"Last Name":"الاسم الأخير"}
                />
                <p style={{ color: "red" }}>{errors1.lastName?.message}</p>
                <input
                  type="email"
                  {...register("email")}
                  style={{
                    width: "100%",
                    margin: "1em auto",
                    boxSizing: "border-box",
                    padding: "1.2em 1em",
                    textIndent: "1em",
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    boxShadow: "0 0 4px lightgray",
                    direction: language=="en"?"ltr":"rtl"
                  }}
                  placeholder={language=="en"?"Email Address":"البريد الالكترونى"}
                />
                <p style={{ color: "red" }}>{errors1.email?.message}</p>
                <Controller
                  name="mobileNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      containerStyle={{
                        margin: "1em auto",
                      }}
                      {...field}
                      // inputProps={{
                      //     name: 'mobileNumber',

                      //    }}
                      enableSearch
                      enableClickOutside
                      enableTerritories
                      country={"sa"}
                      value={mobile}
                      inputStyle={{
                        width: "100%",
                        margin: "3em auto",
                        boxSizing: "border-box",
                        paddingTop: "1.8em ",
                        paddingBottom: "1.8em ",
                        // paddingLeft:"2.5em",
                        textIndent: "1em",
                        border: "none",
                        outline: "none",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        boxShadow: "0 0 4px lightgray",
                      }}
                      // onChange={phone =>addPhoneNumber(phone)}
                      // {...register("mobileNumber")}
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errors1.mobileNumber?.message}</p>
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    marginTop: "3em",
                    padding: "0.8em 1em",
                    border: "none",
                    outline: "none",
                    display: "flex",
                    backgroundColor: "#F6A94A",
                    color: "white",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    boxShadow: "0 0 4px lightgray",
                    fontSize:"18px"
                  }}
                >
                    {language=="en"?"Create Your Account":"أنشىء حسابك"}
                </button>
              </form>
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "2em auto",
                  fontSize: "14px",
                  fontWeight:"400",
                  display:"flex",
                  justifyContent: "center",
                   alignItems: "center",
                }}
              >
                {language=="en"?
                <>
                Already Have An Account?{" "}
                <button
                  style={{
                    border: "none",
                    fontSize: "16px",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    fontWeight:"600",
                    marginLeft:"5px",
                    color:"#000"
                  }}
                  onClick={() => navigate("/login")}
                >
                  Login
                </button></>:
                <>
                <button
                  style={{
                    border: "none",
                    fontSize: "16px",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    fontWeight:"600",
                    marginLeft:"5px",
                    color:"#000",
                    marginRight:"0.5em"
                  }}
                  onClick={() => navigate("/login")}
                >
                  تسجيل الدخول
                </button>
                لديك حساب بالفعل ؟ 
                </> }
              </p>
            </>
          ) : (
            <>
              <OtpInput
                inputType="number"
                value={otp}
                onChange={otpChangeHandler}
                numInputs={6}
                renderSeparator={<span style={{ width: "8px" }}></span>}
                renderInput={(props) => <input {...props} />}
                // isInputNum={true}
                shouldAutoFocus={true}
                // className={styles.otpInput}
                containerStyle={{
                  justifyContent: "center",
                  marginTop: "4em",
                }}
                inputStyle={{
                  border: "1px solid #F6A94A",
                  borderRadius: "8px",
                  width: width > 400 ? "10%" : "100%",
                  height: "35px",
                  fontSize: "16px",
                  color: "black",
                  fontWeight: "400",
                  caretColor: "blue",
                  //direction:"ltr !important",
                  margin: "30px 12px 0 12px",
                }}
                focusStyle={{
                  border: "1px solid #F6A94A ",
                  outline: "none",
                }}
              />
              <button
                type="button"
                onClick={sendCodeFunc}
                disabled={otp.length == 6 ? false : true}
                style={{
                  width: "100%",
                  marginTop: "6em",
                  padding: "1.2em 1em",
                  border: "none",
                  outline: "none",
                  display: "flex",
                  backgroundColor: "#F6A94A",
                  color: "white",
                  borderRadius: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  boxShadow: "0 0 4px lightgray",
                  opacity: otp.length == 6 ? "1" : "0.3",
                }}
              >
                 {language=="en"?"Submit":"تأكيد"}
              </button>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "3em auto ",
                  textAlign: "center",
                }}
              > {language=="en"?
              <>
                 <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    display: "block",
                    margin: " auto auto  auto 0px",
                    color: "#F6A94A",
                    cursor: "pointer",
                    marginRight:"0.5em"
                  }}
                  onClick={resendOtpFunc}
                >
                  إعادة إرسال الرمز
                </button>
                <p
                  style={{
                    textAlign: "center",
                    margin: "auto 10px  auto auto",
                  }}
                >
                  
                 لم يصلك رمز ؟
                </p>
                </>:<></>}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
