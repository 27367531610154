import React, { useEffect, useState } from "react";
import "../Restaurants/Restaurants.css";
import { Segmented, Skeleton, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { BiLogOut, BiSearchAlt2 } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai"
import { Card } from "antd";
import { Url, allStoresApi, getPicksOfWeekApi, getStoresCatApi } from "../../Apis";
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin, Badge } from 'antd';
import { FiShoppingCart } from "react-icons/fi";
import { HiMenuAlt2 } from "react-icons/hi";
import Swal from "sweetalert2";
import { Empty } from 'antd';
import Header from "../../components/Header";
import { FaChevronLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
const { Meta } = Card;
export const PicksOfTheWeek = () => {
  const navigate = useNavigate()
  const [PicksOfWeek, setPicksOfWeek] = useState([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [stores, setStores] = useState([])
  const [storeFilter, setStoreFilter] = useState([])
  const [picksFilter, setPicksFilter] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [storeData, setStore] = useState([])
  const [storesCat, setStoresCat] = useState([])
  const [activeCategory, setActiveCategory] = useState([])
  const [picksOfWeekFilter, setPicksOfWeekFilter] = useState([])
  const { language } = useSelector(state => state)
  const getAllPicksOfTheWeek = () => {
    setLoading(true)
    getPicksOfWeekApi().then(res => {
      console.log(res?.data?.data);
      setPicksOfWeek(res?.data?.data);
      setPicksFilter(res?.data?.data)
      setPicksOfWeekFilter(res?.data?.data)
      // setLoading(false)

    })
  }
  const getStoresCat = () => {
    getStoresCatApi().then(res => {
      console.log(res.data.data);
      setStoresCat(res.data.data)
    })
  }

  let Arr = [];
  const handleActiveCategory = (catId) => {
    console.log(catId);
    // const element = document.getElementById(catId.toString());
    // console.log(element);
    // setActive(catId)
    // if(element) {
    //   element.scrollIntoView({behavior:"smooth",block:"nearest",inline:"start"});
    // }
    // console.log(catId);

    if (!activeCategory.includes(catId) && !Arr.includes(catId)) {
      console.log("Arr", Arr);
      setLoading(true)
      Arr.push(catId)
      setActiveCategory(prev => [...prev, catId])
      setLoading(false)


    } else {
      setLoading(true)
      setActiveCategory(prev => [...prev.filter(id => id !== catId)])
      setLoading(false)
    }
  }
  useEffect(() => {
    if (activeCategory.length > 0) {
      setLoading(true)
      console.log(stores.filter(stores => activeCategory.includes(stores.categories?._id)));
      // setStoresFilter(stores.filter(stores =>activeCategory.includes(stores.categories?._id)))
      setLoading(false)
      setPicksOfWeekFilter(PicksOfWeek.filter(pick => activeCategory.includes(pick.storeCategory)))

    } else {
      // setStoresFilter(stores)
      setPicksOfWeekFilter(PicksOfWeek)
    }
  }, [activeCategory])
  const getAllStores = () => {
    setLoading(true)
    allStoresApi().then(res => {
      console.log(res?.data?.data?.filter(data => data.isDeleted == false));
      setStores(res?.data?.data?.filter(data => data.isDeleted == false));
      setStoreFilter(res?.data?.data?.filter(data => data.isDeleted == false))
      setLoading(false)

    })
  }
  useEffect(() => {
    allStoresApi().then(resStore => {
      console.log(resStore.data?.data?.filter(data => data.isDeleted == false));
      setStore(resStore.data?.data?.filter(data => data.isDeleted == false))
      setLoading(false)
    })
  }, [PicksOfWeek])
  const token = localStorage.getItem('token');
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const handlelogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#F6A94A',
      confirmButtonText: 'ok'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token")
        navigate("/login")
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    })
  }
  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  }
  useEffect(() => {
    if (searchValue?.length > 0) {
      console.log(PicksOfWeek.filter(picks => picks?.englishName?.includes(searchValue)));
      setPicksFilter(PicksOfWeek.filter(picks => language == "en" ? picks?.englishName?.toLowerCase()?.includes(searchValue.toLowerCase()) : picks?.arabicName?.toLowerCase()?.includes(searchValue.toLowerCase())))

    } else {
      setPicksFilter(PicksOfWeek)
    }
  }, [searchValue])

  useEffect(() => {
    getAllPicksOfTheWeek()
    getStoresCat()
    // if(token){

    // }
  }, [])
  return (
    <>
      <div
        style={{ width: "100%", backgroundColor: "#F5F5F5", minHeight: "100vh" }}
      >
        <div
          style={{
            width: "90%",
            height: "100%",
            margin: "auto",
            padding: "1em 0em 2em",
            boxSizing: "border-box",
          }}
        >
          <button
            style={{
              width: "45px",
              height: "45px",
              padding: "0.25em",
              border: "none",
              outline: "none",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "0 0 4px lightgray",
              color: "rgba(72, 160, 219, 1)",
              marginBottom: "1em"
            }}
            onClick={() => window.history.back()}
          >
            <FaChevronLeft />
          </button>
          <Header toggleOrderType={true} />

          <div
            style={{ display: "flex", position: "relative", marginTop: ".5em" }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            <BiSearchAlt2
              style={{
                position: "absolute",
                top: "42px",
                left: language == "en" ? "10px" : "auto",
                right: language == "en" ? "auto" : "10px",
                fontSize: "20px",
              }}
            />
            <input
              type="text"
              value={searchValue}
              onChange={(e) => handleSearch(e)}
              style={{
                width: "100%",
                margin: "2em auto",
                boxSizing: "border-box",
                padding: "1.2em 1em",
                textIndent: "25px",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0 0 4px lightgray"
              }}
              placeholder={language == "en" ? "Search For Products" : "قم بالبحث عن المنتجات"}
            />
            {searchValue.length > 0 &&

              <div style={{ width: "100%", position: "absolute", background: "#eee", maxHeight: "400px", boxShadow: "0 0px 8px #00000075", overflow: "auto", borderRadius: "12px", marginTop: "80px", zIndex: "999", transition: "all ease 1s" }}>
                <ul style={{ listStyle: "none" }} dir={language == "en" ? "ltr" : "rtl"}>
                  {picksFilter.length == 0 ?
                    <Empty style={{ margin: "80px 0" }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    : picksFilter?.map(picks => (
                      <>
                        <li style={{ padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10 }}>
                          <img src={Url + picks?.productImage} width={60} height={60} style={{ borderRadius: "10px" }} alt="img" />

                          <h4 style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "auto" }}>{language == "en" ? picks?.englishName : picks?.arabicName}</h4>
                          <button style={{ color: "#fff", background: "#48A0DB", padding: "8px 10px", border: "none", borderRadius: "10px", cursor: "pointer", whiteSpace: "nowrap" }} onClick={() => navigate(`/${storeData && storeData?.filter(stores => stores?._id == picks?.store._id)[0]?.name?.replaceAll(/\s+/g, '-')}/${picks?.englishName?.replaceAll(/\s+/g, '-')}`)}>{language == "en" ? "Chose Product" : "اختر منتج"}</button>
                        </li>

                      </>
                    ))}
                </ul>

              </div>
            }
          </div>
          <div
            className="btns-div"
            style={{
              width: "100%",
              margin: "0em auto",
              boxSizing: "border-box",
              padding: "0em 1em",
              border: "none",
              outline: "none",
              borderRadius: "10px",
              overflow: "auto",
              display: "flex",
              whiteSpace: "nowrap",
              scrollbarWidth: "none",
              scrollbarColor: "transparent transparent",
              msOverflowStyle: "none",
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            {loading ? [1, 2, 3, 4, 5].map(() => (
              <Space >
                <Skeleton.Button style={{ margin: "1.5em 0.5em" }} active={true} size={"large"} shape={"round"} block={false} />
              </Space>
            ))

              : storesCat.map((data) => (
                <button
                  id={data?.store}
                  key={data?._id}
                  // className={active == data?._id?"active":""}
                  onClick={() => handleActiveCategory(data?._id)}
                  style={{
                    border: "1px solid #48A0DB",
                    borderRadius: "50px",
                    margin: "1em 0.5em",
                    padding: ".6em 2em",
                    display: "flex",
                    alignItems: "center",
                    zIndex: "99",
                    outline: "none",
                    cursor: "pointer",
                    position: "relative",
                    background: activeCategory.includes(data?._id) ? "#48A0DB" : "#f5f5f5",
                    color: activeCategory.includes(data?._id) ? "#fff" : "#000"
                  }}
                  dir={language == "en" ? "ltr" : "rtl"}
                >
                  {/* <img width={20} height={20} style={{borderRadius:"15px",marginRight:"10px",marginLeft:"-10px",boxShadow:"0 0 4px"}} src={Url + data?.image} alt="img" /> */}
                  {language == "en" ? data?.englishName : data?.arabicName}
                  {activeCategory.includes(data?._id) &&
                    <CloseOutlined style={{ right: 7, color: "#fff", position: "absolute", lineHeight: "0" }} />

                  }
                </button>
              ))}
          </div>
          <div
            className="cards-div"
            style={{
              width: "100%",
              // height:"800px",
              //  overflowY:"scroll"

              marginTop: "2em"
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            {loading ? <>{[1, 2, 3, 4].map((d, index) => (
              <>
                <div
                  style={{ width: "100%", borderRadius: "15px", margin: "1.5em 0em 0em", boxShadow: "0 0 8px lightgray" }}
                  dir={language == "en" ? "ltr" : "rtl"}
                >
                  {/* <Space > */}

                  <Space
                    direction="vertical"
                    size="small"
                    style={{
                    }}>
                    <Space>
                      <Skeleton.Image style={{ maxWidth: "460px", width: "90vw", height: "25vh" }} active={true} size={"large"} shape={"round"} />

                    </Space>
                    <br />
                    <Space direction="vertical" style={{
                      width: "40%",
                      padding: "0 0.5em"
                    }}>
                      <Skeleton.Button style={{ height: 15 }} active={true} size={"small"} shape={"round"} block={true} />
                      <Space size={"large"} direction="horizontal" >
                        {/* <Skeleton.Input style={{height:15}} active={true} size={"small"} shape={"round"} block={true} /> */}
                        <Skeleton.Input style={{ height: 15 }} active={true} size={"small"} shape={"round"} block={true} />

                      </Space>
                    </Space>
                  </Space>
                  <br />
                  <br />



                  {/* </Space> */}

                </div>

              </>
            ))}

            </> : picksOfWeekFilter.length == 0 ? <><Empty style={{ margin: "100px auto" }} image={Empty.PRESENTED_IMAGE_SIMPLE} /></> : picksOfWeekFilter?.map((store) => (
              //     <Card
              //     hoverable
              //     style={{
              //       width: "100%",
              //       marginTop:"1em"
              //     }}
              //     cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
              //   >
              //     <Meta title="Europe Street beat" description="www.instagram.com" />
              //   </Card>
              <div
                style={{ width: "100%", borderRadius: "15px", margin: "1em 0em", boxShadow: "0 0 8px lightgray", cursor: "pointer" }}
                dir={language == "en" ? "ltr" : "rtl"}
                onClick={() => navigate(`/${storeData && storeData?.filter(stores => stores?._id == store?.store._id)[0]?.name?.replaceAll(/\s+/g, '-')}/${store?.englishName?.replaceAll(/\s+/g, '-')}`)}
              >
                <div
                  style={{
                    width: "100%",
                    height: "200px",
                    backgroundImage: `url(${Url + store?.productImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                    position: "relative"
                  }}
                >

                  {/* <div
                      style={{
                        padding:"0.15em 1em",
                        backgroundColor:"#F6A94A",
                        borderRadius:"5px",
                        position:"absolute",
                        bottom:"10px",
                        right:"10px",
                        color:"white"
                      }}
                      >
                        10-15 <br/>
                        mins

                      </div> */}

                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "0.5em 1em",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px"
                  }}
                >
                  <div style={{ padding: "0.5em", fontWeight: "bold", fontSize: "18px" }}>
                    {language == "en" ? store?.englishName : store?.arabicName}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", padding: "0 0.5em  0.6em" }}>
                    <p style={{ color: "gray", fontSize: "14px" }}>{storeData && language == "en" ? storeData?.filter(data => data?._id == store?.store._id)[0]?.name : storeData?.filter(data => data?._id == store?.store._id)[0]?.nameArabic}</p>
                    <p style={{ color: "rgba(0, 163, 224, 1)", fontSize: "18px" }}>{language === "en" ? 'SAR' + " " + store?.salePrice : store?.salePrice + " " + "س.ر"}</p>

                    {/* <p style={{color:"#F6A94A"}}><span style={{marginRight:"10px",padding:"0.25em",backgroundColor:"lightgray",display:"flex",display:"inline-block",justifyContent:"center",alignItems:"center", borderRadius:"5px"}}><AiFillStar style={{color:"#F6A94A"}}/>4.5</span>20% off</p>    */}
                  </div>

                </div>

              </div>
            ))
            }

          </div>
        </div>
      </div>
    </>
  );
};

