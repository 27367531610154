import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { loginApi, reSendOtpApi, registerApi, sendOtpApi } from "../../Apis";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import "./Login.css";


export const Login = () => {
  const {language}= useSelector(state => state)
  const MySwal = withReactContent(Swal);
  const [showOtp, setShowOtp] = useState();
  const [otp, setOtp] = useState("");
  const [mobile, setMobile] = useState("");
  const [resendOtp, setResendOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

  const token = localStorage.getItem("token");
  let route = localStorage.getItem("historyRouter");
  useEffect(() => {
    if (token) {
      navigate(route);
    }
  }, []);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const schema = yup.object().shape({
    mobileNumber: yup
      .string()
      .required("Phone number is required")
      .min(10, "phone number  should be at least 10 numbers!")
      .max(16, "phone number  should be at most 16 numbers!"),
    // rememberMe: yup.bool(),
  });

  const {
    register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control1,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit2 = (data) => {
    try {
      const { mobileNumber } = data;
      const obj = {
        mobileNumber: "+" + mobileNumber,
      };
      setMobile("+" + mobileNumber);
      loginApi(obj)
        .then((res) => {
          // MySwal.fire({
          //   icon: 'success',
          //   title: `${res.data.data}`,
          //   showConfirmButton: true,
          //   timer: 60000
          // })
          setShowOtp(true);
        })
        .catch((error) => {
          console.log(error);
          MySwal.fire({
            icon: "error",
            title: `${error.response.data.messageEn}`,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } catch (err) {
      console.log(err);
      MySwal.fire({
        icon: "error",
        title: `${err.message}`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const resendOtpFunc = async () => {
    try {
      reSendOtpApi({ mobileNumber: mobile });
      setResendOtp(false);
      setShowOtp(true);
      setOtp("");
    } catch (error) {
      setResendOtp(true);
    } finally {
      setLoading(false);
    }
  };
  const sendCodeFunc = async () => {
    const obj = {
      mobileNumber: mobile,
      code: otp,
    };
    try {
      sendOtpApi(obj)
        .then((response) => {
          if (response.data.data.token) {
            localStorage.setItem("token", response.data.data.token);
          }
          MySwal.fire({
            icon: "success",
            title:language == "en"? "Login Successfully":"تم تسجيل الدخول بنجاح",
            showConfirmButton: false,
            timer: 1500,
            width: 260,
            padding: "20px 0",
            customClass: {
              container: "containerSwl",
              title: "titleSwl ",
              htmlContainer: "htmlcontainer",
            },
          })
            .then((res) => {
              let route = localStorage.getItem("historyRouter");
              navigate(route ? route : "/");
              setShowOtp(false);
              window.location.reload()
            })
            .catch((err) =>
              MySwal.fire({
                icon: "error",
                title: "Something went wrong",
                showConfirmButton: false,
                timer: 1500,
                width: 260,
                customClass: {
                  container: "containerSwl",
                  title: "titleSwl ",
                  htmlContainer: "htmlcontainer",
                },
              })
            );
        })
        .catch((error) => {
          MySwal.fire({
            icon: "error",
            title: `${error.response.data.messageEn}`,
            showConfirmButton: false,
            timer: 1500,
            width: 260,
            customClass: {
              container: "containerSwl",
              title: "titleSwl ",
              htmlContainer: "htmlcontainer",
            },
          });
        });
    } catch (error) {
      setResendOtp(true);
    }
  };
  const otpChangeHandler = (e) => {
    setOtp(e);
  };

  return (
    <>
      <div
        style={{ width: "100%", height: "100vh", backgroundColor: "#F5F5F5" }}
      >
        <div
          style={{
            width: "90%",
            height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "grid",
              textAlign: "center",
              position: "relative",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "45px",
                height: "45px",
                padding: "0.25em",
                border: "none",
                outline: "none",
                display: "flex",
                position: "absolute",
                backgroundColor: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                boxShadow: "0 0 4px lightgray",
                color: "rgba(72, 160, 219, 1)",
              }}
              onClick={() => window.history.back()}
            >
              <FaChevronLeft />
            </button>
            {showOtp ? (
              <>
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "700",
                    // marginTop: "2em",
                  }}
                >
                 {language=="en"?"VERIFY CODE":"تحقق من الرمز"}
                </p>
              </>
            ) : (
              <>
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "700",
                    // marginTop: "2em",
                  }}
                >
                  {language=="en"?"LOGIN":"تسجيل الدخول"}
                </p>
              </>
            )}
          </div>
          {!showOtp ? (
            <>
              <form
                onSubmit={handleSubmit2(onSubmit2)}
                style={{ marginTop: "2em" }}
              >
                {/* <input
                      type="number"
                      
                      
                      style={{
                        width: "100%",
                        margin: "2em auto",
                        boxSizing: "border-box",
                        padding: "1.2em 1em",
                        textIndent: "1em",
                        border: "none",
                        outline: "none",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        boxShadow:"0 0 4px lightgray"
                      }}
                      placeholder="Phone Number"
                    /> */}
                <Controller
                  name="mobileNumber"
                  control={control1}
                  rules={{
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      onChange={onChange}
                      value={value}
                      id="mobileNumber"
                      containerStyle={{
                        margin: "1em auto",
                      }}
                      // {...field}

                      // inputProps={{
                      //     name: 'mobileNumber',

                      //    }}

                      enableSearch
                      enableClickOutside
                      enableTerritories
                      country={"sa"}
                      inputStyle={{
                        width: "100%",
                        margin: "3em auto",
                        boxSizing: "border-box",
                        paddingTop: "1.8em ",
                        paddingBottom: "1.8em ",
                        // paddingLeft:"2.5em",
                        textIndent: "1em",
                        border: "none",
                        outline: "none",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        boxShadow: "0 0 4px lightgray",
                      }}

                      // onChange={phone =>addPhoneNumber(phone)}
                      // {...register("mobileNumber")}
                    />
                  )}
                />

                <p style={{ color: "red" }}>{errors2.mobileNumber?.message}</p>

                <button
                  type="submit"
                  style={{
                    width: "100%",
                    marginTop: "2em",
                    padding: "0.8em 1em",
                    border: "none",
                    outline: "none",
                    display: "flex",
                    backgroundColor: "#F6A94A",
                    color: "white",
                    borderRadius: "10px",
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 0 4px lightgray",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                   {language=="en"?"Login":"تسجيل الدخول"}
                </button>
              </form>
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "2em auto",
                  fontSize: "15px",
                  fontWeight: "400",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                 {language=="en"?
                 <>
                Don't Have An Account?
                <button
                  style={{
                    border: "none",
                    fontSize: "16px",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    fontWeight: "600",
                    marginLeft: "5px",
                    color: "#000",
                  }}
                  onClick={() => navigate("/register")}
                >
                  Register Now
                </button>
                </>:
                <>
                <button
                style={{
                  border: "none",
                  fontSize: "16px",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  fontWeight: "600",
                  marginLeft: "5px",
                  color: "#000",
                  marginRight:"0.5em"
                }}
                onClick={() => navigate("/register")}
              >
               إنشاء حساب الآن
              </button>
                ليس لديك حساب ؟
              </>
                }
              </p>
            </>
          ) : (
            <>
              {/* <p
                style={{
                  fontSize: "26px",
                  fontWeight: "700",
                  marginTop: "2em",
                }}
              >
                OTP
              </p> */}
              {/* <div className="otpDiv"> */}
              <OtpInput
                inputType="number"
                value={otp}
                onChange={otpChangeHandler}
                numInputs={6}
                renderSeparator={<span style={{ width: "8px" }}></span>}
                renderInput={(props) => <input {...props} />}
                // isInputNum={true}
                shouldAutoFocus={true}
                // className={styles.otpInput}
                containerStyle={{
                  justifyContent: "center",
                  marginTop: "4em",
                }}
                inputStyle={{
                  border: "1px solid #F6A94A",
                  borderRadius: "8px",
                  width: width > 400 ? "10%" : "100%",
                  height: "35px",
                  fontSize: "16px",
                  color: "black",
                  fontWeight: "400",
                  caretColor: "blue",
                  //direction:"ltr !important",
                  margin: "30px 12px 0 12px",
                }}
                focusStyle={{
                  border: "1px solid #F6A94A ",
                  outline: "none",
                }}
              />
              <button
                type="button"
                disabled={otp.length == 6 ? false : true}
                onClick={sendCodeFunc}
                style={{
                  width: "100%",
                  marginTop: "6em",
                  padding: "0.8em 1em",
                  border: "none",
                  outline: "none",
                  display: "flex",
                  backgroundColor: "#F6A94A",
                  color: "white",
                  borderRadius: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  fontSize: "18px",
                  boxShadow: "0 0 4px lightgray",
                  opacity: otp.length == 6 ? "1" : "0.3",
                }}
              >
               {language=="en"?"Submit":"تأكيد"}
              </button>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:"center",
                  margin: "3em auto ",
                  textAlign: "center",
                }}
              > {language=="en"?
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent:"center",
                
                textAlign: "center",
              }}>
                <p
                  style={{
                    textAlign: "center",
                    margin: "auto 10px  auto auto",
                  }}
                >
                  
                  Didn't recieve code ?
                </p>
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    display: "block",
                    margin: " auto auto  auto 0px",
                    color: "#F6A94A",
                    cursor: "pointer",
                  }}
                  onClick={resendOtpFunc}
                >
                  Resend OTP
                </button></div>:<div  style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:"center",
                  
                  textAlign: "center",
                }}>
                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    display: "block",
                    margin: " auto auto  auto 0px",
                    color: "#F6A94A",
                    cursor: "pointer",
                    marginRight:"0.5em"
                  }}
                  onClick={resendOtpFunc}
                >
                  إعادة إرسال الرمز
                </button>
                <p
                  style={{
                    textAlign: "center",
                    margin: "auto 10px  auto auto",
                  }}
                >
                  
                 لم يصلك رمز ؟
                </p>
               
                </div> }
              </div>

              {/* </div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};
