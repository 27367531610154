import React from "react";
import "./pay.css";
import { useSelector } from "react-redux";

function PayFail() {
  const { language } = useSelector((state) => state);
  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "#fff",
          position: "absolute",
          minHeight: "100%",
        }}
      >
        <div
          style={{
            margin: "150px auto",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 10,
            justifyContent: "center",
            justifySelf: "center",
            borderRadius: "15px",
            boxShadow: "0 0 6px gray",
            padding: "1em",
            width: "90%",
            backgroundColor: "#F5F5F5",
          }}
        >
          <img
            src="/error.gif"
            style={{
              width: "130px",
              height: "100px",
              margin: "auto",
              borderRadius: "20px",
            }}
          />
          <p
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: "black",
              marginTop: "2em",
              margin: "1em auto",
            }}
          >
            {language=="en"?"Failed To create Order!":"فشلت في عمل الطلب"}
          </p>

          <button
            style={{
              padding: "0.7em",
              marginTop: "2.5em",
             
              borderRadius: "10px",
              margin: "1em auto",
              cursor: "pointer",
              width: "80%",
            }}
            className="gobackBtn"
            onClick={() => window.history.back()}
          >
           {language=="en"?"go back":"الرجوع"}
          </button>
        </div>
      </div>
    </>
  );
}

export default PayFail;
