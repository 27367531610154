import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { BsInfoCircleFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { Url, allAddressesApi, allCategoriesApi, allProductsApi, allStoresApi } from "../../Apis";
import { Skeleton, Space, Spin, message } from "antd";
import { Checkbox, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Image } from 'antd';
import './Item.css'
import { useContext } from "react";
import { appContext } from "../../Context/appContext";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/en-ca";
import "moment-timezone";
export const Item = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  // const [loadingBtn , setLoadingBtn] = useState(false)
  const [store, setStore] = useState([])
  const [product, setProduct] = useState({})
  const [categories, setCategories] = useState([])
  const [quantity, setQuantity] = useState(1);
  const [notes, setNotes] = useState("")
  const token = localStorage.getItem('token');
  const { storeName, itemName } = useParams()
  const [options, setOptions] = useState([])
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const storeData = useSelector(state => state.theStore)
  const [showStoreClosed, setShowStoreClosed] = useState(false);
  const { addToCart, loadingBtn, cartItems } = useContext(appContext)
  const [api, contextHolder] = notification.useNotification();
  const { language } = useSelector(state => state)
  const [width, setWidth] = useState(window.innerWidth);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
        color: "#fff"
      }}
      spin
    />
  );
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const getOneProduct = (id) => {
    let Addons = []
    setLoading(true)
    if (id) {
      allProductsApi(id).then(res => {
        let product = res.data?.data?.filter(prod => prod?.englishName?.replace(/\s+/g, '-') == itemName)[0]
        setProduct(product)
        product.addons?.map(addon => {
          let oneaddon = {
            label: language == "en"
              ? `${addon.name} - SAR ${addon.price}`
              : `${addon.nameArabic !== undefined
                ? addon.nameArabic
                : addon.name
              } - ${addon.price} س.ر `, value: addon._id
          }
          if (!Addons.includes(oneaddon)) {
            Addons.push(oneaddon)

          }
        })
        setOptions([...Addons])
        setLoading(false)
      }).catch(err => {
        console.log(err);
        setLoading(true)
      })

    }
  }
  //   const getAllCategories = (id) =>{
  //     setLoading(true)
  //     console.log(id);
  //     console.log(store);
  //     if(id){
  //       allCategoriesApi(id).then(res => {
  //        console.log(res?.data?.data);
  //        setCategories(res?.data?.data)
  //        setLoading(false)
  //       }).catch(err => {
  //         console.log(err);
  //         setLoading(true)
  //       })
  //   }
  // }

  const handleAddNote = (e) => {
    setNotes(e.target.value)
  }
  const handleAddAddon = (e) => {
    // console.log(e.target.checked);
    setSelectedAddOns([...e])
  }

  const handleIncrease = () => { setQuantity(prev => prev + 1) }
  const handleDecrease = () => { setQuantity(prev => prev > 1 ? prev - 1 : prev) }
  const getOneStore = async () => {
    allStoresApi().then(res => {
      let data = res?.data?.data?.filter(data => data.name?.replace(/\s+/g, '-') == storeName)
      getOneProduct(data[0]?._id)

      // getAllCategories(data?._id)
      setStore(data)
    })
  }
  // const getAllAddresses = () =>{
  //   allAddressesApi().then((res) =>{
  //     console.log(res?.data?.data);
  //     let allAddress = []
  //     res?.data?.data?.map(address =>{
  //       let oneAddress = {label:address?.city + " , " + address?.area + " , " + address?.street + " , " + address?.apartment + " , " + address?.buildingNo  + " , " + address?.floor, value:address?._id}
  //       allAddress.push(oneAddress)
  //       // setOptions(prev => [...prev,oneAddress])
  //     })
  //     setOptions(allAddress)
  //   })
  // }
  const openNotification = (placement) => {
    api.info({
      // message: `Notification ${placement}`,
      description: <span style={{ color: "white" }}>{store && language == "en"
        ? ` We're closed - our opening hours: ${store[0]?.workingHours[0].openingHours} -> ${store[0]?.workingHours[0].closeingHours}`
        : store &&
        `${store[0]?.workingHours[0].closeingHours} <- ${store[0]?.workingHours[0].openingHours} : لقد أغلقنا - ساعات عملنا`}{" "}</span>,
      placement,
      closeIcon: <></>,
      className: "notification",
      icon: <BsInfoCircleFill style={{ color: "white" }} />,
      style: {
        background: '#48A0DB',
        color: "#fff"
      },
    });
  };

  const addProductToCart = () => {
    if (token) {
      let currentTime = new Date().toLocaleString("en-US", {
        // timeZone: "Africa/Cairo",
        timeZone: "Asia/Riyadh",
      });
      // //console.log(moment(store[0].workingHours[0].closeingHours , "h A").format("h A"));
      const open = moment(
        store[0]?.workingHours[0].openingHours,
        "hh:mm A"
      ).hours();
      const close = moment(
        store[0]?.workingHours[0].closeingHours,
        "hh:mm A"
      ).hours();
      //console.log("open", open);
      //console.log("close", close);

      const currentHr = moment(currentTime.split(",")[1], "hh:mm A").subtract(
        1,
        "hour"
      );
      // const openTime = moment(
      //   store[0]?.workingHours[0].openingHours,
      //   "hh:mm A"
      // ).second(0);
      let openTime;
      let closeTime;
      if (
        (open >= close &&
          ((moment(store[0]?.workingHours[0].closeingHours, "hh:mm A").format(
            "A"
          ) === "AM" &&
            moment(store[0]?.workingHours[0].openingHours, "hh:mm A").format(
              "A"
            ) === "AM") ||
            (moment(store[0]?.workingHours[0].closeingHours, "hh:mm A").format(
              "A"
            ) === "PM" &&
              moment(store[0]?.workingHours[0].openingHours, "hh:mm A").format(
                "A"
              ) === "PM"))) ||
        (moment(store[0]?.workingHours[0].openingHours, "hh:mm A").format("A") ===
          "PM" &&
          moment(store[0]?.workingHours[0].closeingHours, "hh:mm A").format(
            "A"
          ) === "AM")
      ) {
        closeTime = moment(store[0]?.workingHours[0].openingHours, "hh:mm A").second(0)

        openTime = moment(store[0]?.workingHours[0].closeingHours, "hh:mm A").second(0)

        const ksaTime = moment().tz("Asia/Riyadh");

        if (ksaTime.isBetween(openTime, closeTime)) {
          openNotification(width > 500 ? "bottomLeft" : "bottom")
          return;
        }
      } else {
        openTime = moment(
          store[0]?.workingHours[0].openingHours,
          "hh:mm A"
        ).second(0);
        closeTime = moment(
          store[0]?.workingHours[0].closeingHours,
          "hh:mm A"
        ).second(0);
        const ksaTime = moment().tz("Asia/Riyadh");

        if (!ksaTime.isBetween(openTime, closeTime)) {
          openNotification(width > 500 ? "bottomLeft" : "bottom")
          return;
        }
      }

      const current = moment("11:00 PM", "hh:mm A");
      // const open = moment("10:00 AM","hh:mm A")
      // const close = moment("6:00 PM","hh:mm A")
      const now = moment();
      const start = moment().hour(6).minute(0).second(0);
      const end = moment().hour(22).minute(0).second(0);
      //console.log(moment().subtract(1, "hour").isBetween(openTime, closeTime));
      var startTime = store[0]?.workingHours[0].openingHours;
      var endTime = store[0]?.workingHours[0].closeingHours;

      let currentDate = new Date();

      // let startDate = new Date(currentDate.getTime());
      // startDate.setHours(startTime?.split(":")[0]);
      // startDate.setMinutes(startTime?.split(":")[1]);
      // startDate.setSeconds(startTime.split(":")[2]);

      // let endDate = new Date(currentDate.getTime());
      // endDate.setHours(endTime?.split(":")[0]);
      // endDate.setMinutes(endTime?.split(":")[1]);
      // endDate.setSeconds(endTime.split(":")[2]);

      // const ksaTime = moment().tz("Asia/Riyadh");

      // if (ksaTime.isBetween(openTime, closeTime)) {
      //   openNotification(width>500?"bottomLeft":"bottom")
      //   return;
      // }


      // setLoadingBtn(true)
      addToCart(product?._id, product?.salePrice, notes, selectedAddOns, quantity)
      if (!loadingBtn) {

        // navigate(`/${storeName}/my-cart`)
      }

    } else {
      navigate(`/login`)

    }
  }

  useEffect(() => {
    return () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  }, [])
  useEffect(() => {
    setLoading(true)
    getOneStore()
    // if(token){
    //   // getAllAddresses()

    // }
  }, [])

  function calculateAddonsTotal(products) {
    let total = 0;
    products?.forEach((product) => {
      product?.addons.forEach((addonId) => {
        const addon = product?.addonDetails?.find((a) => a._id === addonId);
        if (addon) {
          total += addon?.price * product.quantity;
        }
      });
    });
    return total;
  }

  const addonsTotal = calculateAddonsTotal(cartItems?.data?.products);
  const cartTotal = cartItems?.data?.products?.reduce((total, cartItem) => {
    return total + cartItem?.price * cartItem?.quantity;
  }, 0);

  const totalItems = cartItems?.data?.products?.reduce((total, cartItem) => {
    return total + cartItem?.quantity;
  }, 0);

  const totalPrice = cartTotal + addonsTotal;
  return (
    <>
      {contextHolder}
      <div style={{ width: "100%", backgroundColor: "#F5F5F5", minHeight: "100vh" }}>
        <div
          style={{
            width: "90%",
            // height: "100%",
            margin: "auto",
            padding: "2em 0em",
            boxSizing: "border-box",
          }}
        >

          <div style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)"
          }}>
            <button
              style={{
                width: "45px",
                height: "45px",
                padding: "0.25em",
                border: "none",
                outline: "none",
                display: "flex",
                backgroundColor: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                boxShadow: "0 0 4px lightgray",
                color: "rgba(72, 160, 219, 1)"
              }}
              onClick={() => navigate(`/${storeName}`)}
            >
              <FaChevronLeft />
            </button>

            <div
              style={{ justifySelf: "baseline" }}
            >
              {loading ?
                <Space >
                  <Skeleton.Image active style={{ maxWidth: "300px", width: "100%", height: "60px", marginTop: "0", borderRadius: "10px", backgroundColor: "white", }} />
                </Space>
                : <img
                  alt="shop_logo"
                  width={"100%"}
                  height={100}
                  className="item-img"
                  style={{
                    height: "65px",
                    marginTop: "0em",
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                  onClick={() => navigate(`/${storeName}`)}
                  src={Url + store[0]?.logo}
                />
              }
            </div>
          </div>

          {loading ? <>
            <Space >
              <Skeleton.Image active style={{ maxWidth: "460px", width: "90Vw", height: "35vh", marginTop: 20, borderRadius: 12 }} />
            </Space>
          </> :
            <Image
              width={"100%"}
              height={275}
              className="item-img"
              style={{
                marginTop: "1em",
                backgroundColor: "white",
                borderRadius: "15px",
              }}
              src={Url + product?.productImage}
            />
            // <div
            //   style={{
            //     width: "100%",
            //     height: "250px",
            //     marginTop: "1em",
            //     backgroundColor: "white",
            //     backgroundImage: `url(${Url+product?.productImage})`,
            //     backgroundSize: "cover",
            //     backgroundRepeat: "no-repeat",
            //     backgroundPosition: "center",
            //     borderRadius: "15px",
            //   }}
            // ></div>
          }

          <div
            style={{
              width: "100%",
              marginTop: "2em",
            }}
            dir={language == "en" ? "ltr" : "rtl"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%", fontWeight: "700", fontSize: "0.9em", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",
                borderRadius: "15px"
              }}
              dir={language == "en" ? "ltr" : "rtl"}
            >
              {loading ? <>
                <Space >
                  <Skeleton.Button active={true} size="small" block shape="round" />
                </Space>
                <Space >
                  <Skeleton.Button active={true} size="small" block shape="round" />
                </Space>
              </> : <>
                <p style={{ width: "60%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "18px", fontWeight: "500" }}>
                  {language == "en" ? product?.englishName : product?.arabicName}
                </p>
                <p style={{ fontSize: 16, fontWeight: 300, fontStyle: "normal" }} className="item-details">{language == "en" ? product?.englishDescription : product?.arabicDescription}</p>

              </>}
            </div>
            <div style={{ marginTop: "1em" }}>
              <p style={{ color: "gray", fontSize: "14px" }}>
                {language == "en" ? product?.productEnglishDescription : product?.productArabicDescription}
              </p>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "2em",
                backgroundColor: "white",
                boxSizing: "border-box",
                padding: "24px",
                borderRadius: "15px",
              }}
            >
              <div
                style={{
                  // display: "grid",

                }}
              >
                <div
                  style={
                    {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",

                    }
                  }
                  dir={language == "en" ? "ltr" : "rtl"}
                >
                  <p
                    // className="updateUserPassLabel"
                    style={{ fontWeight: "500" }}
                  >
                    {language == "en" ? "Add Sides" : "الاضافات"}
                  </p>

                  <button
                    style={{
                      padding: ".25em 1em",
                      height: "24px",
                      width: "100px",
                      border: "none",
                      backgroundColor: "rgba(72, 160, 219, 0.2)",
                      borderRadius: "15px",
                    }}
                  >
                    {language == "en" ? "optional" : "اختياري"}
                  </button>
                </div>
                {loading ? <>
                  <div style={{ display: "grid", gridTemplateRows: "auto", marginTop: 10, width: "100%" }} dir={language == "en" ? "ltr" : "rtl"}>
                    {[1, 2, 3, 4].map((j, index) => (
                      <React.Fragment key={index + 1}>
                        <Space direction="horizontal" style={{ marginTop: 10, display: "flex", alignItems: "center" }}>
                          <Skeleton.Avatar style={{ width: "20px", height: "20px" }} active={true} size="small" shape="circle" />
                          <Skeleton.Button style={{ height: "10px", marginTop: 4, width: "5vw" }} active={true} size="small" shape="round" />
                        </Space>
                      </React.Fragment>
                    ))}
                  </div>
                </> :
                  <Checkbox.Group
                    options={options}
                    value={selectedAddOns}
                    onChange={(e) => handleAddAddon(e)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "1.5em",
                      direction: language == "en" ? "ltr" : "rtl",
                    }}
                  />
                }
                {/* <button
                  style={{
                    padding: ".25em 1em",
                    height: "24px",
                    width:"100px",
                    border: "none",
                    backgroundColor: "rgba(72, 160, 219, 0.2)",
                    borderRadius: "15px",
                  }}
                >
                  optional
                </button> */}
              </div>
            </div>

            <p style={{ fontSize: "20px", fontWeight: "700", marginTop: "2em" }} >
              {language == "en" ? "Any Special Instructions" : "لديك تعليمات خاصة"}
            </p>
            <input
              type="text"
              onChange={handleAddNote}
              style={{
                width: "100%",
                margin: "2em auto",
                boxSizing: "border-box",
                padding: "1.2em 1em",
                textIndent: "1em",
                border: "none",
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0 0 4px lightgray",
              }}
              placeholder={language == "en" ? "Tell Us Here...." : "اخبرنا هنا...."}
            />
          </div>

          {totalPrice > 0 && totalItems > 0 &&
            <div style={{ textAlign: "center", cursor: 'pointer' }} onClick={() =>
              storeName
                ? navigate(`/${storeName}/my-cart`)
                : navigate(`/my-cart`)
            }>
              <span style={{
                padding: "5px 8px",
                background: "#48a0db",
                borderRadius: "22px",
                display: "inline-block"
              }}>
                <span style={{ display: "flex" }}>
                  <p style={{
                    margin: "0px 10px 0px 7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "17px",
                    fontWeight: "bold",
                    color: "#fff"
                  }}> {totalPrice} SAR</p>
                  <p style={{
                    background: "rgb(246, 169, 74)",
                    padding: "7px",
                    borderRadius: "50%",
                    minWidth: "25px",
                    minHeight: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "19px",
                    fontWeight: "bold",
                    color: "#48a0db"
                  }}>{totalItems}</p>
                </span>
              </span>
            </div>
          }
          <br />
          <br />
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "2em 1em",
            boxSizing: "border-box",
          }}
        >
          <div style={{ width: "90%", margin: "auto", display: "flex", justifyContent: "space-between", alignItems: "center" }} dir={language == "en" ? "ltr" : "rtl"}>
            <div
              style={{
                width: "35%",
                //   marginTop: "2em",
                padding: "0.6em",
                border: "1px solid #48A0DB",

                display: "flex",

                borderRadius: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: "0 0 4px lightgray",
              }}
              dir={language == "en" ? "ltr" : "ltr"}
            >
              <button
                style={{
                  backgroundColor: "transparent",
                  //   padding: "0.5em",
                  border: "none",
                  fontSize: "20px",
                  cursor: "pointer"
                }}
                onClick={() => handleDecrease()}
              >
                -
              </button>
              <span>{quantity}</span>
              <button
                style={{
                  backgroundColor: "transparent",
                  //   padding: "0.5em",
                  border: "none",
                  fontSize: "20px",
                  cursor: "pointer"
                }}
                onClick={() => handleIncrease()}
              >
                +
              </button>
            </div>
            <button
              style={{
                width: "55%",
                marginLeft: "10px",
                // marginTop: "2em",
                padding: "0.7em 1em",
                border: "none",
                outline: "none",
                display: "flex",
                backgroundColor: "#F6A94A",
                color: "white",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 0 4px lightgray",
                fontSize: "18px",
                cursor: "pointer"
              }}
              onClick={() => addProductToCart()}
              disabled={loadingBtn ? true : false}
            >
              {loadingBtn ? <Spin indicator={antIcon} /> : language == "en" ? "Add" : "اضف"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
